import React from "react";
import CompanyCard from "./CompanyCard";

const SearchResults = ({ results }) => (
  <section className="my-16">
    <h3 className="font-bold mb-8" style={{ fontFamily: "Lato, sans-serif" }}>
      Search Results
    </h3>
    {results.length > 0 ? (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {results.map((company) => (
          <CompanyCard key={company.id} company={company} />
        ))}
      </div>
    ) : (
      <p>No results found. Please try different filter criteria.</p>
    )}
  </section>
);

export default SearchResults;
