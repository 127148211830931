import React from "react";
import { BreadcrumbComponentTwo } from "../../../components/shared/index";
import privacypolicybgimage from "../../../assets/images/Policy/termsandcondition.jpg";
import { Layout } from "../../../components/layout/layout";
import { PolicyPagesFooter } from "../Policy Footer";

export const PrivacyPolicy = () => {
  return (
    <Layout>
      <section className="">
        <BreadcrumbComponentTwo
          title="Privacy Policy"
          bgImage={privacypolicybgimage}
        />
        <main className="px-4 py-8 md:px-8 lg:px-24">
          <p className="text-gray-600 mb-8 font-bold">Effective Date: 30-08-24</p>

          {[
            {
              title: "1. Introduction",
              content:
                "Welcome to GoatArch Design and Products. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website and use our services.",
            },
            {
              title: "2. Information We Collect",
              content:
                "We collect various types of information to provide and improve our services:",
              subsections: [
                {
                  subtitle: "Personal Information:",
                  content:
                    "When you create an account, list your company, profile, products, or projects, we collect personal details such as your name, email address, contact number, and any other information you provide voluntarily.",
                },
                {
                  subtitle: "Company Information:",
                  content:
                    "When listing a company, products, or projects, we collect details about your business, including company name, address, product descriptions, project details, and other related information.",
                },
                {
                  subtitle: "Payment Information:",
                  content:
                    "When you purchase a plan to list additional products or projects, we collect payment details, including credit card information and billing address, through secure payment gateways.",
                },
                {
                  subtitle: "Usage Data:",
                  content:
                    "We collect information on how you interact with our website, including IP addresses, browser type, device information, pages visited, and time spent on the site.",
                },
              ],
            },
            {
              title: "3. How We Use Your Information",
              content:
                "We use the collected information for the following purposes:",
              subsections: [
                {
                  subtitle: "To Provide and Improve Services:",
                  content:
                    "We use your information to create and manage your account, enable listing services, and enhance your experience on our website.",
                },
                {
                  subtitle: "To Process Payments:",
                  content:
                    "Payment information is used to process transactions for the purchase of listing plans.",
                },
                {
                  subtitle: "To Communicate with You:",
                  content:
                    "We may use your contact information to send you updates, newsletters, marketing materials, or respond to inquiries.",
                },
                {
                  subtitle: "To Ensure Security:",
                  content:
                    "We use data to maintain the security of our website, prevent fraud, and protect your information.",
                },
                {
                  subtitle: "To Comply with Legal Obligations:",
                  content:
                    "We may use your information to comply with applicable laws, regulations, and legal processes.",
                },
              ],
            },
            {
              title: "4. Sharing Your Information",
              content:
                "We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted partners for the following reasons:",
              subsections: [
                {
                  subtitle: "Service Providers:",
                  content:
                    "We may share your information with third-party service providers who assist us in operating our website, processing payments, or delivering services.",
                },
                {
                  subtitle: "Legal Requirements:",
                  content:
                    "We may disclose your information to comply with legal obligations, respond to subpoenas, court orders, or other legal processes.",
                },
              ],
            },
            {
              title: "5. Data Security",
              content:
                "We implement various security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, firewalls, and secure servers.",
            },
            {
              title: "6. Your Rights",
              content:
                "You have the right to access, update, or delete your personal information at any time. You can manage your information by logging into your account or contacting us directly.",
            },
            {
              title: "7. Cookies",
              content:
                "We use cookies to enhance your experience on our website. Cookies help us understand user behavior, remember your preferences, and improve our services. You can choose to disable cookies through your browser settings, but this may affect your experience on our site.",
            },
            {
              title: "8. Third-Party Links",
              content:
                "Our website may contain links to third-party websites. We are not responsible for the privacy practices of these sites, and we encourage you to review their privacy policies.",
            },
            {
              title: "9. Changes to This Privacy Policy",
              content:
                "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.",
            },
          ].map((section, index) => (
            <div
              key={index}
              className="mb-8 pb-8 border-b border-gray-200 last:border-b-0"
            >
              <h2 className="text-2xl font-semibold mb-4 text-theme-color">
                {section.title}
              </h2>
              <p className="text-gray-700 mb-4">{section.content}</p>
              {section.subsections &&
                section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} className="mt-4">
                    <h3 className="text-xl font-semibold mb-2 text-black">
                      {subsection.subtitle}
                    </h3>
                    <p className="text-gray-700">{subsection.content}</p>
                  </div>
                ))}
            </div>
          ))}

          <PolicyPagesFooter />
        </main>
      </section>
    </Layout>
  );
};
