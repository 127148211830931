import React, { useState } from "react";
import {
  Calendar,
  MapPin,
  Clock,
  User,
  ArrowLeft,
  ArrowRight,
} from "lucide-react";
import { PhotoDisplay } from "../../../components/shared";
import { Layout } from "../../../components/layout/layout";
import EventRegistrationPopup from "../Event Registration";

const EventDetailPage = () => {
  const event = {
    id: "1",
    imageUrl: "fx7iQCFpUu0",
    title: "Tech Innovation Summit 2023",
    date: "May 15-17, 2023",
    time: "9:00 AM - 6:00 PM Daily",
    location: "San Francisco Convention Center",
    description:
      "Join us for the annual Tech Innovation Summit, a 3-day event showcasing the latest advancements and innovations shaping the future of technology. Attendees will have the opportunity to network with industry leaders, attend thought-provoking keynotes and panel discussions, and explore cutting-edge products and services from top tech companies. This year's summit will feature tracks covering topics such as artificial intelligence, cloud computing, cybersecurity, and emerging technologies. Whether you're a seasoned tech professional or an aspiring innovator, the Tech Innovation Summit is the must-attend event of the year for anyone interested in the future of tech.",
    highlights: [
      {
        title: "Keynote Presentations",
        description:
          "Hear from visionary industry leaders as they share their insights and predictions on the future of technology.",
        icon: "presentation",
      },
      {
        title: "Interactive Workshops",
        description:
          "Get hands-on experience with the latest technologies through interactive workshops and demonstrations.",
        icon: "workshop",
      },
      {
        title: "Networking Lounge",
        description:
          "Connect with peers, industry experts, and potential collaborators in our dedicated networking lounge.",
        icon: "networking",
      },
      {
        title: "Startup Showcase",
        description:
          "Discover the newest tech innovations and cutting-edge products from up-and-coming startups.",
        icon: "startup",
      },
      {
        title: "Tech Career Fair",
        description:
          "Explore job opportunities and meet with top tech employers at our dedicated career fair.",
        icon: "career-fair",
      },
    ],
    attendees: "1,200+ Expected",
    category: "Technology",
    price: "$499 Early Bird, $599 Regular",
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleRegisterClick = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  // Helper function to render highlight item
  const HighlightItem = ({ title, description, icon }) => (
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <div className="w-12 h-12 bg-theme-color/10 rounded-full flex items-center justify-center text-theme-color">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {icon === "presentation" && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M4 18h12a2 2 0 002-2V8a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            )}
            {icon === "workshop" && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
              />
            )}
            {icon === "networking" && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              />
            )}
            {icon === "startup" && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            )}
            {icon === "career-fair" && (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              />
            )}
          </svg>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-bold text-gray-800">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );

  return (
    <Layout headerType="2">
      <div className="py-20">
        {/* Banner */}
        <div className="relative h-[500px] overflow-hidden">
          <PhotoDisplay
            photoId={event.imageUrl}
            alt={event.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 px-24 py-8 text-white">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              {event.title}
            </h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <Calendar className="h-5 w-5" />
                <span>{event.date}</span>
              </div>
              <div className="flex items-center space-x-2">
                <Clock className="h-5 w-5" />
                <span>{event.time}</span>
              </div>
              <div className="flex items-center space-x-2">
                <MapPin className="h-5 w-5" />
                <span>{event.location}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Details */}
        <div className="px-6 lg:px-24 md:px-8 mt-24">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Main Content */}
            <div className="col-span-2 bg-white rounded-lg shadow-lg p-8 space-y-6">
              <p className="text-gray-600 text-lg leading-relaxed">
                {event.description}
              </p>
              <div className="space-y-8">
                <h2 className="text-2xl font-bold text-gray-800">
                  Event Highlights
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {event.highlights.map((highlight, index) => (
                    <HighlightItem
                      key={index}
                      title={highlight.title}
                      description={highlight.description}
                      icon={highlight.icon}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <a
                  href="#"
                  className="flex items-center space-x-2 text-theme-color hover:text-theme-color/80 transition-colors"
                >
                  <ArrowLeft className="h-5 w-5" />
                  <span>Previous Event</span>
                </a>
                <a
                  href="#"
                  className="flex items-center space-x-2 text-theme-color hover:text-theme-color/80 transition-colors"
                >
                  <span>Next Event</span>
                  <ArrowRight className="h-5 w-5" />
                </a>
              </div>
            </div>

            {/* Sidebar */}
            <div className="bg-white rounded-lg shadow-lg p-8 space-y-6 h-fit">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <PhotoDisplay
                    photoId={event.imageUrl}
                    alt="Organizer"
                    className="w-16 h-16 rounded-full object-cover"
                  />
                </div>
                <div>
                  <h4 className="text-lg font-bold text-gray-800">
                    Organized by
                  </h4>
                  <p className="text-gray-600">Tech Innovation Institute</p>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex items-center space-x-3">
                  <User className="h-5 w-5 text-theme-color" />
                  <span className="text-gray-600">{event.attendees}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Calendar className="h-5 w-5 text-theme-color" />
                  <span className="text-gray-600">{event.date}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Clock className="h-5 w-5 text-theme-color" />
                  <span className="text-gray-600">{event.time}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <MapPin className="h-5 w-5 text-theme-color" />
                  <span className="text-gray-600">{event.location}</span>
                </div>
              </div>
              <button
                onClick={handleRegisterClick}
                className="w-full bg-theme-color text-white py-3 rounded font-semibold transition-all duration-300 hover:bg-theme-color/80"
              >
                Register
              </button>
              <EventRegistrationPopup
                isOpen={isPopupOpen}
                onClose={handlePopupClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventDetailPage;
