import React, { useState, useEffect, useRef } from "react";
import { ShareIcon } from "@heroicons/react/24/outline";
import {
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaEnvelope,
  FaMicrosoft,
  FaLinkedin,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";

const UniversalSocialShare = ({ customUrl, title }) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const location = useLocation();
  const shareMenuRef = useRef(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        shareMenuRef.current &&
        !shareMenuRef.current.contains(event.target)
      ) {
        setIsShareOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleShareOptions = () => {
    setIsShareOpen(!isShareOpen);
  };

  const shareOnSocialMedia = (platform) => {
    const currentUrl =
      customUrl || `${window.location.origin}${location.pathname}`;
    const encodedLink = encodeURIComponent(currentUrl);
    const encodedTitle = encodeURIComponent(title || document.title);
    let shareUrl = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedLink}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedLink}&text=${encodedTitle}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=${encodedTitle}&body=Check out this link: ${encodedLink}`;
        break;
      case "outlook":
        shareUrl = `https://outlook.office.com/mail/deeplink/compose?subject=${encodedTitle}&body=Check out this link: ${encodedLink}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedLink}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };

  return (
    <div className="relative" ref={shareMenuRef}>
      <button
        className="bg-gray-100 p-2 rounded-full hover:bg-gray-200 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color"
        onClick={toggleShareOptions}
        aria-label="Share"
      >
        <ShareIcon className="h-5 w-5 text-gray-800" />
      </button>
      {isShareOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg overflow-hidden z-20 transition-all duration-300 ease-in-out transform origin-top-right scale-100">
          <div className="py-2">
            <button
              onClick={() => shareOnSocialMedia("whatsapp")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full transition-colors duration-200"
            >
              <FaWhatsapp className="h-5 w-5 mr-3 text-green-500" />
              WhatsApp
            </button>
            <button
              onClick={() => shareOnSocialMedia("twitter")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full transition-colors duration-200"
            >
              <FaTwitter className="h-5 w-5 mr-3 text-blue-400" />
              Twitter
            </button>
            <button
              onClick={() => shareOnSocialMedia("facebook")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full transition-colors duration-200"
            >
              <FaFacebook className="h-5 w-5 mr-3 text-blue-600" />
              Facebook
            </button>
            <button
              onClick={() => shareOnSocialMedia("linkedin")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full transition-colors duration-200"
            >
              <FaLinkedin className="h-5 w-5 mr-3 text-blue-700" />
              LinkedIn
            </button>
            <button
              onClick={() => shareOnSocialMedia("email")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full transition-colors duration-200"
            >
              <FaEnvelope className="h-5 w-5 mr-3 text-gray-600" />
              Email
            </button>
            <button
              onClick={() => shareOnSocialMedia("outlook")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full transition-colors duration-200"
            >
              <FaMicrosoft className="h-5 w-5 mr-3 text-blue-500" />
              Outlook
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UniversalSocialShare;
