import React from "react";
import {
  BuildingOfficeIcon,
  ShoppingBagIcon,
  DocumentPlusIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const GoatArchFeatures = () => {
  const features = [
    {
      icon: BuildingOfficeIcon,
      title: "List Your Business",
      description:
        "Create a comprehensive professional profile that highlights your architectural and design expertise.",
      color: "bg-black text-theme-color",
      link: "/register/",
      buttonName: "Add Business",
    },
    {
      icon: ShoppingBagIcon,
      title: "Showcase Your Products",
      description:
        "Display your innovative products and solutions to connect with potential clients and collaborators.",
      color: "bg-black text-theme-color",
      link: "/dashboard/create-products",
      buttonName: "Add Product",
    },
    {
      icon: DocumentPlusIcon,
      title: "Submit Your Creative Project",
      description:
        "Share your most impressive architectural and design projects with the GoatArch community.",
      color: "bg-black text-theme-color",
      link: "/dashboard/create-projects",
      buttonName: "Add Projects",
    },
    {
      icon: CalendarDaysIcon,
      title: "Add Your Events",
      description:
        "Promote your upcoming workshops, conferences, and industry events to engage with professionals.",
      color: "bg-black text-theme-color",
      link: "/events/add",
      buttonName: "Add Event",
    },
  ];

  return (
    <section className="px-4 py-16 bg-neutral-50">
      <div className="max-w-7xl mx-auto text-center mb-12">
        <h2 className="text-4xl font-bold text-neutral-800 mb-4">
          Elevate Your Professional Presence
        </h2>
        <p className="text-neutral-600 max-w-2xl mx-auto text-lg">
          GoatArch is the leading platform for bringing together Experts in
          Design , Manufacturing, Architecture and Building Supplies. Promote
          your experience and expand your company.
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-white rounded-2xl border border-neutral-100 
            shadow-lg hover:shadow-xl transition-all duration-300 
            transform hover:-translate-y-2 group overflow-hidden"
          >
            <div className="p-6">
              <div
                className={`
                ${feature.color} 
                w-16 h-16 rounded-full flex items-center 
                justify-center mb-4 transition-all duration-300 
                group-hover:scale-110`}
              >
                <feature.icon className="w-8 h-8" />
              </div>

              <h3 className="text-xl font-bold text-neutral-800 mb-3">
                {feature.title}
              </h3>

              <p className="text-neutral-600 mb-4 text-sm">
                {feature.description}
              </p>

              <Link
                to={feature.link}
                className="
                  inline-flex items-center text-sm font-semibold 
                  text-theme-color hover:underline group/link"
              >
                {feature.buttonName}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 ml-2 transition-transform group-hover/link:translate-x-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
