import { Link } from "react-router-dom";
export const Error404Page = () => {
  return (
    <section className="h-screen bg-no-repeat bg-cover bg-center bg-error-404 flex items-center justify-center">
      <div className="text-center max-w-lg mx-auto text-white">
        <h1 className="sm:text-[200px] text-9xl font-bold bg-clip-text text-white bg-cover bg-bottom bg-no-repeat bg-error-404 mb-5">
          404
        </h1>
        <p className="font-medium">
          Uh-oh, it seems like you've wandered off the beaten path. The page
          you’re looking for is playing hide and seek!
        </p>
        <div className="mt-8">
          <Link to='/' className="border font-medium rounded-full transition-all duration-300 hover:bg-black hover:text-theme-color hover:border-black px-10 py-2">
            Back to home
          </Link>
        </div>
      </div>
    </section>
  );
};
