import React from "react";
import { useForm } from "react-hook-form";
import { Input, Popup } from "../../../components/shared";

const EventRegistrationPopup = ({ isOpen, onClose }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    onClose();
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <div className="bg-white rounded-lg shadow-lg p-8 sm:p-10 relative">
        <div className="md:flex md:items-center md:justify-between">
          <h2 className="text-2xl font-bold mb-4 md:mb-0">
            Event Registration
          </h2>
          <div className="bg-theme-color w-16 h-16 rounded-full flex items-center justify-center text-white font-bold text-2xl md:ml-4">
            EV
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-6 space-y-4">
          <Input
            name="fullName"
            control={control}
            rules={{ required: true }}
            label="Full Name"
            placeholder="Enter your full name"
            className="w-full"
          />
          <Input
            name="email"
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            label="Email"
            type="email"
            placeholder="Enter your email"
            className="w-full"
          />
          <Input
            name="members"
            control={control}
            rules={{ required: true, max: 5 }}
            label="Number of Members"
            type="select"
            options={Array.from({ length: 5 }, (_, i) => i + 1).map((num) => ({
              value: num,
              label: num.toString(),
            }))}
            className="w-full"
          />
          <button
            type="submit"
            className="w-full bg-theme-color text-white py-3 rounded font-semibold transition-all duration-300 hover:bg-theme-color/80"
          >
            Submit
          </button>
        </form>
      </div>
    </Popup>
  );
};

export default EventRegistrationPopup;
