import axios from "axios";
import { Layout } from "../../components/layout/layout";
import BreadcrumbImage from "../../assets/images/Projects/projectbanner-color.jpg";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback, Fragment } from "react";
import {
  BreadcrumbComponentTwo,
  PhotoDisplay,
  Loader,
  SEO,
} from "../../components/shared";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import ProjectCardPlaceholder from "../../assets/images/Placeholders/wowplaceholder.png";
import NODATAFOUND from "../../assets/images/Misc/not-found.gif";

import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
import Fuse from "fuse.js";

const categories = [
  {
    name: "All Projects",
    subcategories: [],
  },
  {
    name: "Residential",
    subcategories: [
      "Apartment",
      "Private House",
      "Multi Unit Housing",
      "Student Housing",
    ],
  },
  {
    name: "Commercial",
    subcategories: [
      "Office",
      "Retail",
      "Bank",
      "Showroom",
      "Supermarket",
      "Exhibition Center",
      "Shopping Mall",
      "Popup",
    ],
  },
  {
    name: "Hotels + Sport",
    subcategories: [
      "Hotel",
      "Restaurant",
      "Bar/Nightclubs",
      "Wellness Spa",
      "Movie Theatre",
      "Information Center",
      "Stadium",
      "Sport Center",
      "Zoo/Aquarium",
      "Amusement Park",
    ],
  },
  {
    name: "Cultural",
    subcategories: [
      "Gallery",
      "Hall/Theatre",
      "Culture Center",
      "Museum",
      "Observation Tower",
      "Pavilion",
      "Sculpture",
      "Religious",
      "Memorial",
    ],
  },
  {
    name: "Educational",
    subcategories: [
      "Nursery",
      "Elementary School",
      "High School",
      "University",
      "Auditorium",
      "Library",
      "Others",
    ],
  },
  {
    name: "Government + Health",
    subcategories: ["City Hall", "Community Center", "Hospital"],
  },
  {
    name: "Industrial",
    subcategories: [
      "Factory",
      "Industry",
      "Laboratory",
      "Power Plant",
      "Water Facility",
      "Farm",
      "Winery",
    ],
  },
  {
    name: "Landscape + Planning",
    subcategories: ["Public Park", "Private Garden", "Playground", "Cemetery"],
  },
  {
    name: "Transport + Infrastructure",
    subcategories: [
      "Airport",
      "Bus Stand",
      "Bridge",
      "Railway Bridge",
      "Parking",
      "Bicycle Facilities",
    ],
  },
];

export const Projects = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Projects");
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL;
  // console.log("filteredProjects", filteredProjects);

  const [isMobile, setIsMobile] = useState(false);
  const [openCategory, setOpenCategory] = useState(null);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 1024);
  }, []);

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    filterProjects();
  }, [selectedCategory, selectedSubcategories, searchTerm, projects]);

  const fetchData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseURL}/api/projects`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      setProjects(response.data);
      setFilteredProjects(response.data);
      // console.log("response", response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategories({});
    if (category === "All Projects") {
      setSearchTerm("");
      setMobileFiltersOpen(false);
    } else {
      setOpenCategory(openCategory === category ? null : category);
    }
  };

  const handleSubcategoryToggle = (subcategory) => {
    setSelectedSubcategories((prev) => ({
      ...prev,
      [subcategory]: !prev[subcategory],
    }));
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.trim();
    setSearchTerm(term);
  };

  const filterProjects = () => {
    let filtered = projects;

    if (selectedCategory && selectedCategory !== "All Projects") {
      filtered = filtered.filter(
        (project) => project.category === selectedCategory
      );
    }
    const activeSubcategories = Object.entries(selectedSubcategories)
      .filter(([, isSelected]) => isSelected)
      .map(([subcategory]) => subcategory);

    if (activeSubcategories.length > 0) {
      filtered = filtered.filter((project) =>
        activeSubcategories.includes(project.sub_category)
      );
    }
    if (searchTerm) {
      const fuse = new Fuse(filtered, {
        keys: ["project_name", "category", "sub_category"],
        includeScore: true,
      });
      const results = fuse.search(searchTerm);
      filtered = results.map((result) => result.item);
    }

    setFilteredProjects(filtered);
  };

  const MobileFilters = () => (
    <div className="sticky top-0 bg-white z-10 p-4 shadow-md">
      <div className="flex items-center space-x-4">
        <input
          type="search"
          placeholder="Search projects..."
          className="flex-grow p-2 border rounded"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button
          type="button"
          className="-m-2 ml-4 p-2 text-black hover:text-gray-500 sm:ml-6 lg:hidden"
          onClick={() => setMobileFiltersOpen(true)}
        >
          <span className="sr-only">Filters</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );

  const projectPageShareURL =
    "https://goatarch.com/static/media/projectbanner-color.feb8731ac299736a2b7e.jpg";
  return (
    <Layout headerType="2">
      {/* SEO Section Start */}
      <SEO
        canonicalUrl="https://goatarch.com/projects/"
        description="Explore inspiring architecture and interior design projects on GoatArch. Discover creative works from top designers and architects, showcasing their expertise and vision."
        title="Projects"
        keywords={[
          "architecture projects",
          "interior design projects",
          "design portfolio",
          "architectural works",
          "professional projects",
          "design showcase",
        ]}
        image={projectPageShareURL}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: "Architecture & Interior Design Projects | GoatArch",
          description:
            "Explore inspiring architecture and interior design projects on GoatArch. Discover creative works from top designers and architects, showcasing their expertise and vision.",
          url: "https://goatarch.com/projects/",
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          },
        }}
      />
      {/* SEO Section End */}
      <div className="mt-[4.6%]">
        <BreadcrumbComponentTwo bgImage={BreadcrumbImage} title="Projects" />
      </div>
      <div className="bg-white">
        <div>
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <span className="text-lg font-medium text-gray-900">
                        Filters
                      </span>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-4 border-t border-gray-200">
                      {categories.map((category) => (
                        <Disclosure
                          as="div"
                          key={category.name}
                          className="border-t border-gray-200 px-4 py-6"
                          defaultOpen={openCategory === category.name}
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button
                                  className={`flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500 ${
                                    selectedCategory === category.name
                                      ? "text-theme-color"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleCategorySelect(category.name)
                                  }
                                >
                                  <span className="font-medium text-gray-900">
                                    {category.name}
                                  </span>
                                  {category.subcategories.length > 0 && (
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  )}
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  {category.subcategories.map((subcategory) => (
                                    <div
                                      key={subcategory}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-mobile-${category.name}-${subcategory}`}
                                        name={`${category.name}[]`}
                                        value={subcategory}
                                        type="checkbox"
                                        checked={
                                          selectedSubcategories[subcategory] ||
                                          false
                                        }
                                        onChange={() =>
                                          handleSubcategoryToggle(subcategory)
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${category.name}-${subcategory}`}
                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                      >
                                        {subcategory}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="mx-auto px-4 sm:px-6 lg:px-24">
            <section className="pb-24 pt-6">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                <div className="hidden lg:block">
                  <h3 className="sr-only">Categories</h3>
                  <div>
                    <div className="relative my-2 ">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded bg-gray-50"
                        placeholder="Search Category, Name..."
                        required=""
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <div className="border border-gray-200 rounded p-4">
                    {categories.map((category) => (
                      <Disclosure key={category.name} as="div" className="mb-2">
                        {({ open }) => (
                          <div>
                            <Disclosure.Button
                              className="flex w-full justify-between rounded bg-theme-light-bg px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-theme-color hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                              onClick={() =>
                                handleCategorySelect(category.name)
                              }
                            >
                              <span>{category.name}</span>
                              {category.name !== "All Projects" && (
                                <ChevronDownIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-gray-500`}
                                />
                              )}
                            </Disclosure.Button>
                            {category.name !== "All Projects" && (
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                {category.subcategories.map((subcategory) => (
                                  <div
                                    key={subcategory}
                                    className="flex items-center mb-2"
                                  >
                                    <input
                                      id={`filter-${category.name}-${subcategory}`}
                                      name={`${category.name}[]`}
                                      value={subcategory}
                                      type="checkbox"
                                      checked={
                                        selectedSubcategories[subcategory] ||
                                        false
                                      }
                                      onChange={() =>
                                        handleSubcategoryToggle(subcategory)
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${category.name}-${subcategory}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {subcategory}
                                    </label>
                                  </div>
                                ))}
                              </Disclosure.Panel>
                            )}
                          </div>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                  <div className="mt-6 bg-black border border-gray-200 rounded-lg shadow">
                    <Link
                      to="/dashboard/create-projects"
                      target="_blank"
                      rel="noopener nofollow noreferrer"
                    >
                      <PhotoDisplay className="rounded" photoId="jn7uVeCdf6U" />
                    </Link>
                    <div className="p-5">
                      <Link
                        to="/dashboard/create-projects/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="text-2xl font-bold tracking-tight text-white">
                          Submit Your Project
                        </p>
                      </Link>
                      <h6 className="text-sm mt-1 text-white">
                        Showcase your work to the world
                      </h6>
                      <p className="my-3 font-normal text-white">
                        Join our community of innovative designers and
                        architects. Get your project featured on our platform
                        today!
                      </p>
                      <Link
                        to="/dashboard/create-projects/"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black hover:text-white bg-theme-color rounded hover:bg-transparent border-theme-color border-2 hover:border-white"
                      >
                        List Your Project
                        <svg
                          className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-3">
                  {isMobile && <MobileFilters />}
                  {filteredProjects.length > 0 ? (
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                      {filteredProjects.map((project) => (
                        <div
                          key={project.id}
                          className="relative overflow-hidden bg-white rounded group"
                        >
                          <Link
                           to={`/projects/project-details/${project.id}-${project.project_name.replace(/\s+/g, '-')}/`}
                            rel="noopener noreferrer nofollow"
                            className="block"
                          >
                            <div className="relative">
                              <img
                                src={
                                  project?.banner_image ||
                                  ProjectCardPlaceholder
                                }
                                className="w-full h-72 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
                              />
                              <span className="absolute top-2 right-2 text-white text-sm font-semibold z-10">
                                {project.category
                                  ? project.category.charAt(0).toUpperCase() +
                                    project.category.slice(1)
                                  : ""}
                              </span>
                              <div className="absolute top-0 left-0 p-4 bg-gradient-to-b from-transparent to-black/70 w-full h-full flex flex-col justify-end">
                                <span
                                  className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300"
                                  style={{ fontFamily: "Lato, sans-serif" }}
                                >
                                  {project.project_name}
                                </span>
                                <span className="text-white text-sm">
                                  {project?.city_name}, {project?.country_name}
                                </span>
                                <span className="text-white text-sm">
                                  By {project?.company_name}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                      <img
                        src={NODATAFOUND}
                        alt="No Data Found"
                        className="max-w-xs w-full mb-4"
                      />
                      <p className="text-xl font-semibold text-black">
                        No projects found for the selected category.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </Layout>
  );
};
