import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../../../components/layout/Dashboard Layout/index";
import { Link } from "react-router-dom";
import { EyeIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import axios from "axios";

export const ProductsUnderReview = () => {
  const [products, setProducts] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/under_observation_products`
        );
        setProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-container")) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleApprove = async (productId, userId) => {
    try {
      await axios.post(`${baseURL}/api/update_product_status/${productId}`, {
        status: 1,
        userId: userId,
      });
      const updatedProducts = products.map((product) =>
        product.id === productId ? { ...product, status: "Approved" } : product
      );
      setProducts(updatedProducts);
      setActiveDropdown(null);
    } catch (error) {
      console.error("Error approving product:", error);
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">
            Products Under Review
          </h1>
        </div>

        <div className="bg-white rounded-lg lg:shadow-md">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Id
                </th>
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Product Name
                </th>
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Collection
                </th>
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Created Date
                </th>
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Preview
                </th>
                <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-left">
              {products.map((product) => (
                <tr
                  key={product.id}
                  className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
                >
                  <td className="px-4 py-4 text-sm text-gray-900">
                    {product.id}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900">
                    {product.product_name}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900">
                    {product.collection_name}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900">
                    {product.category}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500">
                    {new Date(product.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-4 text-sm">
                    <Link
                      to={`/products/${product.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-black hover:text-white bg-theme-color hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color transition-colors gap-2"
                    >
                      <span>View</span>
                      <EyeIcon className="h-4 w-4" />
                    </Link>
                  </td>
                  <td className="px-4 py-4 text-sm">
                    <div className="dropdown-container relative">
                      <button
                        onClick={() => toggleDropdown(product.id)}
                        className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color transition-colors"
                      >
                        Actions
                        <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </button>

                      {activeDropdown === product.id && (
                        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                          <div className="py-1" role="menu">
                            <button
                              onClick={() =>
                                handleApprove(product.id, product.user_id)
                              }
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                              role="menuitem"
                            >
                              Approve
                            </button>
                            <button
                              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                              role="menuitem"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ProductsUnderReview;
