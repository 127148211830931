import BlogOne from "../../assets/images/About/instgram cover.png";
import BlogTwo from "../../assets/images/Home/homeblog.jpg";
import BlogThree from "../../assets/images/Blogs/benefits.jpg";
import BlogFour from "../../assets/images/Blogs/blogfour.jpg";
import BlogFive from "../../assets/images/Blogs/accostic.jpg";

export const newsItems = [
  {
    id: 1,
    photoId: BlogOne,
    title:
      "Why GoatArch Revolutionizing Professional Networking in Architecture & Design ?",
    category: "Platform Guide",
    date: "October 25, 2024",
    url: "/why-goatarch-revolutionizing-professional-networking-in-architecture-design/",
  },
  {
    id: 2,
    photoId: BlogTwo,
    title:
      "Maximizing Your GoatArch Presence: Projects & Products Showcase Guide",
    category: "Platform Guide",
    date: "October 25, 2024",
    url: "/maximizing-your-goatarch-presence-projects-products-showcase-guide/",
  },
  {
    id: 3,
    photoId: BlogThree,
    title: "How is GoatArch.com beneficial for designers ?",
    category: "About GoatArch",
    date: "November 13, 2024",
    url: "/how-is-goatarch-com-beneficial-for-designers/",
  },
  {
    id: 4,
    photoId: BlogFour,
    title: "Quality Materials: The Foundation of Sustainable Architecture",
    category: "Sustainability",
    date: "November 15, 2024",
    url: "/quality-materials-the-foundation-of-sustainable-architecture/",
  },
  {
    id: 5,
    photoId: BlogFive,
    title: "How Acoustic Comfort Enhances Your Building Design?",
    category: "Sustainability",
    date: "Dec 6, 2024",
    url: "/how-acoustic-comfort-enhances-your-building-design/",
  },
];
export const getSortedNewsItems = () => {
  return [...newsItems].sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
};

export const getLatestNewsItems = (count = 4) => {
  return getSortedNewsItems().slice(0, count);
};
