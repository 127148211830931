import React, { useState, useEffect } from "react";
import { Search } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

const SearchBar = ({ onSearch }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("q");
    if (query) {
      setSearchKeyword(query);
      onSearch(query); // Agar URL mein query ho, to onSearch ko call karna
    }
  }, [location.search, onSearch]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search-results?q=${encodeURIComponent(searchKeyword)}`);
    onSearch(searchKeyword); // Search term ko parent ko bhejna
  };

  return (
    <div className="max-w-2xl mx-auto my-8">
      <form onSubmit={handleSearchSubmit} className="relative">
        <input
          type="text"
          placeholder="Search..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="w-full p-4 pr-12 text-xl border-2 text-black border-theme-color rounded focus:outline-none focus:ring-2 focus:ring-theme-color focus:border-transparent"
        />
        <button
          type="submit"
          className="absolute right-4 top-1/2 transform -translate-y-1/2"
        >
          <Search className="h-6 w-6 text-black hover:text-theme-color-dark" />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
