import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  ProductDetailsSectionOne,
  ProductDetailsSectionTwo,
  SimiliarProducts,
} from "./Sections";
import { Layout } from "../../../components/layout/layout";
import { SEO } from "../../../components/shared";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [error, setError] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (id) {
      fetchProductById(id);
    }
  }, [id]);

  const fetchProductById = async (id) => {
    try {
      const response = await axios.post(`${baseURL}/api/products/${id}`);
      // console.log("response.data", response.data);

      setProduct(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("Product not found");
      } else {
        setError("An error occurred while fetching the product");
      }
    }
  };

  // console.log("productTest", productTest);

  return (
    <Layout headerType="2">
      <SEO
        title={product?.product_name}
        description={product?.product_description?.replace(/<[^>]*>/g, '').substring(0, 160)}
        canonicalUrl={`https://goatarch.com/products/${id}`}
        noindex={true}
        keywords={[
          product?.product_name,
          product?.category,
          product?.subcategory,
          "product details",
          "goatarch"
        ]}
        image={product?.product_images?.[0] || product?.company?.company_logo_image}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "Product",
          name: product?.product_name,
          description: product?.product_description?.replace(/<[^>]*>/g, '').substring(0, 160),
          url: `https://goatarch.com/products/${id}`,
          image: product?.product_images?.[0],
          brand: {
            "@type": "Brand",
            name: product?.company?.company_name,
            logo: {
              "@type": "ImageObject",
              url: product?.company?.company_logo_image
            }
          },
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          }
        }}
      />
      <ProductDetailsSectionOne product={product} />
      <ProductDetailsSectionTwo product={product} />
      <SimiliarProducts product={product} />
    </Layout>
  );
};

export default ProductDetails;
