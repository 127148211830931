import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CreateProductStepOne, CreateProductStepTwo } from "./Steps";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import { Helmet } from "react-helmet";
import { Loader, Popup, SiteLogo } from "../../../components/shared";

const pageVariants = {
  initial: { opacity: 0, y: 20, scale: 0.95 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { type: "spring", stiffness: 100, damping: 20 },
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.95,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
};

const steps = [
  { title: "Product Details", component: CreateProductStepOne },
  { title: "Media", component: CreateProductStepTwo },
];

//user_id in product and env work.

export const CreateProduct = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [step, setStep] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
    control,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setIsLoading(true);
    setIsPopupOpen(true);

    const formData = new FormData();

    // Handle main image
    if (data.productMainImage && data.productMainImage) {
      formData.append("product_banner_image", data.productMainImage);
    }

    // Handle supporting images
    if (
      data.productSupportingImages &&
      data.productSupportingImages.length > 0
    ) {
      data.productSupportingImages.forEach((image) => {
        formData.append("product_images", image);
      });
    }

    // Handle product_documents
    if (data.productDataSheet && data.productDataSheet) {
      formData.append("product_documents", data.productDataSheet);
    }

    // Handle product_certifications
    if (
      data.productGreenguardCertifications &&
      data.productGreenguardCertifications.length > 0
    ) {
      data.productGreenguardCertifications.forEach((product_certifications) => {
        formData.append("product_certifications", product_certifications);
      });
    }

    // Prepare non-file data to be sent as JSON
    const productData = {
      user_id: user_id,
      email: parsedData?.email,
      company_name: parsedData?.company_name,
      product_name: data.productName,
      collection_name: data.collection,
      category: data.category,
      sub_category: data.subCategory,
      description: data.description,
      application_of_uses: data.applicationOfUses,
      characteristics: data.characteristics,
      sizes: data.sizes,
      product_video_link: data.videoEmbedLinks,
      product_approved: 0,
    };

    // Append non-file data as JSON string
    formData.append("data", JSON.stringify(productData));

    // console.log("data", productData);

    try {
      const response = await axios.post(
        `${baseURL}/api/add_products`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log("Product added successfully:", response.data);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally, display an error message to the user
    } finally {
      setIsLoading(false);
    }
  };
  const handleExploreProducts = () => {
    navigate("/products");
  };

  useEffect(() => {
    let timer;
    if (isPopupOpen) {
      timer = setTimeout(() => {
        setIsPopupOpen(false);
        navigate("/");
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isPopupOpen, navigate]);

  const validateStep = async (stepNumber) => {
    const stepValidations = [
      [
        "productName",
        "collection",
        "category",
        "subCategory",
        "description",
        "applicationOfUses",
        "characteristics",
        "sizes",
      ],
      ["productMainImage", "productSupportingImages", "documents"],
    ];

    const result = await trigger(stepValidations[stepNumber]);

    if (!result) {
      // If validation fails, scroll to the first error
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    return result;
  };

  const nextStep = async (event) => {
    event.preventDefault();
    const isValid = await validateStep(step);
    if (isValid && step < steps.length - 1) {
      setStep(step + 1);
    } else if (isValid && step === steps.length - 1) {
      handleSubmit(onSubmit)();
    }
  };

  const prevStep = () => {
    if (step > 0) setStep(step - 1);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Create Product | GoatArch Design and Products</title>
      </Helmet>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Create Product</h1>
        <div className="bg-white rounded lg:shadow-md lg:p-6">
          <div className="mb-6">
            <ul className="flex border-b">
              {steps.map((stepObj, index) => (
                <li key={index} className="-mb-px mr-1">
                  <span
                    className={`inline-block py-2 px-4 text-sm font-semibold ${
                      index === step
                        ? "text-theme-color border-b-2 border-theme-color"
                        : "text-gray-500 hover:text-gray-800 cursor-pointer"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (index < step) setStep(index);
                    }}
                  >
                    {stepObj.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={step}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageVariants}
              >
                {React.createElement(steps[step].component, {
                  register,
                  errors,
                  watch,
                  setValue,
                  control,
                })}
              </motion.div>
            </AnimatePresence>
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={prevStep}
                disabled={step === 0}
                className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="w-[200px] text-white bg-black lg:bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
              >
                {step === steps.length - 1 ? "Submit" : "Next"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {isSubmitted && (
        <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
          <div className="flex flex-col items-center justify-center min-h-[300px] space-y-6">
            <SiteLogo className="w-32 md:w-40 lg:w-48" />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center">
              {isLoading ? "Submitting..." : "Success!"}
            </h2>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <p className="text-gray-600 text-lg md:text-xl lg:text-2xl text-center">
                  Your product is under review of administration. After
                  approval, you will receive a notification on your registered
                  email ID.
                </p>
                <p className="text-sm md:text-base lg:text-lg text-gray-500 text-center">
                  Until then, explore some other amazing products in our
                  company.
                </p>
                <button
                  onClick={handleExploreProducts}
                  className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
                >
                  Explore Other Products
                </button>
              </>
            )}
          </div>
        </Popup>
      )}
    </DashboardLayout>
  );
};
