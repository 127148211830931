import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../../components/layout/layout";
import CategoryItem from "./CategoryItem";
import CompanySection from "./CompanySection";
import SearchResults from "./SearchResults";
import MultiFilter from "./MultiFilter";
import HeroSectionImage from "../../assets/images/Company/browsing/companybannerimage.jpg";
import axios from "axios";
import { BreadcrumbComponentTwo, SEO } from "../../components/shared";

const categories = [
  { name: "Architects", image: "zubMDk_h00U" },
  { name: "Interior Designer", image: "io5Tvjh7vCc" },
  { name: "Manufacturer", image: "1-4TMIjDcNo" },
  { name: "Building Material Supplier", image: "mxwHM693fVg" },
  { name: "Furniture supplier", image: "GYFbsdCZ2Rc" },
  { name: "AV/IT Supplier", image: "j_E14mr1djw" },
  { name: "Technical Services and Installers", image: "FemeYrbdMWE" },
  { name: "Sustainability Consultant", image: "4NhqyQeErP8" },
  { name: "Ceiling supplier", image: "i9cuMyaxrFc" },
  { name: "Design and Build Contractor", image: "GR8DgJ309kM" },
  { name: "Landscape Designer", image: "fkalryO4dUI" },
  { name: "Acoustic Consultant", image: "aK0EmfPuktA" },
  { name: "Engineering and Structural Consultant", image: "SyRlD4s_amw" },
  { name: "Facade / Enclosure Consultant", image: "Rr31mltAsdk" },
  { name: "Artist and Art Designer", image: "HMt4tNZFIKg" },
  { name: "MEP Consultant", image: "BpWvu_KLJxo" },
  { name: "Urban Design and Planners", image: "QikjwVzRFWc" },
  { name: "Brand Designer", image: "tZAxLo_Q9T4" },
  { name: "General Contractor", image: "YpT0wtkWjBE" },
  { name: "Fitout Contractor", image: "GpTxPvquiGY" },
  { name: "Real Estate Developers", image: "2d4lAQAlbDA" },
  { name: "Educational Institutions", image: "Rcpv-9kFL-A" },
  { name: "Government Agencies", image: "qYcfhZL3yyM" },
  { name: "Law Firms", image: "NIJuEQw0RKg" },
  { name: "Lighting Supplier", image: "NGb91VwnOWY" },
  { name: "Acoustic supplier", image: "CpsTAUPoScw" },
  { name: "Sanitaryware Supplier", image: "STyEtOVTfg0" },
  { name: "Surfaces Supplier", image: "gJiW8Ye6y2Y" },
  { name: "Flooring Supplier", image: "v9oBtvRCKHk" },
  { name: "Decor supplier", image: "4HG3Ca3EzWw" },
  { name: "Partition Supplier", image: "lXnsemqmolU" },
  { name: "Wall Covering Supplier", image: "7tOV35hnkao" },
  { name: "Kitchen Supplier", image: "G7sE2S4Lab4" },
  { name: "Joinery", image: "eIOUKABNjDA" },
  { name: "Signage", image: "-bab_Hi1C94" },
];

const CompanyBrowsing = () => {
  const [visibleCategories, setVisibleCategories] = useState(12);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/companies`
      );
      setCompanies(response.data);
      // console.log("All companies:", response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch companies");
      console.error("Error fetching companies:", err);
      setLoading(false);
    }
  };

  const groupCompaniesByCategory = (companies) => {
    return companies.reduce((acc, company) => {
      const category = company.primary_company_type || "Uncategorized";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(company);
      return acc;
    }, {});
  };

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    setFilteredCompanies(
      companies.filter(
        (company) => company.primary_company_type === categoryName
      )
    );
    setFiltersApplied(true);
  };

  const handleLoadMore = () => {
    setVisibleCategories((prev) => Math.min(prev + 6, categories.length));
  };

  const handleLoadLess = () => {
    setVisibleCategories(6);
  };

  const isAllCategoriesVisible = visibleCategories >= categories.length;

  const countCompaniesByCategory = (categoryName) => {
    return companies.filter(
      (company) => company.primary_company_type === categoryName
    ).length;
  };

  const handleApplyFilter = (filters) => {
    // console.log("filters", filters);

    const results = companies.filter((company) => {
      const matchCategory =
        !filters.category || company.primary_company_type === filters.category;

      const matchCountry =
        !filters.country ||
        (company.country &&
          company.country
            .toLowerCase()
            .includes(filters.country.toLowerCase()));

      const matchCity =
        !filters.city ||
        (company.city &&
          company.city.toLowerCase().includes(filters.city.toLowerCase()));

      let matchSearchTerm = true;
      if (filters.searchTerm) {
        const searchTermLower = filters.searchTerm.toLowerCase();
        matchSearchTerm =
          company.company_name.toLowerCase().includes(searchTermLower) ||
          company.primary_company_type
            .toLowerCase()
            .includes(searchTermLower) ||
          (company.city &&
            company.city.toLowerCase().includes(searchTermLower)) ||
          (company.country && // Check if company.country is not null
            company.country.toLowerCase().includes(searchTermLower)); // Include country in search term
      }

      return matchCategory && matchCountry && matchCity && matchSearchTerm;
    });

    setFilteredCompanies(results);
    setFiltersApplied(true);
    setSelectedCategory(null);
  };

  const groupedCompanies = groupCompaniesByCategory(companies);

  const handleClearFilter = () => {
    setFilteredCompanies([]);
    setFiltersApplied(false);
    setSelectedCategory(null);
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>{error}</div>;

  const shareURL =
    "https://goatarch.com/static/media/companybannerimage.b986b0b2f42b067cebff.jpg";

  return (
    <Layout headerType="2">
      {/* SEO SEction Start */}
      <SEO
        canonicalUrl="https://goatarch.com/companies/"
        description="Discover top architecture, interior design, and manufacturing companies on GoatArch. Explore detailed profiles and learn about their innovative projects and services."
        title="Companies"
        keywords={[
          "architecture companies",
          "interior design firms",
          "manufacturing companies",
          "design companies",
          "architectural firms",
          "professional designers",
        ]}
        image={shareURL}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: "Architecture & Design Companies | GoatArch",
          description:
            "Discover top architecture, interior design, and manufacturing companies on GoatArch. Explore detailed profiles and learn about their innovative projects and services.",
          url: "https://goatarch.com/companies/",
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          },
        }}
      />

      {/* SEO Section End */}
      {/* <HeroSection /> */}
      {/* <div className="mt-[4.6%]">
        <BreadcrumbComponentTwo bgImage={HeroSectionImage} title="Companies" />
      </div> */}

      <section className="px-6 lg:px-24 py-16 md:py-16 md:pb-0">
        {/* <MultiFilter
          categories={categories}
          onApplyFilter={handleApplyFilter}
          onClearFilter={handleClearFilter}
          filtersApplied={filtersApplied}
        /> */}

        {filtersApplied && <SearchResults results={filteredCompanies} />}

        {!filtersApplied && (
          <div className="mt-10">
            <div className="mb-12">
              <div>
                <h1
                  className="font-bold text-theme-color capitalize text-center"
                  style={{ fontFamily: "Lato, sans-serif" }}
                >
                  <span className="text-black">Browse companies </span> accross
                  the globe 🌍
                </h1>
                <div className="mt-6 text-center">
                  Get inspired, join the community, and reach the right
                  audience! This is your best place to find and collaborate with
                  designers from all over the world.
                </div>
              </div>
              {/* {categories.length > 6 && (
                <button
                  onClick={
                    visibleCategories < categories.length
                      ? handleLoadMore
                      : handleLoadLess
                  }
                  className="text-theme-color font-bold hover:underline"
                >
                  {visibleCategories < categories.length
                    ? "Load More"
                    : "Load Less"}
                </button>
              )} */}
            </div>
            <div className="justify-center grid grid-cols-2 lg:grid-cols-6 gap-6">
              {categories.slice(0, visibleCategories).map((category, index) => (
                <CategoryItem
                  key={index}
                  href={`#${category.name.toLowerCase().replace(/\s+/g, "-")}`}
                  imgSrc={category.image}
                  alt={category.name}
                  label={category.name}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCategoryClick(category.name);
                  }}
                  companyCount={countCompaniesByCategory(category.name)}
                  index={index}
                />
              ))}
            </div>
            {categories.length > 6 && (
              <div className="my-8 text-center space-x-4">
                <button
                  onClick={handleLoadMore}
                  className={`text-theme-color transition-colors duration-300 font-bold ${
                    isAllCategoriesVisible
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={isAllCategoriesVisible}
                >
                  Load More
                </button>
                <button
                  onClick={handleLoadLess}
                  className={`text-black font-bold transition-colors duration-300 ${
                    visibleCategories <= 6
                      ? "opacity-50 hidden cursor-not-allowed"
                      : ""
                  }`}
                  disabled={visibleCategories <= 6}
                >
                  Load Less
                </button>
              </div>
            )}
            <div className="mt-16">
              <MultiFilter
                categories={categories}
                onApplyFilter={handleApplyFilter}
                onClearFilter={handleClearFilter}
                filtersApplied={filtersApplied}
              />
            </div>

            {Object.entries(groupedCompanies).map(
              ([category, categoryCompanies]) => (
                <CompanySection
                  key={category}
                  category={category}
                  companies={categoryCompanies}
                />
              )
            )}
          </div>
        )}
      </section>
    </Layout>
  );
};

export default CompanyBrowsing;
