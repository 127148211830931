import React from "react";
import { Sparkles } from "lucide-react";
import { Link } from "react-router-dom";
import { SiteLogo } from "../../../components/shared";

const MaterialLibrarySteps = () => {
  const steps = [
    {
      number: 1,
      title: "Register as Manufacturer",
      description: "Create your company profile to join our platform",
      buttonText: "Register Now",
      buttonLink: "/register/",
      icon: (
        <svg
          className="w-8 h-8"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
          />
        </svg>
      ),
    },
    {
      number: 2,
      title: "Access Dashboard",
      description: "After approval, login and navigate to your dashboard",
      buttonText: "Add Material",
      buttonLink: "/dashboard/create-products/",
      icon: (
        <svg
          className="w-8 h-8"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
          />
        </svg>
      ),
    },
    {
      number: 3,
      title: "Submit Details",
      description: `On Dashboard Click "Add Products" and fill up all necessary informations`,
      buttonText: "Start Upload",
      status: "Pending Approval",
      icon: (
        <svg
          className="w-8 h-8"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FBF5EE] to-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header with Logo and Visit Site */}
        <div className="flex justify-between items-center pt-8 pb-12">
          {/* Logo */}
          <div className="flex items-center">
            <SiteLogo width={150} white={false} />
          </div>

          {/* Visit Site Link */}
          <Link
            to="/"
            className="inline-flex items-center px-6 py-2 rounded-full border-2 border-black
              bg-white hover:bg-black hover:text-white transition-all duration-300
              text-sm font-medium"
          >
            Visit Site
            <svg
              className="ml-2 w-4 h-4"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </Link>
        </div>

        {/* Main Content */}
        <div className="space-y-16">
          {/* Header Section */}
          <div className="text-center">
            <div className="inline-flex items-center gap-2 rounded-full px-6 py-2 bg-black text-white mb-6">
              <Sparkles className="h-4 w-4" />
              <span className="text-sm font-medium">
                Material Library Now Live
              </span>
              <Sparkles className="h-4 w-4" />
            </div>

            <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-black to-theme-color bg-clip-text text-transparent">
              Join Our Material Library
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Showcase your materials to thousands of architects and designers
              worldwide
            </p>
          </div>

          {/* Process Steps */}
          <div className="relative">
            {/* Connecting Line */}
            <div className="hidden lg:block absolute top-32 left-0 right-0 h-1 bg-gradient-to-r from-black via-theme-color to-black opacity-20" />

            <div className="grid lg:grid-cols-3 gap-8 lg:gap-12">
              {steps.map((step, index) => (
                <div key={step.number} className="relative group">
                  <div className="flex flex-col items-center">
                    <div className="w-full max-w-sm relative mb-8">
                      <div
                        className={`absolute inset-0 rounded-2xl ${
                          index % 2 === 0 ? "bg-black" : "bg-theme-color"
                        } ${index % 2 === 0 ? "rotate-2" : "-rotate-2"} 
                      transform transition-transform group-hover:rotate-0`}
                      />

                      <div
                        className="relative p-8 bg-white rounded-2xl border-2 border-black 
                        transform transition-all duration-300 group-hover:-translate-y-2"
                      >
                        <div className="flex items-center justify-between mb-6">
                          <div
                            className={`p-3 rounded-xl ${
                              index % 2 === 0
                                ? "bg-black text-white"
                                : "bg-theme-color text-black"
                            }`}
                          >
                            {step.icon}
                          </div>
                          <span className="text-3xl font-bold text-black/20">
                            {String(step.number).padStart(2, "0")}
                          </span>
                        </div>

                        <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                        <p className="text-gray-600 mb-6">{step.description}</p>

                        {step.buttonLink ? (
                          <Link
                            to={step.buttonLink}
                            className={`inline-flex items-center px-6 py-3 rounded-full text-sm font-medium
                              ${
                                index % 2 === 0
                                  ? "bg-black text-white hover:bg-theme-color hover:text-black"
                                  : "bg-theme-color text-black hover:bg-black hover:text-white"
                              } transition-all duration-300`}
                          >
                            {step.buttonText}
                            <svg
                              className="ml-2 w-4 h-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </Link>
                        ) : (
                          <div
                            className="inline-flex items-center px-6 py-3 rounded-full text-sm font-medium
                            bg-gradient-to-r from-black to-theme-color text-white"
                          >
                            {step.buttonText}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Bottom Message */}
            <div className="mt-16 text-center">
              <div
                className="inline-block bg-[#FBF5EE] rounded-2xl p-6 border-2 border-black 
                shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-none 
                hover:translate-x-1 hover:translate-y-1 transition-all duration-300"
              >
                <p className="text-lg font-medium">
                  Get your materials in front of{" "}
                  <span className="text-theme-color font-bold">10,000+</span>{" "}
                  designers users!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialLibrarySteps;
