import ArticleImageOne from "../../../assets/images/Home/homeblog.jpg";
import { Layout } from "../../../components/layout/layout";
import { BlogBanner } from "../../../components/shared";

export const ArticleTwo = () => {
  const blogPost = {
    title:
      "Maximizing Your GoatArch Presence: Projects & Products Showcase Guide",
    category: "Platform Guide",
    author: "John Doe",
    readTime: "8 min read",
    publishDate: "Nov 11, 2024",
    bannerImage: ArticleImageOne,
    breadcrumbs: [
      { label: "Blog", url: "/blog" },
      { label: "Platform Guides", url: "/blog/guides" },
    ],
  };
  return (
    <Layout headerType="2">
      <section className="mt-20">
        <BlogBanner {...blogPost} />
      </section>
      <article className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction Section */}
        <div className="mb-12">
          <p className="text-lg text-gray-700 leading-relaxed">
            GoatArch empowers companies to showcase their work effectively
            through dedicated project and product listings. Understanding the
            right way to present your work can significantly impact your
            visibility and engagement with potential clients.
          </p>
        </div>

        {/* Profile Type Clarification */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Understanding Your Profile Type
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-gray-50 p-6 rounded-lg border-l-4 border-blue-500">
              <h3 className="font-semibold text-lg mb-4">
                Manufacturing Companies
              </h3>
              <ul className="space-y-2 text-gray-700">
                <li>• Can add products only</li>
                <li>• Featured in product browsing section</li>
                <li>• Detailed product specifications allowed</li>
              </ul>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg border-l-4 border-green-500">
              <h3 className="font-semibold text-lg mb-4">
                Architecture & Design Firms
              </h3>
              <ul className="space-y-2 text-gray-700">
                <li>• Can add projects only</li>
                <li>• Featured in project browsing section</li>
                <li>• Comprehensive project showcase options</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Adding Content Process */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Quick Upload Process</h2>
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                1
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">Access Dashboard</h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Log in to goatarch.com</li>
                  <li>Click on your company logo in the header</li>
                  <li>
                    Select "Add Project" or "Add Product" from the dropdown
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                2
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">Submit Content</h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Fill in all required details</li>
                  <li>Upload high-quality images</li>
                  <li>Add comprehensive descriptions</li>
                  <li>Submit for administrator approval</li>
                </ul>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                3
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">
                  Post-Approval Visibility
                </h3>
                <p className="text-gray-700">
                  Once approved, your content will be visible on:
                </p>
                <ul className="list-disc list-inside space-y-2 text-gray-700 mt-2">
                  <li>Your company profile page</li>
                  <li>GoatArch's project/product browsing sections</li>
                  <li>Search results for relevant keywords</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Best Practices */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Presentation Best Practices
          </h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-semibold text-lg mb-4">Image Guidelines</h3>
                <ul className="space-y-2 text-gray-700">
                  <li>• Use high-resolution photos</li>
                  <li>• Maintain consistent aspect ratios</li>
                  <li>• Include multiple angles</li>
                  <li>• Ensure proper lighting</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold text-lg mb-4">Content Tips</h3>
                <ul className="space-y-2 text-gray-700">
                  <li>• Write clear descriptions</li>
                  <li>• Include key specifications</li>
                  <li>• Add relevant tags</li>
                  <li>• Update regularly</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Maximize Your Reach</h2>
          <div className="bg-theme-color/5 p-6 rounded-lg">
            <h3 className="font-semibold text-lg mb-4">Share & Promote</h3>
            <p className="text-gray-700 mb-4">
              Your GoatArch profile serves as a comprehensive portfolio. Share
              your profile URL on:
            </p>
            <ul className="space-y-2 text-gray-700">
              <li>• LinkedIn company page</li>
              <li>• Professional networks</li>
              <li>• Business proposals</li>
              <li>• Marketing materials</li>
            </ul>
            <div className="mt-6 p-4 bg-white rounded-lg">
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Pro Tip:</span> Your GoatArch
                profile URL provides a professional, all-in-one showcase of your
                company's work, making it easier for potential clients to
                explore your portfolio.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section>
          <div className="bg-gray-50 p-6 rounded-lg text-center">
            <h2 className="text-xl font-semibold mb-4">
              Ready to Showcase Your Work?
            </h2>
            <p className="text-gray-700 mb-6">
              Start adding your projects or products today and expand your
              business reach through GoatArch's professional platform.
            </p>
            <button className="bg-theme-color text-white px-6 py-2 rounded-lg hover:bg-theme-color/90 transition-colors">
              Add Your First Project
            </button>
          </div>
        </section>
      </article>
    </Layout>
  );
};
