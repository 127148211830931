// import React from "react";
// import { Navigate } from "react-router-dom";

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const token = localStorage.getItem("authToken");

//   return token ? <Component {...rest} /> : <Navigate to="/" />;
// };

// export default PrivateRoute;

import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// Array of protected routes that require authentication
const PROTECTED_ROUTES = [
  "/dashboard",
  "/dashboard/create-products",
  "/dashboard/create-projects",
  "/dashboard/update-company-details",
];

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("authToken");
  const location = useLocation();

  // Check if current path is protected
  const isProtectedRoute = PROTECTED_ROUTES.some(
    (route) => location.pathname === route || location.pathname === route + "/" // Handle trailing slash
  );

  if (!token && isProtectedRoute) {
    // Redirect to login while saving the attempted URL
    return <Navigate to="/register/" state={{ from: location }} replace />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
