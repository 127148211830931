// import { CheckCircleIcon } from "@heroicons/react/24/outline";

// export const ExpertiseSection = ({ expertiseData }) => (
//     <section className="py-12 bg-gray-50">
//       <div className="container mx-auto px-4">
//         <h2 className="mt-8 text-2xl md:text-3xl font-bold">Our Expertise</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//           {expertiseData?.map((item, index) => (
//             <div key={index} className="bg-white p-6 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md">
//               <div className="flex items-start">
//                 <CheckCircleIcon className="h-6 w-6 text-theme-color flex-shrink-0 mr-3" />
//                 <span className="text-lg font-semibold text-gray-800">{item}</span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );

import React from 'react';
import { CheckCircle} from 'lucide-react';

export const ExpertiseSection = ({ expertiseData }) => (
  <section className="py-8">
    <div className="container mx-auto">
      <h2 className="mb-6 text-2xl md:text-3xl font-bold">Our Expertise</h2>
      <ul className="flex flex-wrap gap-4">
        {expertiseData?.map((item, index) => (
          <li key={index} className="flex items-center">
            <CheckCircle className="h-5 w-5 text-green-600 mr-2" />
            <span className="text-base text-gray-800">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  </section>
);