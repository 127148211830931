import React from "react";
import { Link } from "react-router-dom";
export const FeatureProjects = ({ projects }) => {
  return (
    <section className="py-16">
      <div className="flex flex-col px-4 lg:px-24">
        <div className="flex justify-between items-baseline">
          <h2
            className="font-bold text-theme-color text-left mb-10"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            <span className="text-black">GoatArch </span> Projects
          </h2>
          <Link to="/projects/" className="text-lg font-bold text-theme-color">
            View All
          </Link>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          {projects.map((project) => (
            <div
              key={project.id}
              className="relative overflow-hidden bg-white rounded group"
            >
              <Link
                to={`/projects/project-details/${project.id}-${project.project_name.replace(/\s+/g, '-')}/`}
                className="block"
                rel="nofollow"
              >
                <div className="relative">
                  <img
                    src={project?.banner_image}
                    alt="projectimage"
                    className="w-full h-72 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
                  />
                  {/* Category name in top right corner */}
                  <span className="absolute top-2 right-2 text-white text-sm font-semibold z-10">
                    {project.category
                      ? project.category.charAt(0).toUpperCase() +
                        project.category.slice(1)
                      : ""}
                  </span>
                  <div className="absolute top-0 left-0 p-4 bg-gradient-to-b from-transparent to-black/70 w-full h-full flex flex-col justify-end">
                    <span
                      className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300"
                      style={{ fontFamily: "Lato, sans-serif" }}
                    >
                      {project?.project_name}
                    </span>
                    <span className="text-white text-sm">
                      {project?.city_name}, {project?.country_name}
                    </span>
                    <span className="text-white text-sm">
                      By {project?.company_name}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
