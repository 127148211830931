import React from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/shared";
import image from "../../../assets/images/Company/browsing/background/company browsing (1).jpg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { HomeIcon } from "lucide-react";
import axios from "axios";

export const ResetPassword = () => {
  const { watch, control, handleSubmit } = useForm();
  const watchPassword = watch("password");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { token } = useParams();
  

  const onSubmit = async (formData) => {

    if (!token) {
      return alert("Invalid or missing token");
    }

    try {
      const response = await axios.post(
        `${baseURL}/api/password/reset-password`,
        {
          token,
          newPassword: formData.password,
        }
      );

      if (response.status === 200) {
        alert("Password reset successfully");
        navigate("/login");
      }
    } catch (error) {
      if (error.response) {
        console.error("API Response:", error.response.data);
        alert(`Failed to reset password: ${error.response.data.message}`);
      } else {
        alert("Failed to reset password. Please try again.");
      }
    }
  };

  return (
    <div className="full-width h-screen flex overflow-hidden">
      <div className="hidden flex-[1.2] lg:block">
        <img className="h-full w-full object-cover" src={image} alt="bg" />
      </div>
      <div className="flex-1 bg-theme-light-bg overflow-y-auto">
        <div className="min-h-full flex items-center justify-center py-[50px]">
          <div className="max-w-[400px] w-full">
            <h2 className="font-bold font-chivo text-[25px] leading-[30px] md:text-heading-3 mb-[50px] text-center">
              Reset Password
            </h2>
            <form
              className="bg-white w-full p-8 shadow-3 rounded-[6px] md:p-12"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                }}
                label="Password"
                type="password"
                placeholder="********"
                showPasswordStrength={true}
              />
              <Input
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watchPassword || "Passwords do not match",
                }}
                label="Confirm Password"
                type="password"
                placeholder="********"
              />
              <button
                className="mb-6 w-full text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
                type="submit"
              >
                Reset Password
              </button>
            </form>
            <div className="mt-5">
              <Link
                to="/"
                className="flex items-start justify-center gap-2 font-bold"
              >
                <HomeIcon className="w-4" /> Back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
