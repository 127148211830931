// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-down.arrows {
  width: 30px;
  height: 60px;
}

.arrow-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.scroll-down.arrows span {
  position: absolute;
  display: block;
  width: 25px;
  height: 25px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  animation: animate 2s infinite;
}

.scroll-down.arrows span:nth-child(2) {
  animation-delay: -0.2s;
}

.scroll-down.arrows span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Scroll Down Arrow/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,6BAA6B;EAC7B,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,UAAU;IACV,gDAAgD;EAClD;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,8CAA8C;EAChD;AACF","sourcesContent":[".scroll-down.arrows {\n  width: 30px;\n  height: 60px;\n}\n\n.arrow-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.scroll-down.arrows span {\n  position: absolute;\n  display: block;\n  width: 25px;\n  height: 25px;\n  border-bottom: 2px solid white;\n  border-right: 2px solid white;\n  transform: rotate(45deg);\n  animation: animate 2s infinite;\n}\n\n.scroll-down.arrows span:nth-child(2) {\n  animation-delay: -0.2s;\n}\n\n.scroll-down.arrows span:nth-child(3) {\n  animation-delay: -0.4s;\n}\n\n@keyframes animate {\n  0% {\n    opacity: 0;\n    transform: rotate(45deg) translate(-20px, -20px);\n  }\n\n  50% {\n    opacity: 1;\n  }\n\n  100% {\n    opacity: 0;\n    transform: rotate(45deg) translate(20px, 20px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
