import React from "react";
import { BreadcrumbComponentTwo } from "../../../components/shared/index";
import privacypolicybgimage from "../../../assets/images/Policy/termsandcondition.jpg";
import { Layout } from "../../../components/layout/layout";
import { PolicyPagesFooter } from "../Policy Footer";

export const TermsAndConditions = () => {
  return (
    <Layout>
      <section className="">
        <BreadcrumbComponentTwo
          title="Terms & Conditions"
          bgImage={privacypolicybgimage}
        />
        <main className="px-4 py-8 md:px-8 lg:px-24">
          <h1 className="text-3xl font-bold mb-8 text-theme-color">Terms and Conditions</h1>
          <p className="text-gray-600 mb-8 font-bold">Effective Date: 8-10-24</p>

          {[
            {
              title: "1. Acceptance of Terms",
              content:
                "Welcome to GoatArch Design. By accessing or using our platform, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.",
            },
            {
              title: "2. Description of Service",
              content:
                "GoatArch Design is a platform that allows professionals from various fields including architecture, interior design, and manufacturing to list their businesses, products, and projects for global visibility.",
            },
            {
              title: "3. User Accounts",
              content:
                "Users are responsible for maintaining the confidentiality of their account information. Users are entitled to list one business for free. Additional business listings require payment.",
            },
            {
              title: "4. Listing Services",
              content:
                "Manufacturers can list their company and products. Other professionals can list their company and are eligible to add projects. One product or project can be added for free. Additional listings require the purchase of a plan.",
            },
            {
              title: "5. Payment and Fees",
              content:
                "Some services on GoatArch Design require payment. Users agree to pay all fees or charges to their account based on the fees, charges, and billing terms in effect at the time a fee or charge is due and payable.",
            },
            {
              title: "6. User Content",
              content:
                "Users are solely responsible for the content they post on GoatArch Design. By posting content, users grant GoatArch Design a non-exclusive, worldwide, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content.",
            },
            {
              title: "7. Prohibited Activities",
              content:
                "Users agree not to engage in any activity that interferes with or disrupts the services or servers and networks connected to the services.",
            },
            {
              title: "8. Intellectual Property Rights",
              content:
                "The content on GoatArch Design, including but not limited to text, graphics, logos, and software, is the property of GoatArch Design or its content suppliers and is protected by international copyright laws.",
            },
            {
              title: "9. Termination",
              content:
                "GoatArch Design reserves the right to terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.",
            },
            {
              title: "10. Limitation of Liability",
              content:
                "In no event shall GoatArch Design, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the services.",
            },
            {
              title: "11. Changes to Terms",
              content:
                "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. It is your responsibility to check our Terms periodically for changes.",
            },
            {
              title: "12. Governing Law",
              content:
                "These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.",
            },
          ].map((section, index) => (
            <div
              key={index}
              className="mb-8 pb-8 border-b border-gray-200 last:border-b-0"
            >
              <h2 className="text-2xl font-semibold mb-4 text-theme-color">
                {section.title}
              </h2>
              <p className="text-gray-700 mb-4">{section.content}</p>
            </div>
          ))}
          <PolicyPagesFooter />

        </main>
      </section>
    </Layout>
  );
};