import React from "react";
import { PhotoDisplay } from '../../components/shared/index'

const CategoryItem = ({
  href,
  imgSrc,
  alt,
  label,
  onClick,
  companyCount,
  index,
}) => {
  return (
    <div
      className="col animate-item opacity-0 translate-y-4"
      style={{ animationDelay: `${index * 50}ms` }}
    >
      <a
        className="text-center group block h-full relative z-10 overflow-hidden rounded"
        href={href}
        onClick={onClick}
      >
        <div className="relative overflow-hidden rounded">
          {/* <img
            alt={alt}
            width={200}
            height={200}
            className="rounded w-full h-44 sm:h-56 mx-auto bg-white/10 transition-transform duration-300 group-hover:scale-110 object-cover"
            src={imgSrc}
            style={{ color: "transparent" }}
          /> */}
          <PhotoDisplay
            alt={alt}
            width={200}
            height={200}
            className="rounded w-full h-44 sm:h-56 mx-auto bg-white/10 transition-transform duration-300 group-hover:scale-110 object-cover"
            photoId={imgSrc}
            style={{ color: "transparent" }}
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 transition-all duration-300 group-hover:bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <span className="text-white text-xl font-medium uppercase">
              {companyCount} Companies
            </span>
          </div>
        </div>
        <p
          className="text-lg capitalize absolute bottom-4 sm:bottom-5 w-full text-white z-20 tracking-wide px-2"
          style={{ fontFamily: "Lato, sans-serif" }}
        >
          {label}
        </p>
      </a>
    </div>
  );
};

export default CategoryItem;
