import React from "react";
import { Layout } from "../../components/layout/layout";

const Pricing = () => {
  return (
    <Layout headerType="2">
      <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 lg:pb-[90px] lg:pt-[120px] px-6">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  Pricing Table
                </span>
                <h1 className="mb-3 font-bold leading-[1.208] text-black">
                  Our Pricing Plan
                </h1>
                <p className="text-base text-body-color">
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>

          <div className="-mx-4 flex flex-wrap justify-center">
            <div className="-mx-4 flex flex-wrap">
              <PricingCard
                type="Personal"
                price="$59"
                subscription="year"
                description="Perfect for using in a personal website or a client project."
                buttonText="Choose Personal"
              >
                <List>1 User</List>
                <List>All UI components</List>
                <List>Lifetime access</List>
                <List>Free updates</List>
                <List>Use on 1 (one) project</List>
                <List>3 Months support</List>
              </PricingCard>
              <PricingCard
                type="Business"
                price="$199"
                subscription="year"
                description="Perfect for using in a personal website or a client project."
                buttonText="Choose Business"
                active
              >
                <List>5 Users</List>
                <List>All UI components</List>
                <List>Lifetime access</List>
                <List>Free updates</List>
                <List>Use on 3 (three) projects</List>
                <List>4 Months support</List>
              </PricingCard>
              <PricingCard
                type="Professional"
                price="$256"
                subscription="year"
                description="Perfect for using in a personal website or a client project."
                buttonText="Choose Professional"
              >
                <List>Unlimited Users</List>
                <List>All UI components</List>
                <List>Lifetime access</List>
                <List>Free updates</List>
                <List>Unlimited projects</List>
                <List>12 Months support</List>
              </PricingCard>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Pricing;

const PricingCard = ({
  children,
  description,
  price,
  type,
  subscription,
  buttonText,
  active,
}) => {
  return (
    <div className="w-full px-4 md:w-1/2 lg:w-1/3">
      <div
        className={`relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-8 py-10 shadow-pricing sm:p-12 lg:px-6 lg:py-10 xl:p-[50px] ${
          active ? "border-primary" : "border-stroke"
        }`}
      >
        <span className="mb-3 block text-lg font-semibold text-primary">
          {type}
        </span>
        <h2 className="mb-5 text-[42px] font-bold text-theme-color">
          {price}
          <span className="text-base font-medium text-body-color">
            / {subscription}
          </span>
        </h2>
        <p className="mb-8 border-b border-stroke pb-8 text-base text-body-color">
          {description}
        </p>
        <div className="mb-9 flex flex-col gap-[14px]">{children}</div>
        <a
          href="/#"
          className={`block w-full rounded-md p-3 text-center text-base font-medium transition ${
            active
              ? "bg-primary text-theme-color hover:bg-opacity-90"
              : "border border-stroke text-primary hover:border-primary hover:bg-primary hover:text-theme-color"
          }`}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

const List = ({ children }) => {
  return <p className="text-base text-body-color">{children}</p>;
};
