import React from "react";
import { Input } from "../../../../components/shared";

export const CompanyStepFour = ({ control }) => {
  const currentYear = new Date().getFullYear(); // Dynamically get the current year

  return (
    <div className="space-y-6">
      <Input
        name="description"
        control={control}
        rules={{ required: "Description is required" }}
        label="Description"
        type="textarea"
        rows={7}
        placeholder="Enter your description here"
      />
      <Input
        name="establishedYear"
        control={control}
        label="Established Year (Optional)"
        type="number"
        placeholder="Enter Established Year"
        max={currentYear}
      />
    </div>
  );
};
