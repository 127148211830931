import React from "react";
import icon_flower from "../../../assets/images/About/iconflower.svg";
import { CloudIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const AboutSectionFour = () => {
  const services = [
    {
      icon: icon_flower,
      title: "Architects, Interior Designers, Landscape Designers",
      description:
        "You have an ability to bring ideas into a tangible creation. The creativity to shape the world, collaborate and build a team, exchange ideas and come with a great design.",
      buttonName: "Submit Project",
      buttonPath: "/dashboard/create-projects",
    },
    {
      icon: BuildingOffice2Icon,
      title: "Companies",
      description:
        "The design and construction industry is flourishing. Every SMEs and big construction companies are the integral part of the industry which is working on to bring ideas into reality.",
      buttonName: "Browse Top Companies",
      buttonPath: "/companies",
    },
    {
      icon: CloudIcon,
      title: "Products",
      description:
        "The product design process evolves around the industry need. A lot of research and analysis brings up some unique solution for the designers. A creative and quality product is the essence of any project. ",
      buttonName: "Showcase Products",
      buttonPath: "/dashboard/create-products",
    },
  ];

  return (
    <div className="px-4 lg:px-16 xl:px-24 py-16 lg:py-24">
      <div className="text-center mb-12 lg:mb-16">
        <span className="inline-block bg-black/80 text-theme-color py-2 px-4 rounded-full text-sm mb-4">
          What We Do, What You Get
        </span>
        <h2 className="text-3xl lg:text-4xl font-bold mb-4">
          Bringing Creative Ideas
        </h2>
        <p className="text-gray-600 max-w-3xl mx-auto">
          The GoatArch Community brings Ideas of creative people together on one
          dedicated platform. Designers across the globe display their Projects,
          Art and Products etc helping them to reach target potential clients.
        </p>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="border-8 border-[#F4E1CD] p-6 lg:p-8 rounded-lg transition-all duration-300 hover:-translate-y-1 flex flex-col"
          >
            {typeof service.icon === "string" ? (
              <img
                src={service.icon}
                alt={service.title}
                className="w-16 h-16 mb-6"
              />
            ) : (
              <service.icon className="w-16 h-16 mb-6 text-black" />
            )}
            <h3 className="text-xl font-bold mb-4">{service.title}</h3>
            <p className="text-gray-600 mb-auto">{service.description}</p>
            <Link
              className="mt-6 w-fit text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm px-10 py-3 flex justify-center"
              to={service.buttonPath}
            >
              {service.buttonName}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};