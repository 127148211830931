import React from "react";
import { BreadcrumbComponentTwo } from "../../../components/shared/index";
import termsOfUseBgImage from "../../../assets/images/Policy/termsandcondition.jpg";
import { Layout } from "../../../components/layout/layout";
import { PolicyPagesFooter } from "../Policy Footer";

export const ReturnAndRefundPolicy = () => {
  const refundPolicyContent = [
    {
      title: "1. Refund Eligibility",
      content:
        "As GoatArch Design primarily offers digital services in the form of business listings and visibility, our refund policy is limited. We do not offer refunds for completed transactions, including fees paid for additional business listings, product listings, or project additions.",
    },
    {
      title: "2. Subscription Plans",
      content:
        "If you have purchased a subscription plan for additional listings or features, you may cancel your subscription at any time. However, we do not provide partial refunds for unused portions of your subscription period.",
    },
    {
      title: "3. Listing Fees",
      content:
        "Fees paid for individual listings (beyond the free initial listing) are non-refundable once the listing has been published on our platform.",
    },
    {
      title: "4. Exceptions",
      content:
        "In the event of technical issues on our part that prevent your listing from being visible or functioning correctly, we may offer a refund or credit at our discretion. These cases will be evaluated on an individual basis.",
    },
    {
      title: "5. Refund Process",
      content:
        "If you believe you are eligible for a refund under the exceptions mentioned above, please contact our support team at support@wowdesign.com with your account details and a description of the issue.",
    },
    {
      title: "6. Changes to Refund Policy",
      content:
        "We reserve the right to modify this Refund Policy at any time. Any changes will be effective immediately upon posting on this page, with an updated effective date.",
    },
  ];

  return (
    <Layout>
      <section className="">
        <BreadcrumbComponentTwo
          title="Return and Refund Policy"
          bgImage={termsOfUseBgImage}
        />
        <main className="px-4 py-8 md:px-8 lg:px-24">
          <h1 className="text-3xl font-bold my-8 text-theme-color">
            Refund Policy
          </h1>
          <p className="text-gray-600 mb-8 font-bold">
            Effective Date: 8-10-24
          </p>

          {refundPolicyContent.map((section, index) => (
            <div
              key={index}
              className="mb-8 pb-8 border-b border-gray-200 last:border-b-0"
            >
              <h2 className="text-2xl font-semibold mb-4 text-theme-color">
                {section.title}
              </h2>
              <p className="text-gray-700 mb-4">{section.content}</p>
            </div>
          ))}

          <PolicyPagesFooter />

        </main>
      </section>
    </Layout>
  );
};
