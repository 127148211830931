import { Link } from "react-router-dom";
import { Layout } from "../../../components/layout/layout";
import { BlogBanner } from "../../../components/shared";
import BlogThreeImage from "../../../assets/images/Blogs/benefits.jpg";

export const DesignerBenefitsArticle = () => {
  const blogPost = {
    title: "How is GoatArch.com beneficial for designers ?",
    category: "Platform Guide",
    author: "Sarah Mitchell",
    readTime: "10 min read",
    publishDate: "Nov 13, 2024",
    bannerImage: BlogThreeImage,
    breadcrumbs: [
      { label: "Blog", url: "/blog" },
      { label: "Industry Insights", url: "/blog/insights" },
    ],
  };

  return (
    <Layout headerType="2">
      <section className="mt-20">
        <BlogBanner {...blogPost} />
      </section>
      <article className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction */}
        <div className="mb-12">
          <p className="text-lg text-gray-700 leading-relaxed">
            In today's digital age, designers in architecture, interior design,
            and landscape architecture need more than just creative skills to
            thrive. They need a platform that understands their unique needs and
            connects them with opportunities. GoatArch has emerged as a
            game-changing platform for design professionals, offering tools and
            features specifically crafted for the design community. Let's
            explore how GoatArch is transforming the way designers work,
            collaborate, and grow their practices.
          </p>
        </div>

        {/* Inspiration & Resources Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            A Hub of Inspiration and Resources
          </h2>
          <div className="space-y-6">
            <p className="text-gray-700">
              GoatArch serves as a comprehensive resource center for designers,
              offering:
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              <ul className="list-disc list-inside space-y-3 text-gray-700">
                <li>Curated collections of innovative design projects</li>
                <li>Detailed case studies of successful implementations</li>
                <li>Insights into emerging design trends and materials</li>
                <li>
                  Access to a vast library of architectural and design
                  references
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Professional Networking Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Enhanced Professional Networking
          </h2>
          <div className="bg-theme-color/5 p-6 rounded-lg mb-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-semibold text-lg mb-4">Connect With:</h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Fellow designers and architects</li>
                  <li>Material suppliers and vendors</li>
                  <li>Construction professionals</li>
                  <li>Potential clients and partners</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold text-lg mb-4">
                  Collaboration Tools:
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Project sharing capabilities</li>
                  <li>Direct messaging system</li>
                  <li>Team collaboration features</li>
                  <li>Resource sharing options</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Portfolio Showcase Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Professional Portfolio Showcase
          </h2>
          <div className="space-y-6">
            <p className="text-gray-700">
              GoatArch provides designers with powerful tools to showcase their
              work:
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-lg mb-4">
                  Portfolio Features
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>High-resolution project galleries</li>
                  <li>Detailed project descriptions</li>
                  <li>Before and after comparisons</li>
                  <li>Interactive 3D model support</li>
                </ul>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="font-semibold text-lg mb-4">
                  Visibility Benefits
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>SEO-optimized profiles</li>
                  <li>Featured project opportunities</li>
                  <li>Social media integration</li>
                  <li>Analytics and insights</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Business Growth Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Business Growth Opportunities
          </h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <div className="space-y-4">
              <p className="text-gray-700">
                The platform facilitates business growth through:
              </p>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>
                    Access to qualified leads and project opportunities
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Online reputation management tools</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Client review and testimonial features</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Marketing tools and promotional opportunities</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Knowledge Sharing Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Knowledge Sharing and Professional Development
          </h2>
          <div className="space-y-6">
            <p className="text-gray-700">
              GoatArch fosters professional growth through:
            </p>
            <div className="bg-theme-color/5 p-6 rounded-lg">
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Industry webinars and virtual events</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Design trend analysis and reports</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Best practice sharing among professionals</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Access to continuing education resources</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Conclusion */}
        <section>
          <div className="bg-gray-50 p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">
              Join the Design Revolution
            </h2>
            <p className="text-gray-700 mb-6">
              GoatArch is more than just a platform – it's a thriving ecosystem
              where designers can showcase their work, connect with peers, and
              grow their practices. Whether you're an established design firm or
              an emerging talent, GoatArch provides the tools and opportunities
              you need to succeed in today's competitive design landscape.
            </p>
            <div className="flex gap-4">
              <Link
                to={"/register/"}
                className="bg-theme-color text-white px-6 py-2 rounded-lg hover:bg-theme-color/90 transition-colors"
              >
                Join GoatArch Today
              </Link>
              <button className="border border-theme-color text-theme-color px-6 py-2 rounded-lg hover:bg-theme-color/10 transition-colors">
                Learn More
              </button>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default DesignerBenefitsArticle;
