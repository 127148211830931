import { useState } from "react";
import thubnailImage from "../../../assets/images/About/instgram cover.png";

export const AboutSectionThree = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <section className="px-6 my-24 lg:px-24">
      <div className="relative">
        <div className="bg-opacity-50 rounded-full opacity-50 blur-2xl absolute -translate-x-4 max-w-full bg-[#F5E2C6] w-full h-full translate-y-1/3"></div>
        <div className="lg:grid lg:grid-cols-2 gap-8 lg:gap-16">
          <div className="relative mb-8 lg:mb-0">
            <div className="relative w-full pb-[56.25%] h-0">
              {!isPlaying ? (
                <div
                  className="absolute top-0 left-0 w-full h-full cursor-pointer group overflow-hidden"
                  onClick={() => setIsPlaying(true)}
                >
                  <img
                    src={thubnailImage}
                    alt="Video thumbnail"
                    className="rounded w-full h-full object-cover group-hover:scale-105 transition-transform duration-700"
                  />
                  {/* Fancy Play Button */}
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[9]">
                    <div className="relative">
                      {/* Outer rotating ring */}
                      <div className="absolute -inset-2 bg-gradient-to-r from-pink-500 via-theme-color to-purple-500 rounded-full opacity-75 animate-spin-slow blur-sm group-hover:opacity-100 transition-opacity duration-300"></div>
                      {/* Main play button */}
                      <div className="relative w-20 h-20 bg-white/90 backdrop-blur-sm rounded-full flex items-center justify-center shadow-lg group-hover:scale-110 transition-all duration-300">
                        <div className="w-16 h-16 bg-gradient-to-br from-theme-color to-purple-600 rounded-full flex items-center justify-center animate-pulse">
                          {/* Play icon with gradient */}
                          <div className="w-12 h-12 flex items-center justify-center relative">
                            <div className="absolute inset-0 bg-gradient-to-tr from-white to-white/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                            <svg
                              className="w-8 h-8 text-white fill-current ml-1 relative z-10 group-hover:scale-110 transition-transform duration-300"
                              viewBox="0 0 24 24"
                            >
                              <path d="M8 5v14l11-7z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Hover overlay */}
                  <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"></div>
                </div>
              ) : (
                <iframe
                  className="absolute top-0 left-0 w-full h-full rounded"
                  src="https://www.youtube.com/embed/-DLMDxK67gg?autoplay=1&mute=0&controls=1&showinfo=0&rel=0"
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
          <div>
            <h2>What you get?</h2>
            <p className="text-black mb-8">How we work</p>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:gap-8">
              {[1, 2, 3, 4].map((step) => (
                <div key={step} className="mb-6 lg:mb-0">
                  <span className="text-theme-color mb-4 text-[32px] lg:text-[46px]">
                    0{step}
                  </span>
                  <div className="flex items-baseline mb-4 gap-2">
                    <h5>
                      {step === 1 &&
                        "Showcase your creative works and Ideas which are realised"}
                      {step === 2 &&
                        "An exclusive company listing to display your works, brands and get associated with accomplished projects"}
                      {step === 3 &&
                        "Getting Connected with right brands and companies to work with for your future projects"}
                      {step === 4 &&
                        "Get inspired by reading exclusive thoughts and ideas of industry partners"}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
