import React, { useState, useEffect } from 'react';
import { MdOutlineViewAgenda } from "react-icons/md";
import { MdGridView } from "react-icons/md";
import { TfiLayoutGrid3 } from "react-icons/tfi";

const ImageDisplay = ({ images, SectionHeading }) => {
  const [gridColumns, setGridColumns] = useState(null);
  const [availableLayouts, setAvailableLayouts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    
    const layouts = determineAvailableLayouts(images?.length || 0);
    setAvailableLayouts(layouts);

    setGridColumns(layouts[layouts.length - 1]);

    return () => window.removeEventListener('resize', handleResize);
  }, [images]);

  const determineAvailableLayouts = (imageCount) => {
    if (isMobile) {
      if (imageCount === 0) return [1];
      if (imageCount === 1) return [1];
      return [1, 2];
    }
    
    if (imageCount === 0) return [1];
    if (imageCount === 1) return [1];
    if (imageCount === 2) return [1, 2];
    return [1, 2, 3];
  };

  const handleLayoutChange = (columns) => {
    setGridColumns(columns);
  };

  const getImageContainerClass = () => {
    if (gridColumns === 1) {
      if (images?.length === 1) {
        return "w-full h-[250px] lg:h-[750px] relative"; 
      }
      return "aspect-video w-full h-[250px] lg:h-[750px]"; 
    }
    return "aspect-square"; 
  };

  const getGridClass = () => {
    const baseClass = "grid gap-4 ";
    if (gridColumns === 1) return baseClass + "grid-cols-1";
    if (gridColumns === 2) return baseClass + "grid-cols-2";
    return baseClass + "grid-cols-3";
  };

  const LayoutButton = ({ columns, icon: Icon }) => {
    if (!availableLayouts.includes(columns)) return null;

    return (
      <button
        onClick={() => handleLayoutChange(columns)}
        className={`p-2 rounded transition-colors ${
          gridColumns === columns
            ? "bg-theme-light-bg"
            : "bg-gray-100 hover:bg-theme-light-bg"
        }`}
      >
        <Icon className="w-6 h-6" />
      </button>
    );
  };

  return (
    <section className="w-full mt-8">
      {/* Layout Control Icons */}
      <div className="flex justify-between gap-6 mb-6">
        <div>
          <h2
            className="text-2xl md:text-3xl font-bold"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            {SectionHeading}
          </h2>
        </div>
        <div className="flex gap-6">
          <LayoutButton columns={1} icon={MdOutlineViewAgenda} />
          <LayoutButton columns={2} icon={MdGridView} />
          {!isMobile && <LayoutButton columns={3} icon={TfiLayoutGrid3} />}
        </div>
      </div>

      {/* Images Grid */}
      <div className={getGridClass()}>
        {images?.map((image, index) => (
          <div
            key={index}
            className={`${getImageContainerClass()} overflow-hidden rounded cursor-pointer`}
            // onClick={() => console.log("Image clicked:", image)}
          >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              className={`w-full h-full object-cover hover:scale-105 transition-transform duration-300 ${
                images?.length === 1 ? 'aspect-square' : 'object-cover'
              }`}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ImageDisplay;