import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  CompanyStepOne,
  CompanyStepThree,
  CompanyStepFour,
  CompanyStepFive,
  CompanyStepSeven,
  CompanyStepEight,
} from "./Steps";

import {
  Popup,
  SiteLogo,
  Input,
  Toast,
  SEO,
  LoaderTwo,
} from "../../components/shared";
import { HomeIcon } from "@heroicons/react/24/outline";

// Import step images here
import companyImage1 from "../../assets/images/Register/registerpage.jpeg";
import { LogInIcon } from "lucide-react";
const steps = [
  { title: "Company Details", component: CompanyStepOne, image: companyImage1 },
  {
    title: "Contact & Address Details",
    component: CompanyStepThree,
    image: companyImage1,
  },
  {
    title: "About Company",
    component: CompanyStepFour,
    image: companyImage1,
  },
  {
    title: "Profile Media",
    component: CompanyStepFive,
    image: companyImage1,
  },
  {
    title: "Gallery Or Work",
    component: CompanyStepSeven,
    image: companyImage1,
  },
  {
    title: "Company Social Media Handles",
    component: CompanyStepEight,
    image: companyImage1,
  },
];

const AnimatedSpinner = ({ size }) => {
  return (
    <div
      className="buttonloader"
      style={{
        width: size.width ? `${size.width}px` : "24px",
        height: size.height ? `${size.height}px` : "24px",
      }}
    />
  );
};

export const CompanyProfileSetup = () => {
  const [currentStep, setCurrentStep] = useState(-1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [emailVerified, setEmailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifyEmailLoading, setIsVerifyEmailLoading] = useState(false);
  const [isOtpVerifyLoading, setIsOtpVerifyLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [submissionState, setSubmissionState] = useState("idle");
  const [ipAddress, setIpAddress] = useState("");
  // console.log("ipAddress", ipAddress);

  const baseURL = process.env.REACT_APP_BASE_URL;
  // console.log(baseURL);

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      socialMedia: [],
    },
  });
  const watchPassword = watch("password");
  const watchEmail = watch("email");
  const watchUsername = watch("username");
  // console.log("watchEmail", watchEmail);

  const sendOtp = async () => {
    setIsVerifyEmailLoading(true);
    try {
      const data = JSON.stringify({ email: watchEmail });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseURL}/api/verify/verify-company-email`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      if (response.data.success) {
        // console.log("OTP sent successfully:", response.data);
        setIsOtpPopupOpen(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
        setShowToast(true);
      }
    } finally {
      setIsVerifyEmailLoading(false);
    }
  };

  const OtpVerificationPopup = ({ isOpen, onClose, onVerify, email }) => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const otpInputRefs = useRef([]);

    const handleChange = (element, index) => {
      if (isNaN(element.value)) return false;

      const updatedOtp = [...otp];
      updatedOtp[index] = element.value;
      setOtp(updatedOtp);

      if (element.value && element.nextSibling) {
        element.nextSibling.focus();
      }
    };

    const handleBackspace = (e, index) => {
      e.preventDefault();

      const updatedOtp = [...otp];
      // Only clear current input if it has a value
      if (updatedOtp[index]) {
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
      // Move to previous input only if current input is empty
      else if (index > 0) {
        updatedOtp[index - 1] = "";
        setOtp(updatedOtp);
        otpInputRefs.current[index - 1]?.focus();
      }
    };
    const handleVerify = async () => {
      const trimmedOtp = otp.join("").trim();
      const data = { email, otp: trimmedOtp };

      try {
        setIsLoading(true);
        const response = await fetch(`${baseURL}/api/verify/verify-otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.status === 200) {
          onVerify();
          onClose();
        } else {
          const errorData = await response.json();
          setError(
            errorData.message || "Error verifying OTP. Please try again."
          );
        }
      } catch (error) {
        setError("Network error. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Popup isOpen={isOpen} onClose={onClose} showCloseIcon={false}>
        <div className="flex flex-col items-center justify-center space-y-4 p-6">
          <h2 className="text-xl font-bold">Email Verification</h2>
          <p className="text-gray-600 text-sm text-center">
            Enter the 6-digit OTP sent to your email.
          </p>
          <div className="flex justify-center space-x-2">
            {otp.map((value, index) => (
              <input
                key={index}
                ref={(ref) => (otpInputRefs.current[index] = ref)}
                className="w-10 h-12 border-2 rounded bg-gray-100 border-gray-300 text-center text-xl outline-none focus:border-theme-color"
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    handleBackspace(e, index);
                  }
                }}
                inputMode="numeric"
              />
            ))}
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {/* <button
            onClick={handleVerify}
            disabled={isLoading}
            className="px-6 py-2 bg-theme-color text-white hover:bg-black transition-all duration-200 rounded relative"
          > */}
          {isLoading ? (
            <button
              onClick={handleVerify}
              disabled={isLoading}
              className="px-6 py-2 flex items-center gap-2 justify-center bg-black text-white transition-all duration-200 rounded relative"
            >
              <span className="text-white">Verifying</span>
              <AnimatedSpinner size={{ width: 24, height: 24 }} />
            </button>
          ) : (
            <button
              onClick={handleVerify}
              disabled={isLoading}
              className="px-6 py-2 bg-theme-color text-white hover:bg-black transition-all duration-200 rounded relative"
            >
              Verify
            </button>
          )}
          {/* </button> */}
        </div>
      </Popup>
    );
  };

  useEffect(() => {
    const fetchIPData = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        setIpAddress(response.data);
      } catch (error) {
        console.error("Error fetching IP data: ", error);
      }
    };

    fetchIPData();
  }, []);

  const onSubmit = async (data) => {
    const updatedFormData = { ...formData, ...data };
    setFormData(updatedFormData);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      try {
        setIsLoading(true);
        setSubmissionState("submitting");
        setIsPopupOpen(true);

        const formDataToSend = new FormData();

        if (updatedFormData.bannerImage) {
          formDataToSend.append("banner_image", updatedFormData.bannerImage);
        }

        if (updatedFormData.logo) {
          formDataToSend.append("company_logo_image", updatedFormData.logo);
        }

        if (
          updatedFormData.galleryImages &&
          updatedFormData.galleryImages.length > 0
        ) {
          updatedFormData.galleryImages.forEach((image) => {
            formDataToSend.append("gallery_images", image);
          });
        }

        const socialMedia = {
          instagramUrl: updatedFormData.socialMedia.instagramUrl,
          linkedinUrl: updatedFormData.socialMedia.linkedinUrl,
          facebookUrl: updatedFormData.socialMedia.facebookUrl,
          youtubeUrl: updatedFormData.socialMedia.youtubeUrl,
        };

        const dataToSend = {
          username: updatedFormData.username,
          email: updatedFormData.email,
          password: updatedFormData.password,
          role: "COMPANY",
          login_as: "COMPANY",
          company_name: updatedFormData.username,
          primary_company_type: updatedFormData.primaryType,
          secondary_company_type: updatedFormData.secondaryType,
          company_phone_number: updatedFormData.phone,
          company_email: updatedFormData.companyEmail,
          company_website: updatedFormData.companyWebsite,
          country: updatedFormData.country,
          state: updatedFormData.state,
          city: updatedFormData.city,
          company_zipcode: updatedFormData.zip,
          company_description: updatedFormData.description,
          business_type: updatedFormData.businessType,
          number_of_employees: updatedFormData.numberOfEmployees,
          annual_revenue: updatedFormData.revenue,
          year_of_establishment: updatedFormData.establishedYear,
          company_location: updatedFormData.address,
          company_social_media: socialMedia,
          company_video_links: updatedFormData.videoEmbLink,
          company_ip: ipAddress,
          user_approved: 0,
        };

        // console.log("Appending other data:", dataToSend);

        formDataToSend.append("data", JSON.stringify(dataToSend));

        const response = await axios.post(
          `${baseURL}/api/users/signup`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.data && response.data.status === true) {
          // Show popup only if the status is true
          setSubmissionState("success");
          setIsPopupOpen(true);
        }

        // console.log("API Response:", response.data);
        setIsLoading(false);

        // Set timeout for redirect after success
        setTimeout(() => {
          setIsPopupOpen(false);
          navigate("/");
        }, 2000);
      } catch (error) {
        console.error("API Error:", error);
        setIsLoading(false);
        setSubmissionState("error");
        setIsPopupOpen(true);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = () => {
    if (currentStep === -1) {
      return (
        <div>
          <Input
            name="username"
            control={control}
            rules={{ required: "This field is required" }}
            label="Company Name"
            placeholder="Acme Corporation"
          />
          <Input
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            label="Email"
            type="email"
            placeholder="info@acmecorp.com"
            disabled={emailVerified}
          />
          {emailVerified ? (
            <>
              <Input
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                }}
                label="Password"
                type="password"
                placeholder="********"
                showPasswordStrength={true}
              />
              <Input
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watchPassword || "Passwords do not match",
                }}
                label="Confirm Password"
                type="password"
                placeholder="********"
              />
            </>
          ) : null}
        </div>
      );
    }

    const CurrentStepComponent = steps[currentStep]?.component;
    return CurrentStepComponent ? (
      <CurrentStepComponent control={control} errors={errors} watch={watch} />
    ) : null;
  };

  const renderButtons = () => {
    if (currentStep === -1) {
      if (!emailVerified) {
        return (
          <button
            type="button"
            onClick={sendOtp}
            className={`w-full text-white font-bold shadow-sm py-[13px] relative cursor-pointer transition-colors duration-200 ${
              isVerifyEmailLoading
                ? "bg-black hover:bg-black"
                : "bg-theme-color hover:bg-black"
            }`}
            disabled={
              isVerifyEmailLoading ||
              !watchEmail ||
              !watchUsername ||
              errors.email ||
              errors.username
            }
          >
            {isVerifyEmailLoading ? (
              <div className="flex gap-2 justify-center items-center">
                <span className="">Please wait</span>
                <AnimatedSpinner size={{ width: 24, height: 24 }} />
              </div>
            ) : (
              "Verify Email"
            )}
          </button>
        );
      } else {
        return (
          <button
            type="submit"
            className="w-full bg-theme-color text-white hover:bg-black transition-all duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
            disabled={isLoading}
          >
            Next
          </button>
        );
      }
    }

    return (
      <div className="flex justify-between gap-6 mt-6">
        <button
          type="button"
          onClick={prevStep}
          className="w-full text-white bg-black hover:bg-theme-color transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
        >
          Previous
        </button>
        <button
          type="submit"
          className="w-full text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
          disabled={isLoading} // Disable the button if loading
        >
          {currentStep < steps.length - 1 ? "Next" : "Submit"}
        </button>
      </div>
    );
  };

  const getCurrentBackgroundImage = () => {
    if (currentStep === 0) {
      return companyImage1;
    }
    return currentStep === -1
      ? companyImage1
      : steps[currentStep]?.image || companyImage1;
  };

  const renderPopupContent = () => {
    if (submissionState === "submitting") {
      return (
        <div className="flex flex-col items-center justify-center w-[500px] min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <LoaderTwo text={"Submiting"} />
          <p className="text-red-600 text-lg md:text-xl font-semibold text-center">
            Please do not refresh or leave this page
          </p>
        </div>
      );
    }

    if (submissionState === "success") {
      return (
        <div className="flex flex-col items-center justify-center w-[500px] min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <h2 className="text-2xl md:text-3xl lg:text-4xl text-[#4BB543] font-bold text-center">
            Success!
          </h2>
          <p className="text-gray-600 text-lg md:text-xl lg:text-2xl text-center">
            Thank you for registering your Company in GoatArch Community.
          </p>
          <p className="text-base text-black text-center">
            Your profile is under review of administration. Once your profile is
            approved, you will receive an email, then you can login and enjoy
            our services.
          </p>
          <p className="text-sm text-gray-600 text-center">
            Redirecting to home page...
          </p>
        </div>
      );
    }

    if (submissionState === "error") {
      return (
        <div className="flex flex-col items-center justify-center w-[500px] min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center text-red-600">
            Error
          </h2>
          <p className="text-gray-600 text-lg text-center">
            There was an error submitting your registration. Please try again.
          </p>
          <button
            onClick={() => {
              setIsPopupOpen(false);
              setSubmissionState("idle");
            }}
            className="px-6 py-2 bg-theme-color text-white rounded hover:bg-black transition-all"
          >
            Close
          </button>
        </div>
      );
    }
  };

  return (
    <div>
      <SEO
        canonicalUrl={"https://goatarch.com/register"}
        description={
          "Join GoatArch today! Register as an architect, interior designer, or manufacturer to showcase your work, connect with professionals, and reach a global audience."
        }
        title={"Register"}
      />
      <div className="full-width h-screen flex overflow-hidden">
        <div className="hidden flex-[1.2] lg:block">
          <img
            className="h-full w-full object-cover"
            src={getCurrentBackgroundImage()}
            alt={`Step ${currentStep} background`}
          />
        </div>
        <div className="flex-1 bg-theme-light-bg overflow-y-auto">
          <div className="min-h-full flex items-center justify-center py-[50px]">
            <div className="max-w-[450px] w-full">
              <h2 className="font-bold font-chivo text-[25px] leading-[30px] md:text-heading-3 mb-[50px] text-center">
                {currentStep === -1
                  ? "Let's create by registering your company"
                  : steps[currentStep]?.title}
              </h2>
              <form
                className="bg-white w-full p-8 shadow-3 rounded-[6px] md:p-12"
                onSubmit={handleSubmit(onSubmit)}
              >
                {renderStep()}
                {renderButtons()}
              </form>
              <Link
                className="flex mt-10 items-center z-10 relative transition-all duration-200 group p-3 w-full rounded-md bg-white text-gray-500 hover:text-gray-900 hover:-translate-y-[2px] flex-row-reverse mb-[30px]"
                to={`login/`}
              >
                <span className="block text-inherit w-full h-full rounded-md text-base font-semibold">
                  Already have an account? Login
                </span>
                <LogInIcon className="mr-5 text-black" />
              </Link>
              <div className="mt-5 flex flex-col items-center justify-center gap-2">
                <Link
                  to="/"
                  className="flex items-center justify-center gap-2 font-bold"
                >
                  <HomeIcon className="w-4" /> Back to home
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={() => {
            if (submissionState !== "submitting") {
              setIsPopupOpen(false);
            }
          }}
          showCloseIcon={submissionState !== "submitting"}
        >
          {renderPopupContent()}
        </Popup>
        <OtpVerificationPopup
          isOpen={isOtpPopupOpen}
          onClose={() => setIsOtpPopupOpen(false)}
          onVerify={() => {
            setEmailVerified(true);
            setIsOtpPopupOpen(false);
          }}
          email={watchEmail}
        />
        {showToast && (
          <Toast message={errorMessage} onClose={() => setShowToast(false)} />
        )}
      </div>
    </div>
  );
};
