import React from 'react';
import { Share2, Linkedin, Twitter, Facebook, Send } from 'lucide-react';

export const ArticleSocialShare = ({ url, title }) => {
  const encodedUrl = encodeURIComponent(url || window.location.href);
  const encodedTitle = encodeURIComponent(title || document.title);

  const shareLinks = [
    {
      name: 'LinkedIn',
      icon: <Linkedin className="w-5 h-5" />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
      color: 'bg-[#0077b5] hover:bg-[#0077b5]/90'
    },
    {
      name: 'Twitter',
      icon: <Twitter className="w-5 h-5" />,
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
      color: 'bg-[#1DA1F2] hover:bg-[#1DA1F2]/90'
    },
    {
      name: 'Facebook',
      icon: <Facebook className="w-5 h-5" />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      color: 'bg-[#3b5998] hover:bg-[#3b5998]/90'
    },
    {
      name: 'WhatsApp',
      icon: <Send className="w-5 h-5" />,
      url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`,
      color: 'bg-[#25D366] hover:bg-[#25D366]/90'
    }
  ];

  return (
    <div className="flex flex-col items-center gap-4 my-8">
      <div className="flex items-center gap-2">
        <Share2 className="w-5 h-5 text-gray-600" />
        <span className="text-gray-600 font-medium">Share this article</span>
      </div>
      <div className="flex flex-wrap justify-center gap-3">
        {shareLinks.map((platform) => (
          <a
            key={platform.name}
            href={platform.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center gap-2 px-4 py-2 rounded-lg text-white transition-colors ${platform.color}`}
            onClick={(e) => {
              e.preventDefault();
              window.open(platform.url, '_blank', 'width=600,height=400');
            }}
          >
            {platform.icon}
            <span>{platform.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};