import React from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/shared";
import image from "../../../assets/images/Company/browsing/background/company browsing (1).jpg";
import { Link, useNavigate } from "react-router-dom";
import { HomeIcon } from "lucide-react";
import axios from "axios";

export const ForgotPassword = () => {
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const onSubmit = async (formData) => {
    try {
      // Configuring the axios request
      let data = JSON.stringify({
        email: formData.email,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseURL}/api/password/forgot-password`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // Making the axios request
      const response = await axios.request(config);
      if (response.status === 200) {
        alert("Please check your registered mail");
        navigate("/login");
      }
    } catch (error) {
      // console.log("Error:", error);
    }
  };

  return (
    <div className="full-width h-screen flex overflow-hidden">
      <div className="hidden flex-[1.2] lg:block">
        <img className="h-full w-full object-cover" src={image} alt="bg" />
      </div>
      <div className="flex-1 bg-theme-light-bg overflow-y-auto">
        <div className="min-h-full flex items-center justify-center py-[50px]">
          <div className="max-w-[400px] w-full">
            <h2 className="font-bold font-chivo text-[25px] leading-[30px] md:text-heading-3 mb-[50px] text-center">
              Forgot Password
            </h2>
            <form
              className="bg-white w-full p-8 shadow-3 rounded-[6px] md:p-12"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                label="Email"
                type="email"
                placeholder="johnsmith@gmail.com"
              />
              <button
                className="mb-6 w-full text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
                type="submit"
              >
                Submit
              </button>
            </form>
            <div className="mt-5">
              <Link
                to="/"
                className="flex items-start justify-center gap-2 font-bold"
              >
                <HomeIcon className="w-4" /> Back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
