import React from "react";
import { Link } from "react-router-dom";

export const CompanyProjects = ({ projects }) => {
  // console.log("Project Component :-", projects);

  return (
    <section className="mt-10">
      <div className="flex justify-between items-baseline my-10">
        <h3
          className="font-bold text-black text-left"
          style={{ fontFamily: "Lato, sans-serif" }}
        >
          Projects
        </h3>
        <a href="#tabtop" className="text-theme-color font-bold">
          Go to top
        </a>
      </div>
      <div className="flex flex-col">
        {projects && projects.length > 0 ? ( // Agar projects hain to hi yeh grid show hogi
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
            {projects?.map((project) => (
              <div
                key={project?.id}
                className="relative overflow-hidden bg-white rounded group"
              >
                 <Link
                  to={`/projects/project-details/${project.id}-${project?.project_name.replace(/\s+/g, '-')}/`} // yaha project.id URL me pass kar rahe hain
                  className="block"
                >
                  <div className="relative">
                    <img
                      src={project.project_image[0]} // Pehle image ko card par dikhate hain
                      alt={project.project_name}
                      className="w-full h-72 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
                    />
                    {/* Category name in top right corner */}
                    <span className="absolute top-2 right-2 text-white text-sm font-semibold z-10">
                      {project.category
                        ? project.category.charAt(0).toUpperCase() +
                          project.category.slice(1)
                        : ""}
                    </span>
                    <div className="absolute top-0 left-0 p-4 bg-gradient-to-b from-transparent to-black/70 w-full h-full flex flex-col justify-end">
                      <span
                        className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300"
                        style={{ fontFamily: "Lato, sans-serif" }}
                      >
                        {project.project_name}
                      </span>
                      <span className="text-white text-sm">
                        {project.city_name}, {project.country_name}
                      </span>
                      <span className="text-white text-sm">
                        By {project.company_name}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500 font-semibold">
            No Projects Available
          </div>
        )}
      </div>
    </section>
  );
};
