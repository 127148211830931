import React, { useState } from "react";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout/index";
import { Helmet } from "react-helmet";

// Import or create these components
import { ExistingDetailsUpdate } from "./Update Details";
import ImageUpdate from "./Images Update";

const tabs = [
  {
    id: "existing",
    label: "Details Update",
    component: ExistingDetailsUpdate,
  },
  {
    id: "addGallery",
    label: "Media Updates",
    component: ImageUpdate,
  },
];

export const UpdateCompanyDetails = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <DashboardLayout>
      <Helmet>
        <title>Update Company Details | GoatArch Design and Products</title>
      </Helmet>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Update Company Details</h1>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm
                  ${
                    activeTab === tab.id
                      ? "border-theme-color text-black"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-theme-color"
                  }
                `}
              >
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
        <div className="mt-6">
          {tabs.map(
            (tab) => activeTab === tab.id && <tab.component key={tab.id} />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};
