import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Building2,
  PencilRuler,
  Factory,
  Users,
  Palette,
  FileText,
  Upload,
  Camera,
  HandshakeIcon,
  Package,
  Film,
  ChevronRight,
  ChevronDown,
  X,
} from "lucide-react";

const ModernProcessFlow = () => {
  const [activeStep, setActiveStep] = useState(null);

  const processSteps = [
    {
      id: 1,
      title: "Company Registration",
      description: "Begin your journey by establishing your presence",
      icon: <Building2 className="w-6 h-6" />,
      color: "bg-[#E6BC8E]",
      steps: [
        {
          icon: <Users className="w-4 h-4" />,
          title: "Profile Creation",
          detail: "Build your company's digital identity",
        },
        {
          icon: <Palette className="w-4 h-4" />,
          title: "Brand Integration",
          detail: "Showcase your company's unique offerings",
        },
        {
          icon: <FileText className="w-4 h-4" />,
          title: "Documentation",
          detail: "Complete your business credentials",
        },
      ],
    },
    {
      id: 2,
      title: "Project Showcase",
      description: "Document your architectural masterpieces",
      icon: <PencilRuler className="w-6 h-6" />,
      color: "bg-[#a3b18a]",
      steps: [
        {
          icon: <Upload className="w-4 h-4" />,
          title: "Design Upload",
          detail: "Share your architectural vision",
        },
        {
          icon: <Camera className="w-4 h-4" />,
          title: "Visual Documentation",
          detail: "Capture your project's essence",
        },
        {
          icon: <HandshakeIcon className="w-4 h-4" />,
          title: "Collaboration Details",
          detail: "Highlight partnership success",
        },
      ],
    },
    {
      id: 3,
      title: "Product Catalog",
      description: "Showcase your manufacturing excellence",
      icon: <Factory className="w-6 h-6" />,
      color: "bg-[#284b63]",
      steps: [
        {
          icon: <Package className="w-4 h-4" />,
          title: "Product Details",
          detail: "Comprehensive product specifications",
        },
        {
          icon: <Film className="w-4 h-4" />,
          title: "Installation Guides",
          detail: "Clear implementation instructions",
        },
      ],
    },
  ];

  return (
    <div className="w-full" id="process">
      {/* Header Section */}
      <div className="py-16">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <h2 className="mb-4">
              Our <span className="text-theme-color">Process</span>
            </h2>
            <p className="text-lg max-w-2xl mx-auto">
              Experience our seamless workflow designed for industry
              professionals
            </p>
          </motion.div>
        </div>
      </div>

      {/* Process Timeline */}
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col gap-8">
          {processSteps.map((step, index) => (
            <motion.div
              key={step.id}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="relative"
            >
              {/* Step Card */}
              <motion.div
                className={`bg-white rounded-xl shadow-lg overflow-hidden
                  transition-all duration-300 ${
                    activeStep === step.id ? "" : ""
                  }`}
              >
                {/* Step Header */}
                <button
                  onClick={() =>
                    setActiveStep(activeStep === step.id ? null : step.id)
                  }
                  className="w-full text-left"
                >
                  <div className="flex items-center p-6 gap-6">
                    {/* Step Number */}
                    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-theme-light-bg flex items-center justify-center text-theme-color font-bold">
                      {step.id}
                    </div>

                    {/* Step Icon */}
                    <div className={`p-3 ${step.color} rounded-xl text-white`}>
                      {step.icon}
                    </div>

                    {/* Step Info */}
                    <div className="flex-1">
                      <h3 className="text-xl font-bold text-gray-800">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 mt-1">{step.description}</p>
                    </div>

                    {/* Toggle Icon */}
                    <motion.div
                      animate={{ rotate: activeStep === step.id ? 180 : 0 }}
                      className="flex-shrink-0 text-gray-400"
                    >
                      <ChevronDown className="w-6 h-6" />
                    </motion.div>
                  </div>
                </button>

                {/* Sub Steps */}
                <AnimatePresence>
                  {activeStep === step.id && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="border-t border-gray-100"
                    >
                      <div className="p-6 grid gap-4">
                        {step.steps.map((subStep, idx) => (
                          <motion.div
                            key={idx}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: idx * 0.1 }}
                            className="flex items-center p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                          >
                            <div
                              className={`p-2 ${step.color} rounded-lg text-white`}
                            >
                              {subStep.icon}
                            </div>
                            <div className="ml-4 flex-1">
                              <h4 className="font-semibold text-gray-800">
                                {subStep.title}
                              </h4>
                              <p className="text-gray-600 text-sm mt-1">
                                {subStep.detail}
                              </p>
                            </div>
                            <ChevronRight className="w-5 h-5 text-gray-400" />
                          </motion.div>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>

              {/* Connector Line */}
              {/* {index < processSteps.length - 1 && (
                <div className="absolute left-[2.5rem] top-[4.5rem] bottom-[-2rem] w-0.5 bg-blue-100" />
              )} */}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModernProcessFlow;
