import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  IndividualProfileUpdateStepOne,
} from "./Steps";
import { Popup, Loader, SiteLogo } from "../../../components/shared/index";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import { Helmet } from "react-helmet";

const pageVariants = {
  initial: { opacity: 0, y: 20, scale: 0.95 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { type: "spring", stiffness: 100, damping: 20 },
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.95,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
};

const steps = [
  { title: "Personal Details", component: IndividualProfileUpdateStepOne },
];

export const UpdateIndividualProfile = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
    control,
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem("userProfileData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      Object.keys(parsedData).forEach((key) => setValue(key, parsedData[key]));
    }
  }, [setValue]);

  const onSubmit = (data) => {
    const formDataWithFiles = {
      ...data,
      bannerImage: data.bannerImage ? data.bannerImage.name : null,
      profileImage: data.profileImage ? data.profileImage.name : null,
    };
    localStorage.setItem("userProfileData", JSON.stringify(formDataWithFiles));
    setIsSubmitted(true);
    setIsPopupOpen(true);
  };

  useEffect(() => {
    let timer;
    if (isPopupOpen) {
      timer = setTimeout(() => {
        setIsPopupOpen(false);
        navigate("/user-profile");
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isPopupOpen, navigate]);

  const validateStep = async (stepNumber) => {
    const stepValidations = [
      ["fullName", "email", "phoneNumber", "address", "country", "description"],
      ["linkedin", "twitter", "facebook", "instagram"],
      [
        "companyCount",
        "company1",
        "company2",
        "company3",
        "company4",
        "company5",
      ],
      ["bannerImage", "profileImage"],
    ];

    return await trigger(stepValidations[stepNumber]);
  };

  const nextStep = async (event) => {
    event.preventDefault();
    const isValid = await validateStep(step);
    if (isValid && step < steps.length - 1) {
      setStep(step + 1);
    } else if (isValid && step === steps.length - 1) {
      handleSubmit(onSubmit)();
    }
  };

  const prevStep = () => {
    if (step > 0) setStep(step - 1);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Individual Profile Setup | GoatArch Design and Products</title>
      </Helmet>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Individual Profile Setup</h1>
        <div className="bg-white rounded shadow-md p-6">
          <div className="mb-6">
            <ul className="flex border-b">
              {steps.map((stepObj, index) => (
                <li key={index} className="-mb-px mr-1">
                  <span
                    className={`inline-block py-2 px-4 text-sm font-semibold ${
                      index === step
                        ? "text-theme-color border-b-2 border-theme-color"
                        : "text-gray-500 hover:text-gray-800"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (index < step) setStep(index);
                    }}
                  >
                    {stepObj.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={step}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageVariants}
              >
                {React.createElement(steps[step].component, {
                  register,
                  errors,
                  watch,
                  setValue,
                  control,
                })}
              </motion.div>
            </AnimatePresence>
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={prevStep}
                disabled={step === 0}
                className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
              >
                {step === steps.length - 1 ? "Submit" : "Next"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {isSubmitted && (
        <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
          <div className="flex flex-col items-center justify-center min-h-[300px] space-y-6">
            <SiteLogo className="w-32 md:w-40 lg:w-48" />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center">
              Success!
            </h2>
            <p className="text-gray-600 text-lg md:text-xl lg:text-2xl text-center">
              Your individual profile has been successfully updated.
            </p>
            <Loader />
            <p className="text-sm md:text-base lg:text-lg text-gray-500 text-center">
              Redirecting to user profile page...
            </p>
          </div>
        </Popup>
      )}
    </DashboardLayout>
  );
};
