import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Circle } from "lucide-react";

const ProfileScoreCard = ({ companyDetails, isOwnProfile, isManufacturer }) => {
  const calculateScore = useMemo(() => {
    let score = 0;
    const improvements = [];

    // Check about description length
    const aboutLength = companyDetails?.company_description?.length || 0;
    if (aboutLength >= 96) {
      score += 2;
    } else {
      improvements.push({
        text: "Increase about description",
        link: "/dashboard",
        description:
          "Add more details about your company (min. 1200 characters)",
      });
    }

    // Check brands and expertise
    if (companyDetails?.brands_we_deal?.length > 0) {
      score += 2;
    } else {
      improvements.push({
        text: "Add brands",
        link: "/dashboard/",
        description: "Add brands you deal with",
      });
    }

    if (companyDetails?.company_expertise?.length > 0) {
      score += 2;
    } else {
      improvements.push({
        text: "Add expertise",
        link: "/dashboard/",
        description: "Showcase your company's expertise",
      });
    }

    // Check images
    if (companyDetails?.gallery_images?.length >= 3) {
      score += 1;
    } else {
      improvements.push({
        text: "Add more images",
        link: "/dashboard/",
        description: "Add at least 3 images to your gallery",
      });
    }

    // Check team members
    if (companyDetails?.number_of_employees?.length > 0) {
      score += 1;
    } else {
      improvements.push({
        text: "Add team members",
        link: "/dashboard/",
        description: "Add your team members' information",
      });
    }

    // Check products/projects based on company type
    if (isManufacturer) {
      if (companyDetails?.products?.length > 0) {
        score += 2;
      } else {
        improvements.push({
          text: "Add products",
          link: "/dashboard/create-products/",
          description: "Showcase your products",
        });
      }
    } else {
      if (companyDetails?.projects?.length > 0) {
        score += 2;
      } else {
        improvements.push({
          text: "Add projects",
          link: "/dashboard/create-projects/",
          description: "Showcase your projects",
        });
      }
    }

    return { score, improvements };
  }, [companyDetails, isManufacturer]);

  if (!isOwnProfile) return null;

  return (
    <div className="mt-6 rounded-lg shadow-lg border border-gray-200 bg-white overflow-hidden">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Profile Score</h3>
          <div className="flex items-center">
            <div className="relative w-12 h-12">
              <svg className="w-12 h-12 transform -rotate-90">
                <circle
                  cx="24"
                  cy="24"
                  r="20"
                  stroke="#e5e7eb"
                  strokeWidth="4"
                  fill="none"
                />
                <circle
                  cx="24"
                  cy="24"
                  r="20"
                  stroke={
                    calculateScore.score >= 7
                      ? "#22c55e"
                      : calculateScore.score >= 4
                      ? "#f59e0b"
                      : "#ef4444"
                  }
                  strokeWidth="4"
                  fill="none"
                  strokeDasharray={`${
                    (calculateScore.score / 10) * 125.6
                  } 125.6`}
                  className="transition-all duration-1000"
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-lg font-bold">
                  {calculateScore.score}
                </span>
              </div>
            </div>
            <span className="ml-2 text-sm text-gray-500">out of 10</span>
          </div>
        </div>

        {calculateScore.improvements.length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-3">
              Suggested Improvements
            </h4>
            <div className="grid grid-cols-3 gap-6">
              {calculateScore.improvements.map((improvement, index) => (
                <Link
                  key={index}
                  to={improvement.link}
                  className="block p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center text-theme-color">
                    <Circle className="w-4 h-4 mr-2" />
                    <span className="font-medium">{improvement.text}</span>
                  </div>
                  <p className="mt-1 text-sm text-gray-500 ml-6">
                    {improvement.description}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        )}

        {calculateScore.score === 10 && (
          <div className="mt-4 p-4 bg-green-50 rounded-lg">
            <p className="text-green-700 text-sm font-medium">
              Congratulations! Your profile is complete. 🎉
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileScoreCard;
