import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../common/Dashboard Sidebar";
import DashboardHeader from "../../common/Dashboard Header";

export const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-screen flex flex-col">
      <div className="block lg:hidden">
        <DashboardHeader toggleSidebar={toggleSidebar} />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <DashboardSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <main className="flex-1 overflow-y-auto">{children}</main>
      </div>
    </div>
  );
};
