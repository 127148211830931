// import React, { useState, useCallback, useEffect } from "react";
// import { useDropzone } from "react-dropzone";
// import { XCircle, Upload, CheckCircle, AlertCircle } from "lucide-react";

// export const FileUpload = ({
//   onFileChange,
//   label,
//   multiple = false,
//   maxFiles = Infinity,
//   maxSizeInMB = 5,
// }) => {
//   const [files, setFiles] = useState([]);
//   const [previews, setPreviews] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState({});
//   const [error, setError] = useState(null);

//   const onDrop = useCallback(
//     (acceptedFiles, rejectedFiles) => {
//       if (rejectedFiles.length > 0) {
//         setError(
//           `Some files were rejected. Please ensure they are images under ${maxSizeInMB}MB.`
//         );
//         return;
//       }

//       const newFiles = multiple
//         ? [...files, ...acceptedFiles]
//         : [acceptedFiles[0]];

//       setError(null);
//       setFiles(newFiles);
//       onFileChange(multiple ? newFiles : newFiles[0]);

//       const newPreviews = newFiles.map((file) => ({
//         id: Math.random().toString(36).substr(2, 9),
//         url: URL.createObjectURL(file),
//       }));
//       setPreviews(newPreviews);
//       newFiles.forEach((file) => {
//         const fileId = file.name + file.size;
//         setUploadProgress((prev) => ({ ...prev, [fileId]: 0 }));
//         const interval = setInterval(() => {
//           setUploadProgress((prev) => {
//             const newProgress = (prev[fileId] || 0) + 10;
//             if (newProgress >= 100) {
//               clearInterval(interval);
//             }
//             return { ...prev, [fileId]: Math.min(newProgress, 100) };
//           });
//         }, 200);
//       });
//     },
//     [files, multiple, onFileChange, maxSizeInMB]
//   );

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     accept: "image/*",
//     multiple: multiple,
//     maxSize: maxSizeInMB * 1024 * 1024,
//   });

//   const removeFile = useCallback(
//     (index) => {
//       const newFiles = [...files];
//       newFiles.splice(index, 1);
//       setFiles(newFiles);
//       onFileChange(multiple ? newFiles : newFiles[0] || null);

//       const newPreviews = [...previews];
//       URL.revokeObjectURL(newPreviews[index].url);
//       newPreviews.splice(index, 1);
//       setPreviews(newPreviews);
//       const removedFile = files[index];
//       const fileId = removedFile.name + removedFile.size;
//       setUploadProgress((prev) => {
//         const { [fileId]: removed, ...rest } = prev;
//         return rest;
//       });
//     },
//     [files, previews, multiple, onFileChange]
//   );

//   useEffect(() => {
//     return () =>
//       previews.forEach((preview) => URL.revokeObjectURL(preview.url));
//   }, [previews]);

//   const renderPreviews = () => {
//     if (!multiple && previews.length === 1) {
//       const preview = previews[0];
//       const file = files[0];
//       const fileId = file.name + file.size;
//       const progress = uploadProgress[fileId] || 0;

//       return (
//         <div className="relative w-full h-64">
//           <img
//             src={preview.url}
//             alt="Preview"
//             className="w-full h-full object-cover rounded"
//           />
//           <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
//             <button
//               onClick={(e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 removeFile(0);
//               }}
//               className="text-white hover:text-red-500 transition-colors"
//             >
//               <XCircle size={48} />
//             </button>
//           </div>
//           {progress < 100 && (
//             <div className="absolute bottom-0 left-0 right-0 bg-gray-200 h-2">
//               <div
//                 className="bg-theme-color h-full transition-all duration-300 ease-out"
//                 style={{ width: `${progress}%` }}
//               ></div>
//             </div>
//           )}
//           {progress === 100 && (
//             <div className="absolute top-2 right-2 text-green-500">
//               <CheckCircle size={24} />
//             </div>
//           )}
//         </div>
//       );
//     }

//     return (
//       <div className="grid grid-cols-3 gap-4">
//         {previews.map((preview, index) => {
//           const file = files[index];
//           const fileId = file.name + file.size;
//           const progress = uploadProgress[fileId] || 0;

//           return (
//             <div key={preview.id} className="relative group">
//               <img
//                 src={preview.url}
//                 alt={`Preview ${index + 1}`}
//                 className="w-full h-32 object-cover rounded transition-opacity group-hover:opacity-75"
//               />
//               <button
//                 onClick={(e) => {
//                   e.preventDefault();
//                   e.stopPropagation();
//                   removeFile(index);
//                 }}
//                 className="absolute top-2 right-2 text-red-500 hover:text-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
//               >
//                 <XCircle size={24} />
//               </button>
//               {progress < 100 && (
//                 <div className="absolute bottom-0 left-0 right-0 bg-gray-200 h-1">
//                   <div
//                     className="bg-theme-color h-full transition-all duration-300 ease-out"
//                     style={{ width: `${progress}%` }}
//                   ></div>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>
//     );
//   };

//   return (
//     <div className="mb-4">
//       <div
//         {...getRootProps()}
//         className={`border-2 border-dashed rounded p-4 text-center cursor-pointer transition-all ${
//           isDragActive
//             ? "border-theme-color bg-blue-50 scale-105"
//             : "border-gray-300 hover:border-theme-color"
//         }`}
//       >
//         <input {...getInputProps()} />
//         {files.length > 0 ? (
//           renderPreviews()
//         ) : (
//           <div className="flex flex-col items-center justify-center py-10">
//             <Upload
//               size={64}
//               className={`${
//                 isDragActive ? "text-theme-color animate-bounce" : "text-black"
//               } mb-4`}
//             />
//             <p className="text-lg font-semibold text-theme-color">
//               {isDragActive
//                 ? "Drop it like it's hot!"
//                 : "Upload your file here"}
//             </p>
//             <p className="text-sm text-gray-500 mt-2">
//               or click to select from your device
//             </p>
//           </div>
//         )}
//       </div>
//       {error && (
//         <p className="mt-2 text-sm text-red-600 flex items-center">
//           <AlertCircle size={16} className="mr-1" />
//           {error}
//         </p>
//       )}
//       {multiple && files.length > 0 && (
//         <p className="text-sm text-gray-500 mt-2">
//           {files.length} file{files.length !== 1 ? "s" : ""} uploaded
//         </p>
//       )}
//     </div>
//   );
// };

import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  XCircle,
  Upload,
  CheckCircle,
  AlertCircle,
  FileText,
  Image,
  File,
} from "lucide-react";

const getFileIcon = (fileType) => {
  if (fileType.startsWith("image/")) return Image;
  if (fileType.includes("pdf")) return FileText;
  return File;
};

const getFileTypeConfig = (acceptedTypes) => {
  const typeConfigs = {
    images: {
      accept: {
        "image/*": [".png", ".jpg", ".jpeg", ".gif"],
      },
      message: "Upload images (PNG, JPG, JPEG)",
    },
    documents: {
      accept: {
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      message: "Upload documents (PDF, DOC, DOCX, XLS, XLSX)",
    },
    all: {
      accept: {
        "image/*": [".png", ".jpg", ".jpeg", ".gif"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      message: "Upload files (Images, PDF, DOC, DOCX, XLS, XLSX)",
    },
  };

  return typeConfigs[acceptedTypes] || typeConfigs.all;
};

export const FileUpload = ({
  onFileChange,
  label,
  multiple = false,
  maxFiles = Infinity,
  maxSizeInMB = 5,
  acceptedTypes = "all", // 'images', 'documents', or 'all'
}) => {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [error, setError] = useState(null);

  const fileTypeConfig = getFileTypeConfig(acceptedTypes);

  const generatePreview = async (file) => {
    if (file.type.startsWith("image/")) {
      return {
        id: Math.random().toString(36).substr(2, 9),
        url: URL.createObjectURL(file),
        type: "image",
        name: file.name,
        size: file.size,
        mimeType: file.type,
      };
    }

    // For documents, generate a preview object with file information
    return {
      id: Math.random().toString(36).substr(2, 9),
      type: "document",
      name: file.name,
      size: file.size,
      mimeType: file.type,
      icon: getFileIcon(file.type),
    };
  };

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        const errors = rejectedFiles.map((rejection) => {
          const error = rejection.errors[0];
          if (error.code === "file-invalid-type") {
            return `${rejection.file.name} has invalid file type. Accepted types: ${fileTypeConfig.message}`;
          }
          if (error.code === "file-too-large") {
            return `${rejection.file.name} is too large. Max size: ${maxSizeInMB}MB`;
          }
          return `${rejection.file.name}: ${error.message}`;
        });
        setError(errors.join(". "));
        return;
      }

      if (acceptedFiles.length + files.length > maxFiles) {
        setError(`Maximum ${maxFiles} files allowed`);
        return;
      }

      const newFiles = multiple
        ? [...files, ...acceptedFiles]
        : [acceptedFiles[0]];
      setError(null);
      setFiles(newFiles);
      onFileChange(multiple ? newFiles : newFiles[0]);

      const newPreviews = await Promise.all(
        newFiles.map((file) => generatePreview(file))
      );
      setPreviews(newPreviews);

      newFiles.forEach((file) => {
        const fileId = file.name + file.size;
        setUploadProgress((prev) => ({ ...prev, [fileId]: 0 }));
        const interval = setInterval(() => {
          setUploadProgress((prev) => {
            const newProgress = (prev[fileId] || 0) + 10;
            if (newProgress >= 100) {
              clearInterval(interval);
            }
            return { ...prev, [fileId]: Math.min(newProgress, 100) };
          });
        }, 200);
      });
    },
    [files, multiple, onFileChange, maxSizeInMB, maxFiles, fileTypeConfig]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: fileTypeConfig.accept,
      multiple: multiple,
      maxSize: maxSizeInMB * 1024 * 1024,
      maxFiles: maxFiles,
    });

  const removeFile = useCallback(
    (index) => {
      const newFiles = [...files];
      const removedFile = files[index];
      newFiles.splice(index, 1);
      setFiles(newFiles);
      onFileChange(multiple ? newFiles : newFiles[0] || null);

      const newPreviews = [...previews];
      if (newPreviews[index].type === "image") {
        URL.revokeObjectURL(newPreviews[index].url);
      }
      newPreviews.splice(index, 1);
      setPreviews(newPreviews);

      const fileId = removedFile.name + removedFile.size;
      setUploadProgress((prev) => {
        const { [fileId]: removed, ...rest } = prev;
        return rest;
      });
    },
    [files, previews, multiple, onFileChange]
  );

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const renderPreview = (preview, index) => {
    const file = files[index];
    const fileId = file.name + file.size;
    const progress = uploadProgress[fileId] || 0;

    if (preview.type === "image") {
      return (
        <div key={preview.id} className="relative group">
          <img
            src={preview.url}
            alt={preview.name}
            className="w-full h-32 object-cover rounded transition-opacity group-hover:opacity-75"
          />
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black bg-opacity-50 transition-opacity rounded">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                removeFile(index);
              }}
              className="text-white hover:text-red-500"
            >
              <XCircle size={24} />
            </button>
          </div>
          {renderProgress(progress)}
        </div>
      );
    }

    return (
      <div
        key={preview.id}
        className="relative group bg-gray-50 p-4 rounded border"
      >
        <div className="flex items-center space-x-3">
          <preview.icon size={24} className="text-gray-500" />
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {preview.name}
            </p>
            <p className="text-sm text-gray-500">
              {formatFileSize(preview.size)}
            </p>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFile(index);
            }}
            className="text-gray-400 hover:text-red-500"
          >
            <XCircle size={20} />
          </button>
        </div>
        {renderProgress(progress)}
      </div>
    );
  };

  const renderProgress = (progress) => {
    if (progress < 100) {
      return (
        <div className="absolute bottom-0 left-0 right-0 bg-gray-200 h-1">
          <div
            className="bg-theme-color h-full transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mb-4">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-4 text-center cursor-pointer transition-all ${
          isDragReject
            ? "border-red-500 bg-red-50"
            : isDragActive
            ? "border-theme-color bg-blue-50"
            : "border-gray-300 hover:border-theme-color"
        }`}
      >
        <input {...getInputProps()} />
        {files.length > 0 ? (
          <div className="space-y-4">
            {previews.map((preview, index) => renderPreview(preview, index))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-10">
            <Upload
              size={48}
              className={`${
                isDragReject
                  ? "text-red-500"
                  : isDragActive
                  ? "text-blue-500 animate-bounce"
                  : "text-gray-400"
              } mb-4`}
            />
            <p className="text-lg font-semibold">
              {isDragReject
                ? "Invalid file type"
                : isDragActive
                ? `Drop it like it's hot!`
                : "Drop files here or click to upload"}
            </p>
            <p className="text-sm text-gray-500 mt-2">
              {fileTypeConfig.message}
            </p>
            <p className="text-sm text-gray-500 mt-1">
              Maximum file size: {maxSizeInMB}MB
            </p>
          </div>
        )}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-600 flex items-center">
          <AlertCircle size={16} className="mr-1 flex-shrink-0" />
          <span>{error}</span>
        </div>
      )}
      {multiple && files.length > 0 && (
        <p className="text-sm text-gray-500 mt-2">
          {files.length} file{files.length !== 1 ? "s" : ""} uploaded
        </p>
      )}
    </div>
  );
};
