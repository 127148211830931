import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  BuildingOfficeIcon,
  LightBulbIcon,
  ShoppingBagIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";

export const GetStartedDropdown = ({
  isScrolled,
  isWhiteHeader = false,
  className = "",
  buttonClassName = "",
  dropdownClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  const menuItems = [
    {
      icon: BuildingOfficeIcon,
      title: "Register Your Company",
      description: "Create your professional profile",
      link: "/register/",
      color: "bg-black text-theme-color",
    },
    {
      icon: LightBulbIcon,
      title: "Submit Project",
      description: "Showcase your latest work",
      link: "/dashboard/create-projects",
      color: "bg-black text-theme-color",
    },
    {
      icon: ShoppingBagIcon,
      title: "Showcase Product",
      description: "Highlight your product offerings",
      link: "/dashboard/create-products",
      color: "bg-black text-theme-color",
    },
    {
      icon: CalendarDaysIcon,
      title: "Add Your Events",
      description: "Share upcoming events",
      link: "/events/",
      color: "bg-black text-theme-color",
    },
  ];

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`
          flex items-center space-x-2 font-semibold text-sm rounded 
          transition-all duration-300 px-4 py-2.5 group
          ${
            isWhiteHeader
              ? "bg-black text-white hover:bg-black/30 backdrop-blur-sm"
              : isScrolled
              ? "bg-neutral-900 text-white hover:bg-neutral-700"
              : "bg-white/20 text-white hover:bg-white/30 backdrop-blur-sm"
          }
          ${buttonClassName}
        `}
      >
        <span>Get Started</span>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="w-4 h-4 group-hover:text-theme-color"
        >
          <path d="m6 9 6 6 6-6" />
        </motion.svg>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className={`
              absolute right-0 mt-3 w-96 bg-white 
              rounded-2xl shadow-2xl overflow-hidden
              border border-neutral-100 z-50
              ${dropdownClassName}
            `}
          >
            <div className="p-4 bg-neutral-50 border-b border-neutral-100">
              <h3 className="text-lg font-bold text-neutral-800">
                Get Started with GoatArch
              </h3>
              <p className="text-xs text-neutral-500 mt-1">
                Choose your next step
              </p>
            </div>

            <div className="divide-y divide-neutral-100">
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  onClick={() => setIsOpen(false)}
                  className="block group"
                >
                  <div className="flex items-center px-4 py-3 hover:bg-neutral-50 transition-colors duration-200">
                    <div
                      className={`
                      p-3 rounded-xl mr-4 
                      group-hover:scale-105 
                      transition-all duration-300
                      ${item.color}
                    `}
                    >
                      <item.icon className="w-6 h-6" />
                    </div>
                    <div className="flex-1">
                      <h4 className="font-semibold text-neutral-800 group-hover:text-theme-color transition-colors">
                        {item.title}
                      </h4>
                      <p className="text-xs text-neutral-500">
                        {item.description}
                      </p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-neutral-400 opacity-0 group-hover:opacity-100 transition-all duration-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
