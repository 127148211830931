// import React from "react";
// import { Input } from "../../../../components/shared";

// export const CompanyStepEight = ({ control }) => {
//   const socialMediaPlatforms = [
//     {
//       name: "instagramUrl",
//       label: "Instagram URL",
//       placeholder: "Enter Instagram Profile URL",
//     },
//     {
//       name: "linkedinUrl",
//       label: "LinkedIn URL",
//       placeholder: "Enter LinkedIn Profile URL",
//     },
//     {
//       name: "facebookUrl",
//       label: "Facebook URL",
//       placeholder: "Enter Facebook Profile URL",
//     },
//     {
//       name: "youtubeUrl",
//       label: "YouTube URL",
//       placeholder: "Enter YouTube Profile URL",
//     },
//   ];

//   return (
//     <div className="space-y-6">
//       {socialMediaPlatforms.map((platform) => (
//         <Input
//           key={platform.name}
//           name={`socialMedia.${platform.name}`}
//           control={control}
//           label={platform.label}
//           placeholder={platform.placeholder}
//         />
//       ))}

//       <div className="flex items-center">
//         <Input
//           name="termsAccepted"
//           control={control}
//           type="checkbox"
//           inlineLabel='I accept the <a href="/terms-and-conditions" class="text-theme-color hover:underline">Terms and Conditions</a>'
//           rules={{ required: "You must accept the terms and conditions" }}
//         />
//       </div>

//       {formSubmitted && !termsAccepted && (
//         <p className="text-red-500 text-sm">
//           You must agree to the terms and conditions.
//         </p>
//       )}
//     </div>
//   );
// };

import React from "react";
import { Input } from "../../../../components/shared";

const isValidURL = (url) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(url);
};

export const CompanyStepEight = ({ control }) => {
  const socialMediaPlatforms = [
    {
      name: "instagramUrl",
      label: "Instagram URL (Optional)",
      placeholder: "Enter Instagram Profile URL",
    },
    {
      name: "linkedinUrl",
      label: "LinkedIn URL (Optional)",
      placeholder: "Enter LinkedIn Profile URL",
    },
    {
      name: "facebookUrl",
      label: "Facebook URL (Optional)",
      placeholder: "Enter Facebook Profile URL",
    },
    {
      name: "youtubeUrl",
      label: "YouTube URL (Optional)",
      placeholder: "Enter YouTube Profile URL",
    },
  ];

  return (
    <div className="space-y-6">
      {socialMediaPlatforms.map((platform) => (
        <Input
          key={platform.name}
          name={`socialMedia.${platform.name}`}
          control={control}
          label={platform.label}
          placeholder={platform.placeholder}
          rules={{
            validate: (value) => {
              if (value && !isValidURL(value)) {
                return "Please enter a valid URL";
              }
              return true;
            },
          }}
        />
      ))}

      <div className="flex items-center">
        <Input
          name="termsAccepted"
          control={control}
          type="checkbox"
          inlineLabel='I accept the <a href="/terms-and-conditions" class="text-theme-color hover:underline">Terms and Conditions</a>'
          rules={{ required: "You must accept the terms and conditions" }}
        />
      </div>
    </div>
  );
};
