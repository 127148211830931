export const AnimatedSpinner = ({ size }) => {
    return (
      <div
        className="buttonloader"
        style={{
          width: size.width ? `${size.width}px` : "24px",
          height: size.height ? `${size.height}px` : "24px",
        }}
      />
    );
  };