import React from "react";
import { Layout } from "../../../components/layout/layout";
import { BlogBanner } from "../../../components/shared";
import AcousticBannerImage from "../../../assets/images/Blogs/accostic.jpg";
import { Link } from "react-router-dom";

export const AcousticComfortBlog = () => {
  const blogPost = {
    title: "How Acoustic Comfort Enhances Your Building Design?",
    category: "Design Insights",
    author: "Emily Rodriguez",
    readTime: "10 min read",
    publishDate: "Dec 6, 2024",
    bannerImage: AcousticBannerImage,
    breadcrumbs: [
      { label: "Blog", url: "/blog" },
      { label: "Design Insights", url: "/blog/design-insights" },
    ],
  };

  return (
    <Layout headerType="2">
      <section className="mt-20">
        <BlogBanner {...blogPost} />
      </section>

      <article className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction Section */}
        <section className="mb-12">
          <p className="text-lg text-gray-700 leading-relaxed">
            In the world of architectural design, acoustic comfort is often an
            overlooked element that can dramatically transform the user
            experience of a space. Beyond visual aesthetics and functional
            layout, sound plays a crucial role in how we perceive and interact
            with built environments.
          </p>
        </section>

        {/* Understanding Acoustic Comfort */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Understanding Acoustic Comfort
          </h2>
          <div className="bg-theme-light-bg p-6 rounded-lg">
            <p className="text-gray-700 mb-4">
              Acoustic comfort refers to the quality of sound within a space
              that ensures optimal listening conditions, minimizes unwanted
              noise, and creates a pleasant auditory environment. It's about
              managing sound reflection, absorption, and transmission to enhance
              occupant well-being and productivity.
            </p>
          </div>
        </section>

        {/* Key Principles of Acoustic Design */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Key Principles of Acoustic Design
          </h2>
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-10 h-10 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                1
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">Sound Absorption</h3>
                <p className="text-gray-700">
                  Use materials like acoustic panels, fabric wall coverings, and
                  soft furnishings to reduce sound reflection and minimize echo.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-10 h-10 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                2
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">Sound Insulation</h3>
                <p className="text-gray-700">
                  Implement robust wall and ceiling constructions that prevent
                  sound transmission between different spaces and from external
                  noise sources.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-10 h-10 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                3
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">Sound Diffusion</h3>
                <p className="text-gray-700">
                  Create irregular surfaces and strategic placement of acoustic
                  elements to distribute sound waves evenly and prevent sound
                  concentration.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Application in Different Spaces */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Acoustic Comfort in Different Spaces
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-theme-light-bg p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">Offices</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-700">
                <li>Open-plan noise reduction</li>
                <li>Private meeting room acoustics</li>
                <li>Sound masking techniques</li>
              </ul>
            </div>
            <div className="bg-theme-light-bg p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">Educational Spaces</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-700">
                <li>Classroom sound clarity</li>
                <li>Lecture hall reverb control</li>
                <li>Reducing external noise disruption</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Technology and Innovation */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Technology and Innovation in Acoustic Design
          </h2>
          <div className="bg-theme-light-bg p-6 rounded-lg">
            <p className="text-gray-700 mb-4">
              Modern acoustic design leverages advanced technologies like:
            </p>
            <ul className="space-y-3 text-gray-700">
              <li className="flex items-start gap-2">
                <span className="text-balck font-bold">•</span>
                <span>Digital sound modeling and simulation</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-balck font-bold">•</span>
                <span>
                  Smart acoustic panels with real-time sound adjustment
                </span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-balck font-bold">•</span>
                <span>Active noise cancellation systems</span>
              </li>
            </ul>
          </div>
        </section>

        {/* Conclusion */}
        <section>
          <div className="bg-theme-light-bg p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">
              Elevate Your Design with Acoustic Comfort
            </h2>
            <p className="text-gray-700 mb-6">
              Integrating acoustic comfort into your architectural design is not
              just about reducing noise—it's about creating environments that
              enhance human experience, productivity, and well-being.
            </p>
            <button className="bg-theme-color text-white px-6 py-2 rounded hover:bg-black transition-colors">
              <Link to="/companies/">Explore Acoustic Design Solutions</Link>
            </button>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default AcousticComfortBlog;
