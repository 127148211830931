import React, { useEffect, useState } from "react";
import CompanyCard from "./CompanyCard";
import { useLocation } from "react-router-dom";

const CompanySection = ({ category, companies }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, [location]);

  const [showAll, setShowAll] = useState(false);
  const displayedCompanies = showAll ? companies : companies.slice(0, 4);

  const categoryName = category || "Uncategorized";
  const sectionId = categoryName.split(" ")[0].toLowerCase();

  return (
    <section className="my-16" id={sectionId}>
      <div className="flex justify-between items-center mb-8">
        <h3 style={{ fontFamily: "Lato, sans-serif" }} className="font-bold">
          {categoryName}
        </h3>
        {companies.length > 4 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="text-theme-color font-bold hover:underline"
          >
            {showAll ? "Show Less" : "View All"}
          </button>
        )}
      </div>
      <p className="text-black mb-6">
        Discover top {categoryName.toLowerCase()} for your next project. Our
        curated list features industry leaders known for their expertise and
        innovation.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {displayedCompanies.map((company) => (
          <CompanyCard key={company.id} company={company} />
        ))}
      </div>
    </section>
  );
};

export default CompanySection;
