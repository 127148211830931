import React from "react";
import { Input } from "../../../../../components/shared";

export const IndividualProfileUpdateStepTwo = ({ control }) => {
  const socialMedias = [
    { name: "linkedin", label: "LinkedIn", placeholder: "LinkedIn Profile URL" },
    { name: "twitter", label: "Twitter", placeholder: "Twitter Profile URL" },
    { name: "facebook", label: "Facebook", placeholder: "Facebook Profile URL" },
    { name: "instagram", label: "Instagram", placeholder: "Instagram Profile URL" },
  ];

  return (
    <div>
      <h3 className="text-xl font-semibold text-gray-900 mb-6">
        Social Media Profiles
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {socialMedias.map((social) => (
          <Input
            key={social.name}
            name={social.name}
            control={control}
            label={social.label}
            placeholder={social.placeholder}
            type="url"
          />
        ))}
      </div>
    </div>
  );
};