import React, { useState, useEffect } from "react";
import { Input } from "../../../../../components/shared";
import { useController } from "react-hook-form";
import { GetCountries, GetState, GetCity } from "react-country-state-city";

const categories = [
  {
    name: "Residential",
    subcategories: [
      "Apartment",
      "Private House",
      "Multi Unit Housing",
      "Student Housing",
    ],
  },
  {
    name: "Commercial",
    subcategories: [
      "Office",
      "Retail",
      "Bank",
      "Showroom",
      "Supermarket",
      "Exhibition Center",
      "Shopping Mall",
      "Popup",
    ],
  },
  {
    name: "Hotel And Sports",
    subcategories: [
      "Hotel",
      "Restaurant",
      "Bar/Night Clubs",
      "Wellness Spa",
      "Movie Theatre",
      "Information Center",
      "Stadium",
      "Sports Center",
      "Zoo/Aquarium",
      "Amusement Park",
    ],
  },
  {
    name: "Cultural",
    subcategories: [
      "Gallery",
      "Hall/Theatre",
      "Museum",
      "Observation",
      "Pavilion",
      "Sculpture",
      "Religious",
      "Memorial",
    ],
  },
  {
    name: "Educational",
    subcategories: [
      "Nursery",
      "Elementary School",
      "High School",
      "University",
      "Auditorium",
      "Library",
      "Others",
    ],
  },
  {
    name: "Government And Health",
    subcategories: ["City Hall", "Community Center", "Hospital"],
  },
  {
    name: "Industrial",
    subcategories: [
      "Factory",
      "Industry",
      "Laboratory",
      "Power Plant",
      "Water Facility",
      "Farm",
      "Winery",
    ],
  },
  {
    name: "Landscape And Planning",
    subcategories: ["Public Park", "Private Garden", "Playground", "Cemetery"],
  },
  {
    name: "Transport And Infrastructure",
    subcategories: [
      "Airport",
      "Bus Stand",
      "Bridge",
      "Railway Bridge",
      "Parking",
      "Bicycle Facilities",
    ],
  },
];

export const CreateProjectStepOne = ({ control, errors }) => {
  const [state, setState] = useState({
    selectedCategory: "",
    subCategories: [],
  });

  const { field: categoryField } = useController({
    name: "category",
    control,
  });

  const { field: subCategoryField } = useController({
    name: "subCategory",
    control,
  });
  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    const country = countriesList.find((c) => c.name === countryName);
    setCountryid(country.id);

    GetState(country.id).then((result) => {
      setStateList(result);
    });
    // console.log(`Selected Country Name: ${country.name}`);
  };

  const handleStateChange = (e) => {
    const stateName = e.target.value;
    const state = stateList.find((s) => s.name === stateName);
    setStateid(state.id);

    GetCity(countryid, state.id).then((result) => {
      setCityList(result);
    });
    // console.log(`Selected State Name: ${state.name}`);
  };

  const handleCityChange = (e) => {
    const cityName = e.target.value;
    const city = cityList.find((c) => c.name === cityName);
    setCityid(city.id);

    // console.log(`Selected City Name: ${city.name}`);
  };

  const handleCategoryChange = (value) => {
    const category = categories.find((cat) => cat.name === value);
    if (category) {
      updateState({
        selectedCategory: value,
        subCategories: category.subcategories,
      });
      categoryField.onChange(value);
      subCategoryField.onChange(""); // Reset subcategory when category changes
    } else {
      updateState({
        selectedCategory: "",
        subCategories: [],
      });
      categoryField.onChange("");
      subCategoryField.onChange("");
    }
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols lg:grid-cols-4 gap-4">
        <Input
          name="projectName"
          control={control}
          rules={{ required: "Project name is required" }}
          label="Project Name"
          placeholder="Enter project name"
        />
        <Input
          name="category"
          rules={{ required: "Category is required" }}
          control={control}
          label="Category"
          type="select"
          options={[
            { value: "", label: "Select Category" },
            ...categories.map((category) => ({
              value: category.name,
              label: category.name,
            })),
          ]}
          onChange={(e) => handleCategoryChange(e.target.value)}
        />
        <Input
          name="subCategory"
          rules={{ required: "Sub Category is required" }}
          control={control}
          label="Sub Category"
          type="select"
          options={[
            { value: "", label: "Select Sub Category" },
            ...state.subCategories.map((subCategory) => ({
              value: subCategory,
              label: subCategory,
            })),
          ]}
          disabled={!state.selectedCategory}
        />
        <Input
          name="yearOfCompletion"
          control={control}
          rules={{
            required: "Year of completion is required",
            validate: {
              maxYear: (value) =>
                value <= currentYear ||
                `Year cannot be greater than ${currentYear}`,
            },
          }}
          label="Year of Completion"
          type="number"
          placeholder="Enter year of completion"
        />
        <Input
          name="country"
          control={control}
          label="Country"
          type="select"
          options={countriesList.map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          placeholder="Select a country"
          allowSearch={true}
          onChange={handleCountryChange}
          rules={{ required: "Country is required" }}
        />
        <Input
          name="state"
          control={control}
          label="State"
          type="select"
          options={stateList.map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          placeholder="Select a state"
          allowSearch={true}
          onChange={handleStateChange}
          rules={{ required: "State is required" }}
        />
        <Input
          name="city"
          control={control}
          label="City"
          type="select"
          options={cityList.map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          placeholder="Select a city"
          allowSearch={true}
          onChange={handleCityChange}
          rules={{ required: "City is required" }}
        />
        <Input
          name="totalArea"
          rules={{ required: "Total Area required" }}
          control={control}
          label="Total Area"
          type="number"
          placeholder="Enter Area Sqm"
        />
      </div>
      <Input
        name="project_concept"
        control={control}
        label="Project Concept"
        type="textarea"
        placeholder="Enter your project concept etc."
        rows={4}
        className="w-full"
      />
      <Input
        name="project_challenge"
        control={control}
        label="Project Challenges"
        type="textarea"
        placeholder="Enter your project challenges you faced"
        rows={4}
        className="w-full"
      />
      <Input
        name="description"
        control={control}
        rules={{ required: "Description is required" }}
        label="Project Description"
        type="textarea"
        placeholder="Enter your project description"
        rows={4}
        className="w-full"
      />
    </div>
  );
};
