// // SEO.jsx
// import React from "react";
// import { Helmet } from "react-helmet";

// const SEO = ({
//   title,
//   description,
//   canonicalUrl,
//   structuredData,
//   noindex = false,
//   keywords = []
// }) => {
//   const defaultTitle = "GoatArch Design and Products";
//   const fullTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;

//   return (
//     <Helmet>
//       <title>{fullTitle}</title>
//       <meta name="description" content={description} />
//       <link rel="canonical" href={canonicalUrl} />
//       {keywords.length > 0 && (
//         <meta name="keywords" content={keywords.join(", ")} />
//       )}
//       {noindex ? (
//         <meta name="robots" content="noindex, follow" />
//       ) : (
//         <meta name="robots" content="index, follow" />
//       )}
//       {structuredData && (
//         <script type="application/ld+json">
//           {JSON.stringify(structuredData)}
//         </script>
//       )}
//     </Helmet>
//   );
// };

// export default SEO;
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title,
  description,
  canonicalUrl,
  structuredData,
  noindex = false,
  keywords = [],
  image,
}) => {
  const defaultTitle = "GoatArch Design and Products";
  const fullTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const siteUrl = "https://goatarch.com"; // Your website domain

  // Handle both imported images and URL strings
  let fullImageUrl;
  if (typeof image === "string") {
    fullImageUrl = image.startsWith("http") ? image : `${siteUrl}${image}`;
  } else {
    // For imported images, we need to get their URL
    try {
      fullImageUrl = new URL(image, siteUrl).href;
    } catch {
      // Fallback to default image if there's an error
      fullImageUrl = `${siteUrl}/logo512.png`;
    }
  }

  // Ensure description is not too long for social media
  const truncatedDescription =
    description?.length > 160
      ? `${description.substring(0, 157)}...`
      : description;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={truncatedDescription} />
      <link rel="canonical" href={canonicalUrl} />
      {keywords?.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}
      {noindex ? (
        <meta name="robots" content="noindex, follow" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}
      {/* Open Graph Meta Tags */}
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={truncatedDescription} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@goatarch" />{" "}
      {/* Replace with your Twitter handle */}
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={truncatedDescription} />
      <meta name="twitter:image" content={fullImageUrl} />
      {/* Additional Meta Tags */}
      <meta
        property="article:published_time"
        content={new Date().toISOString()}
      />
      <meta property="article:author" content={siteUrl} />
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
