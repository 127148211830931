import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { countries } from "countries-list";
import { Input } from "../../components/shared";

const MultiFilter = ({
  categories,
  onApplyFilter,
  onClearFilter,
  filtersApplied,
}) => {
  const { control, handleSubmit, reset } = useForm();

  const categoryOptions = useMemo(
    () => categories.map((cat) => ({ value: cat.name, label: cat.name })),
    [categories]
  );

  const countryOptions = useMemo(
    () =>
      Object.entries(countries).map(([code, country]) => ({
        value: country.name,
        label: country.name,
      })),
    []
  );

  const onSubmit = (data) => {
    onApplyFilter(data);
  };

  const handleClearFilter = () => {
    reset();
    onClearFilter();
  };

  return (
    <div className="bg-white my-8">
      {/* <h2 className="font-bold text-theme-color">
        <span className="text-black">Find the perfect </span> company for your next project
      </h2> */}
      {/* <p className="my-2 text-black">Find the perfect company for your next project</p> */}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* <Input
            name="category"
            control={control}
            type="select"
            label="Category"
            options={categoryOptions}
            placeholder="Select a category"
            allowSearch={true}
          /> */}
          <Input
            name="country"
            control={control}
            type="select"
            label="Country"
            options={countryOptions}
            placeholder="Select a country"
            allowSearch={true}
          />
          <Input
            name="city"
            control={control}
            type="text"
            label="City"
            placeholder="Enter a city name"
          />
          <Input
            name="searchTerm"
            control={control}
            type="text"
            label="Search"
            placeholder="Search companies..."
          />
        </div>
        <div className="flex justify-between items-center">
          {filtersApplied && (
            <button
              type="button"
              onClick={handleClearFilter}
              className="text-theme-color hover:underline"
            >
              Clear Filters
            </button>
          )}
          <button
            type="submit"
            className="text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm w-fit p-2 rounded"
          >
            Apply Filters
          </button>
        </div>
      </form>
    </div>
  );
};

export default MultiFilter;
