import React from "react";
import { Input } from "../../../../../components/shared";

export const IndividualProfileUpdateStepFour = ({ control }) => {
  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-gray-900">Media</h3>
      <Input
        name="bannerImage"
        control={control}
        label="Banner Image"
        type="file"
        accept="image/*"
        multiple
      />
      <Input
        name="profileImage"
        control={control}
        label="Profile Image"
        type="file"
        accept="image/*"
      />
    </div>
  );
};