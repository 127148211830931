import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../../components/layout/Dashboard Layout/index";
import { Link } from "react-router-dom";
import { EyeIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import axios from "axios";

export const AllCompaniesTable = () => {
  const [companies, setCompanies] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/under_observation_companies`
        );
        setCompanies(response.data.signup);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-container")) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleApprove = async (companyId, email) => {
    try {
      await axios.post(
        `${baseURL}/api/update_companies_status/${companyId}`,
        { status: 1, email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const updatedCompanies = companies.map((company) =>
        company.id === companyId ? { ...company, status: "Approved" } : company
      );
      setCompanies(updatedCompanies);
      setActiveDropdown(null);
    } catch (error) {
      console.error("Error approving company:", error);
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">
            Companies Under Review
          </h1>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Id
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Company Name
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Company Email
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Role
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Company
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Primary Category
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Preview
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-left">
                {companies.map((company, index) => (
                  <tr
                    key={company.id}
                    className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {company.id}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {company.username}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {company.email}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {company.role}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {company.company_name}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {company.primary_company_type}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-500">
                      {new Date(company.created_date).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-4 text-sm">
                      <Link
                        to={`/company-profile/${company.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-black hover:text-white bg-theme-color hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color transition-colors gap-2"
                      >
                        <span>View</span>
                        <EyeIcon className="h-4 w-4" />
                      </Link>
                    </td>
                    <td className="px-4 py-4 text-sm">
                      <div className="dropdown-container relative">
                        <button
                          onClick={() => toggleDropdown(company.id)}
                          className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color transition-colors"
                        >
                          Actions
                          <ChevronDownIcon className="ml-2 h-4 w-4" />
                        </button>

                        {activeDropdown === company.id && (
                          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                            <div className="py-1" role="menu">
                              <button
                                onClick={() =>
                                  handleApprove(company.id, company.email)
                                }
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                                role="menuitem"
                              >
                                Approve
                              </button>
                              <button
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                                role="menuitem"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
