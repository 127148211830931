import React from "react";
import { Link } from "react-router-dom";
import { BuildingOffice2Icon, MapIcon } from "@heroicons/react/24/outline";

export const SearchResultCompanies = ({ companies = [] }) => {
  const displayedCompanies = companies.slice(0, 4);

  return (
    <div className="px-4 sm:px-6 lg:px-24 my-14">
      <div className="flex justify-between items-baseline">
        <h2
          className="font-bold text-theme-color text-left mb-10"
          style={{ fontFamily: "Lato, sans-serif" }}
        >
          <span className="text-black">Companies</span>
        </h2>
      </div>
      {displayedCompanies.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {displayedCompanies.map((company) => (
            <Link
              key={company.id}
              to={`/company-profile/${company.id}/`}
              className="block"
              rel="nofollow"
            >
              <div className="relative w-full h-96 rounded overflow-hidden group">
                <div className="absolute inset-0">
                  <img
                    src={company.banner_image} // Use default logo if image is undefined
                    className="w-full h-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
                    alt={`${company.company_name} banner`}
                  />
                </div>
                <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70" />

                <div className="absolute inset-0 p-6 flex flex-col justify-between">
                  <div className="flex justify-between items-start">
                    <div className="rounded-full shadow-md bg-white">
                      <img
                        src={company.company_logo_image} // Use default logo if logo is undefined
                        alt={`${company.company_name} logo`}
                        className="w-20 h-20 object-contain rounded-full"
                      />
                    </div>
                    <span className="text-white rounded-full text-sm font-bold">
                      {company.primary_company_type === "Manufacturer" ||
                      company.secondary_company_type === "Manufacturer"
                        ? company.product_count || 0
                        : company.project_count || 0}
                      {company.primary_company_type === "Manufacturer" ||
                      company.secondary_company_type === "Manufacturer"
                        ? " Product"
                        : " Project"}
                    </span>
                  </div>

                  <div className="text-white">
                    <span
                      className="text-lg font-bold"
                      style={{ fontFamily: "Lato, sans-serif" }}
                    >
                      {company.company_name}
                    </span>
                    <div className="space-y-2 text-sm mt-2">
                      <div className="flex items-center">
                        <BuildingOffice2Icon className="w-5 h-5 mr-2" />
                        <span>{company.primary_company_type}</span>
                      </div>
                      <div className="flex items-center">
                        <MapIcon className="w-5 h-5 mr-2" />
                        <span>
                          {company.state && company.country
                            ? `${company.state}, ${company.country}`
                            : company.state
                            ? company.state
                            : company.country
                            ? company.country
                            : "Location not specified"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p>No companies found matching your search criteria.</p>
      )}
    </div>
  );
};
