import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Input, AnimatedSpinner } from "../../shared";

const EnquiryForm = ({ recipientEmail, baseURL, formTitle, formId }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm();
  const { id } = useParams();

  const onSubmit = async (data) => {
    const { name, phone, email } = data;

    const requestData = {
      name: name,
      number: phone,
      recipientEmail: id,
      senderEmail: email,
    };

    console.log("requestData", requestData);

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${baseURL}/api/enquiry`, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccessMessage("Your enquiry has been submitted successfully!");
      reset();
      setTimeout(() => setSuccessMessage(""), 2000);
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      setSuccessMessage("Failed to submit enquiry. Please try again.");
      setTimeout(() => setSuccessMessage(""), 2000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6" id={formId}>
      <h3 className="text-lg font-semibold mb-4 pb-2 border-b-2 inline-block text-gray-800 border-theme-color">
        {formTitle}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Input
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            label="Name"
            placeholder="Enter your name"
            className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-theme-color focus:border-transparent"
          />
        </div>
        <div className="mb-4">
          <Input
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            label="Email"
            type="email"
            placeholder="Enter your email"
            className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-theme-color focus:border-transparent"
          />
        </div>
        <div className="mb-4">
          <Input
            name="phone"
            control={control}
            rules={{ required: "Phone number is required" }}
            label="Phone Number"
            placeholder="Enter your contact number"
            className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-theme-color focus:border-transparent"
          />
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full py-2 px-4 rounded-md transition duration-300 shadow-md disabled:cursor-not-allowed flex items-center justify-center gap-2 text-white ${
            isSubmitting
              ? "bg-black hover:bg-black/90"
              : "bg-theme-color hover:bg-opacity-90"
          }`}
        >
          {isSubmitting ? (
            <>
              Wait <AnimatedSpinner size={{ width: 24, height: 24 }} />
            </>
          ) : (
            "Enquire Now"
          )}
        </button>
      </form>
      {successMessage && (
        <p className="text-green-600 mt-2 text-center text-sm bg-green-50 p-2 rounded-md">
          {successMessage}
        </p>
      )}
    </div>
  );
};

export default EnquiryForm;
