import React from "react";

const HomeAboutSection = () => {
  return (
    <section className="text-black px-6 lg:px-24 py-16 bg-theme-light-bg">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Left Column - Content */}
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-4xl font-bold">
            Welcome to <span className="text-theme-color">GoatArch</span>
          </h1>

          <div className="bg-white bg-opacity-10 backdrop-blur-lg rounded-lg p-6">
            <p className="text-lg mb-8">
              GoatArch is your premier platform connecting professionals in
              architecture, interior design, manufacturing, and acoustics. We
              provide a dynamic space where industry experts can showcase their
              work, connect with potential clients, and collaborate with fellow
              professionals. Our platform empowers you to build your digital
              presence, highlight your expertise, and grow your business in an
              ever-evolving industry.
            </p>

            <div className="border-l-4 border-theme-color pl-4 py-2">
              <p className="text-xl italic font-semibold text-theme-color">
                "Together, let's shape the future of architecture and design."
              </p>
              {/* <p className="text-sm mt-2">- Mohammad Kikadosa, CEO of GoatArch</p> */}
            </div>
          </div>
        </div>

        {/* Right Column - Video */}
        <div className="relative h-full w-full min-h-[400px] lg:min-h-[600px]">
          <div className="absolute inset-0 rounded-lg overflow-hidden">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/TwgR-BfGDls?si=CUHjzWFmpMamAVQe"
              title="GoatArch Introduction"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAboutSection;
