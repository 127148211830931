import React from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../../components/shared";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout/index";
import axios from "axios"; // Import axios for API requests

export const AddEventsDetails = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      eventBanner: "",
      eventName: "",
      eventCategory: "",
      eventDate: "",
      eventLocation: "",
      eventCapacity: "",
      registrationUrl: "",
      eventDescription: "",
      eventTimingFrom: "",
      eventTimingTo: "",
    },
  });

  const eventCategories = [
    { value: "conference", label: "Conference" },
    { value: "workshop", label: "Workshop" },
    { value: "seminar", label: "Seminar" },
    { value: "webinar", label: "Webinar" },
    { value: "networking", label: "Networking Event" },
    { value: "trade_show", label: "Trade Show" },
    { value: "concert", label: "Concert" },
    { value: "exhibition", label: "Exhibition" },
  ];

  // Function to convert 24hr time to 12hr format
  const convertTo12HourFormat = (time24) => {
    if (!time24) return "";

    const [hours, minutes] = time24.split(":");
    const hoursNum = parseInt(hours, 10);

    let period = hoursNum >= 12 ? "PM" : "AM";
    let hours12 = hoursNum % 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Convert 0 to 12 for 12 AM/PM

    return `${hours12}:${minutes} ${period}`;
  };

  const onSubmit = async (data) => {
    // Format event data and remove timing fields
    const formattedData = {
      ...data,
      // Remove event timing fields
      eventTimingFrom: undefined,
      eventTimingTo: undefined,
    };

    // Create FormData to send to the backend
    const formData = new FormData();
    
    // Add the event data to FormData (excluding timing fields)
    formData.append("data", JSON.stringify(formattedData)); // Attach the event details as a JSON string

    // Append the event banner image to FormData
    if (data.eventBanner[0]) {
      formData.append("event_banner_image", data.eventBanner[0]);
    }

    try {
      // Send data to the backend using axios
      const response = await axios.post(`${baseURL}/api/add_event`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important header for FormData
        },
      });

      if (response.status === 201) {
        // Handle success, for example, show a success message or navigate
        console.log("Event created successfully:", response.data);
        alert("Event created successfully!");
      } else {
        // Handle error response
        console.error("Error creating event:", response.data);
        alert("Error creating event. Please try again.");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <DashboardLayout>
      <div className="bg-white p-6 shadow rounded space-y-8">
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Create New Event</h1>
          <p className="mt-2 text-sm text-gray-600">
            Fill in the details below to create a new event.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 gap-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <Input
                name="eventName"
                control={control}
                label="Event Name"
                placeholder="Enter event name"
                rules={{
                  required: "Event name is required",
                }}
              />
              <Input
                name="eventCategory"
                control={control}
                type="select"
                label="Event Category"
                options={eventCategories}
                placeholder="Select event category"
                rules={{
                  required: "Event category is required",
                }}
              />
              <Input
                name="eventDate"
                control={control}
                type="date"
                label="Event Date"
                rules={{
                  required: "Event date is required",
                }}
              />
              <Input
                name="eventLocation"
                control={control}
                label="Event Location"
                placeholder="Enter event location"
                rules={{
                  required: "Event location is required",
                }}
              />
              <Input
                name="eventCapacity"
                control={control}
                type="number"
                label="Event Capacity"
                placeholder="Maximum 5"
                rules={{
                  required: "Event capacity is required",
                  max: {
                    value: 100000,
                    message: "Maximum capacity is 5",
                  },
                  min: {
                    value: 1,
                    message: "Minimum capacity is 1",
                  },
                }}
              />
              <Input
                name="registrationUrl"
                control={control}
                label="Registration Platform URL"
                placeholder="e.g., BookMyShow, PaytmInsider URL"
                rules={{
                  required: "Registration URL is required",
                  pattern: {
                    value:
                      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                    message: "Please enter a valid URL",
                  },
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Input
              name="eventBanner"
              control={control}
              type="file"
              label="Event Banner"
              acceptedFileTypes="images"
              multiple={false}
              maxSizeInMB={5}
              rules={{
                required: "Event banner is required",
              }}
            />
            <Input
              name="eventDescription"
              control={control}
              type="textarea"
              label="Event Description"
              placeholder="Enter event description"
              rules={{
                required: "Event description is required",
                minLength: {
                  value: 150,
                  message: "Description must be at least 150 characters",
                },
              }}
            />
          </div>
          <div className="flex justify-end pt-6">
            <button
              type="submit"
              className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
            >
              Create Event
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default AddEventsDetails;
