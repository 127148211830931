import React from "react";
import { Input } from "../../../../components/shared";

export const CompanyStepSeven = ({ control }) => {
  return (
    <div className="space-y-6">
      <Input
        name="videoEmbLink"
        control={control}
        label="YouTube Video Link (Optional)"
        placeholder="Enter Only Youtube Embed Link"
      />
      <div>
        <Input
          name="galleryImages"
          control={control}
          type="file"
          label="Gallery Images"
          acceptedFileTypes="images"
          multiple={true}
          maxSizeInMB={5}
          rules={{
            required: "Please Upload Gallery or Supporting Images",
          }}
        />

        <p className="mt-2 text-sm text-left">
          <span className="font-bold">Note: </span>for uploading more images
          just click on upload box or drag you file in upload box. <br />
          Upload high-definition images for the gallery, ensuring each image is
          no larger than 2MB.
        </p>
      </div>
    </div>
  );
};
