import { Link } from "react-router-dom";
import { getLatestNewsItems } from "../../../data/articles/blogdata";

export const Articals = () => {
  const latestNewsItems = getLatestNewsItems();
  return (
    <section className="px-6 lg:px-24 py-16 bg-theme-light-bg">
      <div className="text-center mb-8 lg:mb-16">
        <h2 className="text-black mb-4">
          Insights & <span className="text-theme-color">Inspiration</span>
        </h2>
        <p className="text-black max-w-5xl mx-auto">
          GoatArch Knowledge Hub, your gateway to the latest in architecture,
          interior design, and manufacturing. From cutting-edge design trends
          and inspiring projects to innovations in manufacturing and expert
          insights, we bring you articles crafted to ignite ideas and keep you
          connected with the pulse of the industry. Whether you’re a designer,
          architect, or manufacturer, our articles are here to inform, inspire,
          and support your journey in creating spaces and solutions that shape
          the future.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-12">
        {latestNewsItems.map((item) => (
          <div key={item.id} className="flex flex-col">
            <Link to={item.url} className="mb-4 block aspect-w-16 aspect-h-9">
              <img className="w-full aspect-video rounded" src={item.photoId} />
            </Link>
            <h3 className="mb-4">
              <Link to={item.url}>{item.title}</Link>
            </h3>
            <div className="w-full bg-gray-200 h-px mb-4" />
            <div className="flex items-center justify-between mt-auto">
              <div className="flex items-center space-x-4">
                <Link
                  className="text-sm font-bold text-black hover:text-theme-color"
                  to="#"
                >
                  {item.category}
                </Link>
                <span className="text-black text-sm font-bold">
                  {item.date}
                </span>
              </div>
              <Link
                className="text-sm uppercase underline font-bold text-theme-color hover:text-black"
                to={item.url}
              >
                READ MORE
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
