// import React from 'react';
// import { XCircleIcon } from 'lucide-react';

// export const Popup = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999999] p-4">
//       <div className="bg-white rounded shadow-xl overflow-hidden">
//         <div className="relative">
//           <button 
//             onClick={onClose}
//             className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
//           >
//             <XCircleIcon className='w-4 text-black hover:text-theme-color'/>
//           </button>
//           <div className="p-6 md:p-8 lg:p-10 overflow-y-auto max-h-[80vh]">
//             {children}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

import React from 'react';
import { XCircleIcon } from 'lucide-react';

export const Popup = ({ isOpen, onClose, children, showCloseIcon = true }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999999] p-4">
      <div className="bg-white rounded shadow-xl overflow-hidden">
        <div className="relative">
          {showCloseIcon && (
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              <XCircleIcon className='w-4 text-black hover:text-theme-color'/>
            </button>
          )}
          <div className="p-6 md:p-8 lg:p-10 overflow-y-auto max-h-[80vh]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};