import React from "react";
import { Input } from "../../../../../components/shared";

export const CreateProjectStepFour = ({ control, errors }) => {
  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-gray-900">Project Media</h3>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="">
          <Input
            name="UploadBannerImages"
            control={control}
            type="file"
            label="Upload Main Project Image"
            acceptedFileTypes="images"
            multiple={false}
            maxSizeInMB={10}
            rules={{ required: "Project main image is required" }}
          />
        </div>
        <div className="">
          <Input
            name="UploadProjectImages"
            control={control}
            type="file"
            label="Upload Project Supporting Images"
            acceptedFileTypes="images"
            multiple={true}
            maxFiles={6}
            maxSizeInMB={2}
            rules={{ required: "Project supporting images is required" }}
          />
          <p className="text-left text-gray-600 text-sm">
            <strong>Note:</strong> Upload high-quality images. Prefer landscape
            orientation (1270px X 750px) for better presentation.
          </p>
        </div>
        <Input
          name="videoEmbedLinks"
          control={control}
          label="Video Embed Links"
          type="text"
          placeholder="Enter video embed link"
        />
      </div>
      <div className="flex items-center">
        <Input
          name="termsAccepted"
          control={control}
          type="checkbox"
          inlineLabel='I accept the <a href="/terms-and-conditions" class="text-theme-color hover:underline">Terms and Conditions</a>'
          rules={{ required: "You must accept the terms and conditions" }}
        />
      </div>
    </div>
  );
};
