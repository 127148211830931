import React, { useState, useRef, useEffect } from "react";
import { Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

const SearchOverlay = ({ isOpen, onClose }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchOverlayRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        searchOverlayRef.current &&
        !searchOverlayRef.current.contains(e.target)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // console.log("Search keyword:", searchKeyword);
    navigate(`/search-results?q=${encodeURIComponent(searchKeyword)}`);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-[10000] flex items-center justify-center transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        ref={searchOverlayRef}
        className={`bg-white rounded p-6 w-full max-w-2xl transform transition-all duration-300 ${
          isOpen ? "scale-100" : "scale-95"
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-theme-color">Search</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-theme-color"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSearchSubmit} className="relative mb-4">
          <input
            type="text"
            placeholder="Search Company, Product, Projects, Categories, City etc."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            className="w-full p-4 pr-12 text-xl border-2 text-black border-theme-color rounded focus:outline-none focus:ring-2 focus:ring-theme-color focus:border-transparent"
          />
          <button
            type="submit"
            className="absolute right-4 top-1/2 transform -translate-y-1/2"
          >
            <Search className="h-6 w-6 text-black hover:text-theme-color-dark" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchOverlay;