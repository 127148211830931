import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Layout } from "../../../components/layout/layout";
import { ProjectImages } from "./Images";
import { ProjectPageDetails } from "./Details";
import { SimiliarProjects } from "./Similiar Projects";
import { SEO } from "../../../components/shared";
export const ProjectDetails = () => {
  const { id } = useParams();
  // console.log("Project details Page id", id);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const [project, setProject] = useState([]);
  // console.log("project", project);

  const [projectConsultant, setProjectConultant] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (id) {
      fetchProjectById(id);
    }
  }, [id]);

  const fetchProjectById = async (id) => {
    try {
      const response = await axios.post(`${baseURL}/api/projects/${id}`);
      // console.log("response.data", response.data);
      localStorage.setItem("projectCategory", response.data.category);

      setProject(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("Project not found");
      } else {
        setError("An error occurred while fetching the project");
      }
    }
  };


  return (
    <Layout headerType="2">
      <SEO
        title={project?.project_name}
        description={project?.project_description
          ?.replace(/<[^>]*>/g, "")
          .substring(0, 160)}
        canonicalUrl={`https://goatarch.com/projects/${id}`}
        noindex={true}
        keywords={[
          project?.project_name,
          project?.project_type,
          project?.category,
          "project details",
          "goatarch",
        ]}
        image={project?.banner_image || project?.company?.company_logo_image}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "Project",
          name: project?.project_name,
          description: project?.project_description
            ?.replace(/<[^>]*>/g, "")
            .substring(0, 160),
          url: `https://goatarch.com/projects/${id}`,
          image: project?.banner_image,
          author: {
            "@type": "Organization",
            name: project?.company?.company_name,
            logo: {
              "@type": "ImageObject",
              url: project?.company?.company_logo_image,
            },
          },
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          },
        }}
      />
      <div className="px-6 lg:px-24 mt-4">
        <ProjectImages project={project} />
        <ProjectPageDetails project={project} />
        <SimiliarProjects />
      </div>
    </Layout>
  );
};
