import {
  ArrowLeftEndOnRectangleIcon,
  Cog6ToothIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import UserProfileImage from "../../assets/images/User Profile/man.jpg";
import UserBackgroundImage from "../../assets/images/Projects/commercials.jpg";
import { ProfilePortfolio } from "./ProfilePortfolio/portfolio";
import { UserPersonalDetails } from "./User Personal Details/personal-details";
import { useEffect, useState } from "react";
import { UserProfileSettings } from "./Profile Settings/settings";
import { SocialProfiles } from "./User Social Profiles/social";
import { Layout } from "../../components/layout/layout";
import { Link } from "react-router-dom";
export const UserProfile = () => {
  const [activeSection, setActiveSection] = useState("profile");
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem("userProfileData");
    if (storedData) {
      setProfileData(JSON.parse(storedData));
    }
  }, []);

  if (!profileData) {
    return null;
  }

  return (
    <Layout headerType="1">
      <div className="relative px-3 container-fluid">
        <div className="layout-specing">
          <div className="grid grid-cols-1 -mt-[6.3rem]">
            <div className="relative overflow-hidden text-transparent rounded-md shadow profile-banner">
              <input
                id="pro-banner"
                name="profile-banner"
                type="file"
                className="hidden"
              />
              <div className="relative shrink-0">
                <img
                  src={UserBackgroundImage}
                  className="object-cover w-full h-[50vh]"
                  id="profile-banner"
                  alt=""
                />
                <div className="absolute inset-0 bg-black/70"></div>
                <label
                  className="absolute inset-0 cursor-pointer"
                  htmlFor="pro-banner"
                ></label>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="mx-6 xl:col-span-3 lg:col-span-4 md:col-span-4">
              <div className="relative p-6 -mt-48 bg-white rounded-md shadow">
                <div className="mb-5 text-center profile-pic">
                  <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="hidden"
                    onChange="loadFile(event)"
                  />
                  <div className="flex flex-col items-center">
                    <div className="relative">
                      <div className="w-[200px] h-[200px] overflow-hidden rounded-full">
                        <img
                          src={UserProfileImage}
                          className="object-cover w-full h-full shadow"
                          id="profile-image"
                          alt="User Profile"
                        />
                      </div>
                      <label
                        className="absolute inset-0 cursor-pointer"
                        htmlFor="pro-img"
                      ></label>
                    </div>

                    <div className="mt-4">
                      <h5 className="text-lg font-semibold">
                        {profileData.fullName}'s Profile
                      </h5>
                      <p className="text-black">{profileData.email}</p>
                    </div>
                  </div>
                </div>

                <div className="border-t border-gray-100">
                  <ul
                    className="mt-3 mb-0 list-none sidebar-nav"
                    id="navmenu-nav"
                  >
                    <li className="navbar-item account-menu">
                      <span
                        className="flex items-center py-2 text-black rounded navbar-link"
                        onClick={() => setActiveSection("profile")}
                      >
                        <span className="me-2 text-[18px] mb-0">
                          <UserCircleIcon className="w-6 text-theme-color" />
                        </span>
                        <h6 className="mb-0 font-semibold">Profile</h6>
                      </span>
                    </li>

                    <li className="navbar-item account-menu">
                      <Link
                        to="/dashboard"
                        className="flex items-center py-2 text-black rounded navbar-link"
                        onClick={() => setActiveSection("settings")}
                      >
                        <span className="me-2 text-[18px] mb-0">
                          <Cog6ToothIcon className="w-6 text-theme-color" />
                        </span>
                        <h6 className="mb-0 font-semibold">Profile Settings</h6>
                      </Link>
                    </li>

                    <li className="navbar-item account-menu">
                      <Link
                        to="/login"
                        className="flex items-center py-2 text-black rounded navbar-link"
                      >
                        <span className="me-2 text-[18px] mb-0">
                          <ArrowLeftEndOnRectangleIcon className="w-6 text-theme-color" />
                        </span>
                        <h6 className="mb-0 font-semibold">Sign Out</h6>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-6 text-left xl:col-span-9 lg:col-span-8 md:col-span-8">
              <div className="grid grid-cols-1 gap-6">
                {activeSection === "profile" && (
                  <div className="relative p-6 bg-white rounded-md shadow">
                    <h5 className="text-xl font-semibold">
                      {profileData.fullName}
                    </h5>

                    <p className="mt-3 text-black">{profileData.description}</p>
                  </div>
                )}
                {activeSection === "profile" && <UserPersonalDetails />}
                {activeSection === "profile" && <ProfilePortfolio />}
                {activeSection === "social" && <SocialProfiles />}
                {activeSection === "settings" && <UserProfileSettings />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
