import { CompanyStepOne } from "./StepOne";
import { CompanyStepTwo } from "./StepTwo";
import { CompanyStepThree } from "./StepThree";
import { CompanyStepFour } from "./StepFour";
import { CompanyStepFive } from "./StepFive";
import { CompanyStepSix } from "./StepSix";
import { CompanyStepSeven } from "./StepSeven";
import { CompanyStepEight } from "./StepEight";
export {
  CompanyStepOne,
  CompanyStepTwo,
  CompanyStepThree,
  CompanyStepFour,
  CompanyStepFive,
  CompanyStepSix,
  CompanyStepSeven,
  CompanyStepEight
};
