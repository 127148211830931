import React from "react";
import { Input } from "../../../../../components/shared";

export const CreateProjectStepTwo = ({ control, errors }) => {
  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-gray-900">Project Details</h3>
      <div className="flex space-x-4">
        <div className="flex-1">
          <Input
            name="architecture"
            control={control}
            rules={{ required: "Architecture is required" }}
            label="Architecture"
            placeholder="Enter architecture details"
          />
        </div>
        <div className="flex-1">
          <Input
            name="locations"
            control={control}
            rules={{ required: "Locations are required" }}
            label="Locations"
            placeholder="Enter project locations"
          />
        </div>
      </div>
      <div className="flex space-x-4">
        <div className="flex-1">
          <Input
            name="projectCategory"
            control={control}
            rules={{ required: "Project category is required" }}
            label="Project Category"
            type="select"
            options={[
              { value: "residential", label: "Residential" },
              { value: "commercial", label: "Commercial" },
              { value: "industrial", label: "Industrial" },
            ]}
          />
        </div>
        <div className="flex-1">
          <Input
            name="yearOfCompletion"
            control={control}
            rules={{ required: "Year of completion is required" }}
            label="Year of Completion"
            type="number"
            placeholder="Enter year of completion"
          />
        </div>
      </div>
      <Input
        name="area"
        control={control}
        rules={{ required: "Area is required" }}
        label="Area"
        placeholder="Enter project area"
      />
    </div>
  );
};
