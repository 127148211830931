import React, { useState, useEffect } from "react";
import axios from "axios";
import { Trash, Edit } from "lucide-react";

const ImageUpdate = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editBanner, setEditBanner] = useState(false);
  const [editLogo, setEditLogo] = useState(false);
  const [newFiles, setNewFiles] = useState([]);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  useEffect(() => {
    fetchCompanyImages();
  }, []);

  const fetchCompanyImages = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/api/companies/${userData?.id}`
      );
      const company = response.data.company;
      setGalleryImages(company.gallery_images || []);
      setBannerImage(company.banner_image || null);
      setLogoImage(company.company_logo_image || null);
    } catch (error) {
      console.error("Error fetching company images:", error);
    }
  };

  const handleDeleteImage = (index) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      setGalleryImages((prevImages) =>
        prevImages.filter((_, i) => i !== index)
      );
    }
  };

  const handleDeleteAllImages = () => {
    if (window.confirm("Are you sure you want to delete all gallery images?")) {
      setGalleryImages([]);
    }
  };

  const handleDeleteBanner = () => {
    if (window.confirm("Are you sure you want to delete the banner image?")) {
      setBannerImage(null);
    }
  };

  const handleDeleteLogo = () => {
    if (window.confirm("Are you sure you want to delete the logo image?")) {
      setLogoImage(null);
    }
  };

  const handleEditImage = (index) => {
    setEditIndex(index);
  };

  const validateFileSize = (file) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    return file.size <= maxSizeInBytes;
  };

  const handleImageUpload = (event, index = null) => {
    const file = event.target.files[0];

    if (!file) return;

    if (!validateFileSize(file)) {
      alert("Please select an image under 5 MB.");
      return;
    }

    if (index !== null) {
      setGalleryImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = file;
        return newImages;
      });
      setEditIndex(null);
    } else {
      setNewFiles((prevNewFiles) => [...prevNewFiles, file]);
    }
  };

  const handleBannerUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!validateFileSize(file)) {
      alert("Please select a banner image under 5 MB.");
      return;
    }

    setBannerImage(file);
    setEditBanner(false);
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!validateFileSize(file)) {
      alert("Please select a logo image under 5 MB.");
      return;
    }

    setLogoImage(file);
    setEditLogo(false);
  };

  const onSubmit = async () => {
    try {
      const formData = new FormData();

      const combinedGalleryImages = [
        ...galleryImages,
        ...newFiles.map((file) => file),
      ];

      

      combinedGalleryImages.forEach((file) => {
        if (file instanceof File) {
          formData.append("gallery_images", file);
        } else if (typeof file === "string") {
          formData.append(
            "gallery_images_urls",
            JSON.stringify(
              combinedGalleryImages.filter((img) => typeof img === "string")
            )
          );
        } else {
          console.warn("Invalid file type, not appending:", file);
        }
      });

      if (bannerImage) {
        formData.append("banner_image", bannerImage);
      }

      if (logoImage) {
        formData.append("company_logo_image", logoImage);
      }

      const response = await axios.post(
        `${baseURL}/api/update_company_images/${userData?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.message === "Images updated successfully") {
        alert("Images updated successfully!");
        fetchCompanyImages();
      }
    } catch (error) {
      console.error("Error updating images:", error);
      alert("Failed to update images. Please try again.");
    }
  };

  return (
    <div className="p-6">
      <div className="mb-12">
        <div className="flex justify-between mb-6">
          <h1 className="text-2xl font-bold">Gallery Images</h1>
          <button
            onClick={handleDeleteAllImages}
            className="text-red-600 font-semibold flex items-center gap-2"
          >
            <Trash className="h-5 w-5" />
            Delete All Images
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
          {galleryImages.map((image, index) => (
            <div key={index} className="relative border rounded-lg p-2">
              {editIndex === index ? (
                <input
                  type="file"
                  onChange={(e) => handleImageUpload(e, index)}
                  className="w-full"
                  accept="image/*"
                />
              ) : (
                <>
                  <img
                    src={image}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-48 object-cover rounded"
                  />
                  <div className="absolute top-2 right-2 flex gap-2">
                    <button
                      onClick={() => handleEditImage(index)}
                      className="p-1 bg-white rounded-full shadow hover:bg-gray-100"
                    >
                      <Edit className="h-4 w-4 text-blue-600" />
                    </button>
                    <button
                      onClick={() => handleDeleteImage(index)}
                      className="p-1 bg-white rounded-full shadow hover:bg-gray-100"
                    >
                      <Trash className="h-4 w-4 text-red-600" />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}

          <div className="border-2 border-dashed rounded-lg p-4 flex items-center justify-center">
            <input
              type="file"
              onChange={(e) => handleImageUpload(e)}
              className="w-full"
              accept="image/*"
              multiple
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="border rounded-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Banner Image</h2>
            {bannerImage && (
              <button
                onClick={handleDeleteBanner}
                className="text-red-600 flex items-center gap-1"
              >
                <Trash className="h-4 w-4" />
                Delete
              </button>
            )}
          </div>

          {editBanner ? (
            <input
              type="file"
              onChange={(e) => handleBannerUpload(e)}
              className="w-full"
              accept="image/*"
            />
          ) : (
            <div className="relative">
              {bannerImage ? (
                <>
                  <img
                    src={bannerImage}
                    alt="Banner"
                    className="w-full h-48 object-cover rounded"
                  />
                  <button
                    onClick={() => setEditBanner(true)}
                    className="absolute top-2 right-2 p-1 bg-white rounded-full shadow hover:bg-gray-100"
                  >
                    <Edit className="h-4 w-4 text-blue-600" />
                  </button>
                </>
              ) : (
                <input
                  type="file"
                  onChange={(e) => handleBannerUpload(e)}
                  className="w-full"
                  accept="image/*"
                />
              )}
            </div>
          )}
        </div>

        <div className="border rounded-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Logo Image</h2>
            {logoImage && (
              <button
                onClick={handleDeleteLogo}
                className="text-red-600 flex items-center gap-1"
              >
                <Trash className="h-4 w-4" />
                Delete
              </button>
            )}
          </div>

          {editLogo ? (
            <input
              type="file"
              onChange={(e) => handleLogoUpload(e)}
              className="w-full"
              accept="image/*"
            />
          ) : (
            <div className="relative">
              {logoImage ? (
                <>
                  <img
                    src={logoImage}
                    alt="Logo"
                    className="w-full h-48 object-cover rounded"
                  />
                  <button
                    onClick={() => setEditLogo(true)}
                    className="absolute top-2 right-2 p-1 bg-white rounded-full shadow hover:bg-gray-100"
                  >
                    <Edit className="h-4 w-4 text-blue-600" />
                  </button>
                </>
              ) : (
                <input
                  type="file"
                  onChange={(e) => handleLogoUpload(e)}
                  className="w-full"
                  accept="image/*"
                />
              )}
            </div>
          )}
        </div>
      </div>

      <button
        onClick={onSubmit}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        Save Changes
      </button>
    </div>
  );
};

export default ImageUpdate;
