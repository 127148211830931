// import React, { useState, useEffect } from "react";
// import {
//   ChevronLeft,
//   ChevronRight,
//   Download,
//   Heart,
// } from "lucide-react";
// import { Popup, UniversalSocialShare } from "../../../components/shared";
// import { Link } from "react-router-dom";

// const ProductPopup = ({ product, isOpen, onClose }) => {

//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [activeTab, setActiveTab] = useState("description");
//   const [isLiked, setIsLiked] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const handlePrevImage = () => {
//     setCurrentImageIndex((prev) =>
//       prev === 0 ? product.product_images.length - 1 : prev - 1
//     );
//   };

//   const handleNextImage = () => {
//     setCurrentImageIndex((prev) =>
//       prev === product.product_images.length - 1 ? 0 : prev + 1
//     );
//   };

//   const handleLike = () => {
//     setIsLiked(!isLiked);
//   };

//   const getYouTubeEmbedUrl = (url) => {
//     const videoId = url.split("v=")[1];
//     return `https://www.youtube.com/embed/${videoId}`;
//   };

//   const handleDownload = async (fileUrls) => {
//     if (Array.isArray(fileUrls) && fileUrls.length > 0) {
//       let validFilesFound = false;

//       for (const fileUrl of fileUrls) {
//         if (typeof fileUrl === "string" && fileUrl) {
//           validFilesFound = true;
//           try {
//             const response = await fetch(fileUrl, {
//               mode: "cors",
//             });

//             if (!response.ok) {
//               throw new Error(
//                 `Failed to fetch ${fileUrl}: ${response.statusText}`
//               );
//             }

//             const blob = await response.blob();
//             const link = document.createElement("a");
//             const url = URL.createObjectURL(blob);
//             const fileName = fileUrl.split("/").pop();

//             link.href = url;
//             link.setAttribute("download", fileName || "download");
//             document.body.appendChild(link);
//             link.click();
//             link.remove();
//             URL.revokeObjectURL(url);
//           } catch (error) {
//             console.error("Error downloading the file:", error);
//           }
//         } else {
//           console.error("Invalid file URL:", fileUrl);
//         }
//       }

//       if (!validFilesFound) {
//         alert("Nothing to download.");
//       }
//     } else {
//       alert("Nothing to download.");
//     }
//   };

//   const availableTabs = [
//     { key: "description", label: "Description" },
//     product.sizes && { key: "sizes", label: "Sizes" },
//     product.application_of_uses && {
//       key: "applications",
//       label: "Applications",
//     },
//     { key: "downloads", label: "Downloads" },
//     // product.product_video_link &&
//     // product.product_video_link.length > 0 && { key: "video", label: "Video" },
//   ].filter(Boolean);

//   const combinedImages = product?.product_banner_image
//     ? [product.product_banner_image, ...product.product_images]
//     : product.product_images;

//   return (
//     <Popup isOpen={isOpen} onClose={onClose}>
//       <div className="w-[85vw] h-[70vh] bg-white rounded-lg overflow-hidden flex flex-col md:flex-row">
//         <div className="w-full md:w-1/2 h-full relative">
//           {combinedImages.length > 0 ? (
//             <img
//               src={combinedImages[currentImageIndex]}
//               alt={`Product Image ${currentImageIndex + 1}`}
//               className="object-cover w-full h-full"
//             />
//           ) : (
//             <div className="w-full h-full flex items-center justify-center text-gray-500">
//               No Image Available
//             </div>
//           )}
//           <button
//             onClick={handlePrevImage}
//             className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white/50 rounded-full p-1 hover:bg-white/70 transition-colors"
//           >
//             <ChevronLeft className="w-6 h-6 text-gray-800" />
//           </button>
//           <button
//             onClick={handleNextImage}
//             className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white/50 rounded-full p-1 hover:bg-white/70 transition-colors"
//           >
//             <ChevronRight className="w-6 h-6 text-gray-800" />
//           </button>
//         </div>

//         <div className="w-full md:w-1/2 flex flex-col h-full overflow-scroll">
//           <div className="flex-grow overflow-y-auto p-4 md:p-6 min-h-[420px]">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-lg md:text-xl font-bold text-black">
//                 {product.product_name}
//               </h2>
//               <div className="flex space-x-2">
//                 <button
//                   onClick={handleLike}
//                   className={`p-2 rounded-full ${
//                     isLiked
//                       ? "bg-red-500 text-white"
//                       : "bg-gray-200 text-gray-600"
//                   }`}
//                 >
//                   <Heart className="w-5 h-5" />
//                 </button>
//                 <UniversalSocialShare />
//               </div>
//             </div>
//             <p className="text-sm text-black mb-4">
//               {product.category} - {product.sub_category}
//             </p>

//             <div className="flex flex-wrap border-b mb-4 py-2">
//               {availableTabs.map((tab) => (
//                 <button
//                   key={tab.key}
//                   onClick={() => setActiveTab(tab.key)}
//                   className={`px-3 md:px-4 py-2 mr-2 mb-2 rounded-t-lg text-xs md:text-sm font-medium transition-colors ${
//                     activeTab === tab.key
//                       ? "bg-theme-color text-white"
//                       : "bg-gray-100 text-gray-600 hover:bg-gray-200"
//                   }`}
//                 >
//                   {tab.label}
//                 </button>
//               ))}
//             </div>
//             <div className="mb-4">
//               {activeTab === "description" && (
//                 <div
//                   className="mt-4"
//                   dangerouslySetInnerHTML={{
//                     __html: product?.application_of_uses,
//                   }}
//                 />
//               )}
//               {activeTab === "sizes" && product.sizes && (
//                 <div className="space-y-2">
//                   <h3 className="font-semibold">Available Sizes:</h3>
//                   <ul className="list-disc pl-5">
//                     {product.sizes
//                       .filter((sizeItem) => sizeItem.value !== "N/A")
//                       .map((sizeItem, index) => (
//                         <li key={index}>{sizeItem.value}</li>
//                       ))}
//                   </ul>
//                 </div>
//               )}

//               {activeTab === "applications" && product.application_of_uses && (
//                 <div className="space-y-2">
//                   <h3 className="font-semibold">Applications:</h3>
//                   <div
//                     className="mt-4"
//                     dangerouslySetInnerHTML={{
//                       __html: product?.application_of_uses,
//                     }}
//                   />
//                 </div>
//               )}
//               {activeTab === "downloads" && (
//                 <div className="flex flex-col md:flex-row md:justify-start gap-3 md:gap-6">
//                   {Array.isArray(product.product_documents) &&
//                     product.product_documents.length > 0 && (
//                       <button
//                         onClick={() =>
//                           handleDownload(product.product_documents)
//                         }
//                         className="flex items-center justify-center px-4 py-2 bg-theme-color text-black rounded hover:bg-black hover:text-white transition-colors"
//                       >
//                         <Download className="w-5 h-5 mr-2" /> Data Sheet
//                       </button>
//                     )}
//                   {Array.isArray(product.product_certifications) &&
//                     product.product_certifications.length > 0 && (
//                       <button
//                         onClick={() =>
//                           handleDownload(product.product_certifications)
//                         }
//                         className="flex items-center justify-center px-4 py-2 bg-theme-color text-black rounded hover:bg-black hover:text-white transition-colors"
//                       >
//                         <Download className="w-5 h-5 mr-2" /> Installation Guide
//                       </button>
//                     )}
//                 </div>
//               )}

//               {activeTab === "video" && product.product_video_link && (
//                 <div className="aspect-w-16 aspect-h-9">
//                   <iframe
//                     src={getYouTubeEmbedUrl(product.product_video_link)}
//                     title="Product Video"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                     className="w-full h-[32vh]"
//                   ></iframe>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="bg-gray-100 p-4">
//             <div className="flex flex-col md:flex-row gap-3 md:gap-5 justify-between items-center">
//               <Link
//                 to={`/product-details/${product.id}`}
//                 className="w-full text-black text-center bg-white hover:bg-black hover:text-theme-color transition-colors duration-200 text-sm md:text-base font-chivo font-bold shadow-sm py-3 md:py-[13px] rounded"
//               >
//                 Know More
//               </Link>
//               <button className="w-full text-white bg-theme-color hover:bg-black transition-colors duration-200 text-sm md:text-base font-chivo font-bold shadow-sm py-3 md:py-[13px] rounded">
//                 <Link
//                   to={`/products/products-details/${product.id}#product-enquiry-form`}
//                 >
//                   Make Inquiry
//                 </Link>
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Popup>
//   );
// };

// export default ProductPopup;

import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Download, Heart } from "lucide-react";
import { Popup, UniversalSocialShare } from "../../../components/shared";
import { Link } from "react-router-dom";

const ProductPopup = ({ product, isOpen, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("description");
  const [isLiked, setIsLiked] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? combinedImages.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === combinedImages.length - 1 ? 0 : prev + 1
    );
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
  };

  const handleDownload = async (fileUrls) => {
    // ... (download logic remains the same)
  };

  const availableTabs = [
    { key: "description", label: "Description" },
    product.sizes && { key: "sizes", label: "Sizes" },
    product.application_of_uses && {
      key: "applications",
      label: "Applications",
    },
    { key: "downloads", label: "Downloads" },
  ].filter(Boolean);

  const combinedImages = product?.product_banner_image
    ? [product.product_banner_image, ...product.product_images]
    : product.product_images;

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <div className="w-[85vw] h-[70vh] bg-white rounded-lg overflow-hidden flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 h-full relative">
          {combinedImages.length > 0 ? (
            <img
              src={combinedImages[currentImageIndex]}
              alt={`Product Image ${currentImageIndex + 1}`}
              className="object-cover w-full h-full"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center text-gray-500">
              No Image Available
            </div>
          )}
          <button
            onClick={handlePrevImage}
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white/50 rounded-full p-1 hover:bg-white/70 transition-colors"
          >
            <ChevronLeft className="w-6 h-6 text-gray-800" />
          </button>
          <button
            onClick={handleNextImage}
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white/50 rounded-full p-1 hover:bg-white/70 transition-colors"
          >
            <ChevronRight className="w-6 h-6 text-gray-800" />
          </button>
        </div>

        <div className="w-full md:w-1/2 flex flex-col h-full">
          <div className="flex-grow overflow-y-auto p-4 md:p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg md:text-xl font-bold text-black">
                {product.product_name}
              </h2>
              <div className="flex space-x-2">
                <button
                  onClick={handleLike}
                  className={`p-2 rounded-full ${
                    isLiked
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                >
                  <Heart className="w-5 h-5" />
                </button>
                <UniversalSocialShare />
              </div>
            </div>
            <p className="text-sm text-black mb-4">
              {product.category} - {product.sub_category}
            </p>

            <div className="flex flex-wrap border-b mb-4 py-2">
              {availableTabs.map((tab) => (
                <button
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                  className={`px-3 md:px-4 py-2 mr-2 mb-2 rounded-t-lg text-xs md:text-sm font-medium transition-colors ${
                    activeTab === tab.key
                      ? "bg-theme-color text-white"
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className="mb-4">
              {activeTab === "description" && (
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: product?.application_of_uses,
                  }}
                />
              )}
              {activeTab === "sizes" && product.sizes && (
                <div className="space-y-2">
                  <h3 className="font-semibold">Available Sizes:</h3>
                  <ul className="list-disc pl-5">
                    {product.sizes
                      .filter((sizeItem) => sizeItem.value !== "N/A")
                      .map((sizeItem, index) => (
                        <li key={index}>{sizeItem.value}</li>
                      ))}
                  </ul>
                </div>
              )}
              {activeTab === "applications" && product.application_of_uses && (
                <div className="space-y-2">
                  <h3 className="font-semibold">Applications:</h3>
                  <div
                    className="mt-4"
                    dangerouslySetInnerHTML={{
                      __html: product?.application_of_uses,
                    }}
                  />
                </div>
              )}
              {activeTab === "downloads" && (
                <div className="flex flex-col md:flex-row md:justify-start gap-3 md:gap-6">
                  {Array.isArray(product.product_documents) &&
                    product.product_documents.length > 0 && (
                      <button
                        onClick={() =>
                          handleDownload(product.product_documents)
                        }
                        className="flex items-center justify-center px-4 py-2 bg-theme-color text-black rounded hover:bg-black hover:text-white transition-colors"
                      >
                        <Download className="w-5 h-5 mr-2" /> Data Sheet
                      </button>
                    )}
                  {Array.isArray(product.product_certifications) &&
                    product.product_certifications.length > 0 && (
                      <button
                        onClick={() =>
                          handleDownload(product.product_certifications)
                        }
                        className="flex items-center justify-center px-4 py-2 bg-theme-color text-black rounded hover:bg-black hover:text-white transition-colors"
                      >
                        <Download className="w-5 h-5 mr-2" /> Installation Guide
                      </button>
                    )}
                </div>
              )}
            </div>
          </div>

          <div className="bg-gray-100 p-4 mt-auto">
            <div className="flex flex-col md:flex-row gap-3 md:gap-5 justify-between items-center">
              <Link
                to={`/product-details/${product.id}`}
                className="w-full text-black text-center bg-white hover:bg-black hover:text-theme-color transition-colors duration-200 text-sm md:text-base font-chivo font-bold shadow-sm py-3 md:py-[13px] rounded"
              >
                Know More
              </Link>
              <Link
                to={`/products/products-details/${product.id}#product-enquiry-form`}
                className="w-full text-white text-center bg-theme-color hover:bg-black transition-colors duration-200 text-sm md:text-base font-chivo font-bold shadow-sm py-3 md:py-[13px] rounded"
              >
                Make Inquiry
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ProductPopup;
