import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Input, Popup, Loader, SiteLogo } from "../../../components/shared";
import image from "../../../assets/images/Company/browsing/background/company browsing (1).jpg";
import google from "../../../assets/images/Loginsignup/Icon-google.svg";
import { Link, useNavigate } from "react-router-dom";
import { HomeIcon } from "lucide-react";

export const Login = () => {
  const { control, handleSubmit } = useForm();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL; // Base URL for API calls
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    try {
      const response = await axios.post(`${baseURL}/api/auth/login`, {
        email: formData.email,
        password: formData.password,
      });

      if (response.data.user.user_approved === 0) {
        setErrorMessage(
          "Your profile is currently under review. You will be able to login in 1-2 working days as soon as you get approval from the administrator."
        );
        return;
      } else {
        navigate("/");
      }

      const token = response.data.token;
      // console.log("login token :-", response);

      const user_id = response.data.user.id;
      localStorage.setItem("authToken", token);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("userData", JSON.stringify(response.data?.user));

      setIsPopupOpen(true);
      setTimeout(() => {
        setIsPopupOpen(false);

        // if (response.data.user.user_approved === 0) {
        //   setErrorMessage(
        //     "Under review"
        //   );
        // }
        // else if (response.data.user.login_as === "COMPANY") {
        //   navigate("/company-profile-setup");
        // } else if (response.data.user.login_as === "INDIVIDUAL") {
        //   navigate("/profile-setup");
        // }
      }, 3000);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Failed to log in. Please check your credentials and try again."
      );
    }
  };
  return (
    <div className="full-width h-screen flex overflow-hidden">
      <div className="hidden flex-[1.2] lg:block">
        <img className="h-full w-full object-cover" src={image} alt="bg" />
      </div>
      <div className="flex-1 bg-theme-light-bg overflow-y-auto">
        <div className="min-h-full flex items-center justify-center py-[50px]">
          <div className="max-w-[400px] w-full">
            <h2 className="font-bold font-chivo text-[25px] leading-[30px] md:text-heading-3 mb-[50px] text-center">
              Login to GoatArch 😊
            </h2>
            {/* <a
              className="flex items-center z-10 relative transition-all duration-200 group py-[13px] md:px-[95px] px-[60px] rounded-md bg-white text-gray-500 hover:text-gray-900 hover:-translate-y-[2px] flex flex-row-reverse w-fit mb-[30px]"
              href={`${baseURL}/auth/google`} // Redirect to Google Auth
            >
              <span className="block text-inherit w-full h-full rounded-md text-base font-semibold">
                Sign in with Google
              </span>
              <img className="mr-5" src={google} alt="google icon" />
            </a>
            <div className="flex items-center justify-center gap-[7px] mb-[25px]">
              <div className="bg-gray-300 w-[50px] h-[2px]"></div>
              <p className="text-text text-gray-500">
                Or, sign in with your email
              </p>
              <div className="bg-gray-300 w-[50px] h-[2px]"></div>
            </div> */}
            <form
              className="bg-white w-full p-8 shadow-3 rounded-[6px] md:p-12"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                label="Email"
                type="email"
                placeholder="johnsmith@gmail.com"
              />
              <Input
                name="password"
                control={control}
                rules={{ required: "Password is required" }}
                label="Password"
                type="password"
                placeholder="********"
              />
              <div className="flex justify-end mt-2 mb-4">
                <Link
                  to="/forgot-password"
                  className="text-theme-color hover:underline text-sm font-bold"
                >
                  Forgot Password?
                </Link>
              </div>
              {errorMessage && (
                <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
              )}
              <button
                className="mb-6 w-full text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-bold shadow-sm py-[13px]"
                type="submit"
              >
                Sign In
              </button>
              <div className="flex gap-2">
                <p className="text-text text-gray-500">
                  Don't have an account?
                </p>
                <Link
                  className="text-green-900 hover:opacity-70"
                  to="/register"
                >
                  <p className="text-black font-bold">Register</p>
                </Link>
              </div>
            </form>
            <div className="mt-5">
              <Link
                to="/"
                className="flex items-start justify-center gap-2 font-bold"
              >
                <HomeIcon className="w-4" /> Back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <div className="flex flex-col items-center justify-center min-h-[300px] space-y-6">
          <SiteLogo className="w-32 md:w-40 lg:w-48" />
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center">
            Success!
          </h2>
          <p className="text-gray-600 text-lg md:text-xl lg:text-2xl text-center">
            You have successfully signed in.
          </p>
          <Loader />
          <p className="text-sm md:text-base lg:text-lg text-gray-500 text-center">
            Redirecting to profile setup page...
          </p>
        </div>
      </Popup>
    </div>
  );
};
