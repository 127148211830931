// export const BrandsSection = ({ brandsData }) => (
//     <section className="py-12 bg-white">
//       <div className="container mx-auto px-4">
//         <h2 className="mt-8 text-2xl md:text-3xl font-bold">Brands We Deal With</h2>
//         <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
//           {brandsData?.map((brand, index) => (
//             <div key={index} className="flex items-center justify-center">
//               <div className="w-full h-24 bg-gray-50 rounded-lg shadow-sm flex items-center justify-center p-4 transition-all duration-300 hover:shadow-md hover:bg-gray-100">
//                 <span className="text-lg font-semibold text-gray-700 text-center">{brand}</span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
import { Briefcase } from "lucide-react";

export const BrandsSection = ({ brandsData }) => (
  <section className="py-8 bg-white">
    <div className="container mx-auto">
      <h2 className="mb-6 text-2xl md:text-3xl font-bold">
        Brands We Deal With
      </h2>
      <div className="flex flex-wrap gap-3">
        {brandsData?.map((brand, index) => (
          <span
            key={index}
            className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-theme-color/30 text-black"
          >
            <Briefcase className="h-4 w-4 mr-1" />
            {brand}
          </span>
        ))}
      </div>
    </div>
  </section>
);
