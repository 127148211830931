// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation, EffectFade } from "swiper/modules";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/effect-fade";

// export const ProjectHeroSlider = ({ projects }) => {
//   const [activeIndex, setActiveIndex] = useState(0);

//   return (
//     <div className="h-[55vh] lg:h-[85vh] w-full relative overflow-hidden">
//       <Swiper
//         modules={[Autoplay, Navigation, EffectFade]}
//         className="h-full w-full"
//         autoplay={{ delay: 5000, disableOnInteraction: false }}
//         navigation={{
//           nextEl: ".swiper-button-next",
//           prevEl: ".swiper-button-prev",
//         }}
//         effect="fade"
//         loop={true}
//         onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
//       >
//         {projects.map((project, index) => (
//           <SwiperSlide key={index}>
//             <div className="h-full w-full relative overflow-hidden">
//               <img
//                 src={project.banner_image}
//                 alt={project.project_name}
//                 className="absolute inset-0 w-full h-full object-cover transform scale-100 transition-transform duration-[10000ms] ease-in-out zoom-effect cursor-grab"
//               />
//               <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end px-6 lg:px-24 py-8">
//                 <div className="text-white">
//                   <h1 className="text-3xl font-bold mb-2 animate-slide-up">
//                     {project.project_name}
//                   </h1>
//                   <p className="text-xl mb-1 animate-slide-up animation-delay-300">
//                     {project.category}
//                   </p>
//                   <p className="text-lg animate-slide-up animation-delay-600">
//                     by {project.company_name}
//                   </p>
//                   <Link to={`/projects/project-details/${project.id}`}>
//                     <button className="mt-4 px-6 py-2 bg-white text-black font-semibold rounded hover:bg-black hover:text-white transition-all duration-300 animate-slide-up animation-delay-900">
//                       View Project
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>

//       <div className="swiper-button-prev !left-4 !text-white">
//         <ChevronLeftIcon className="h-8 w-8" />
//       </div>
//       <div className="swiper-button-next !right-4 !text-white">
//         <ChevronRightIcon className="h-8 w-8" />
//       </div>

//       <div className="absolute bottom-0 left-0 w-full h-1 bg-white bg-opacity-20">
//         <div
//           className="h-full bg-white transition-all duration-300 ease-in-out"
//           style={{ width: `${((activeIndex + 1) / projects.length) * 100}%` }}
//         ></div>
//       </div>
//     </div>
//   );
// };

// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation, EffectFade } from "swiper/modules";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/effect-fade";

// export const ProjectHeroSlider = ({ projects }) => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [swiper, setSwiper] = useState(null);
//   const prevRef = useRef(null);
//   const nextRef = useRef(null);

//   useEffect(() => {
//     if (swiper && swiper.params) {
//       swiper.params.navigation = {
//         ...swiper.params.navigation,
//         prevEl: prevRef.current,
//         nextEl: nextRef.current,
//       };
//       swiper.navigation.init();
//       swiper.navigation.update();
//     }
//   }, [swiper]);

//   return (
//     <div className="h-[55vh] lg:h-[85vh] w-full relative overflow-hidden">
//       <Swiper
//         modules={[Autoplay, Navigation, EffectFade]}
//         className="h-full w-full"
//         autoplay={{ delay: 5000, disableOnInteraction: false }}
//         effect="fade"
//         loop={true}
//         onSwiper={setSwiper}
//         onSlideChange={(swiperInstance) =>
//           setActiveIndex(swiperInstance.realIndex)
//         }
//         navigation={{
//           prevEl: prevRef.current,
//           nextEl: nextRef.current,
//         }}
//       >
//         {projects.map((project, index) => (
//           <SwiperSlide key={index}>
//             <div className="h-full w-full relative overflow-hidden">
//               <img
//                 src={project.banner_image}
//                 alt={project.project_name}
//                 className="absolute inset-0 w-full h-full object-cover transform scale-100 transition-transform duration-[10000ms] ease-in-out zoom-effect cursor-grab"
//               />
//               <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end px-6 lg:px-24 py-8">
//                 <div className="text-white">
//                   <h1 className="text-3xl font-bold mb-2 animate-slide-up">
//                     {project.project_name}
//                   </h1>
//                   <p className="text-xl mb-1 animate-slide-up animation-delay-300">
//                     {project.category}
//                   </p>
//                   <p className="text-lg animate-slide-up animation-delay-600">
//                     by {project.company_name}
//                   </p>
//                   <Link to={`/projects/project-details/${project.id}`}>
//                     <button className="mt-4 px-6 py-2 bg-white text-black font-semibold rounded hover:bg-black hover:text-white transition-all duration-300 animate-slide-up animation-delay-900">
//                       View Project
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>

//       <div ref={prevRef} className="swiper-button-prev !left-4 !text-white">
//         <ChevronLeftIcon className="h-8 w-8" />
//       </div>
//       <div ref={nextRef} className="swiper-button-next !right-4 !text-white">
//         <ChevronRightIcon className="h-8 w-8" />
//       </div>

//       <div className="absolute bottom-0 left-0 w-full h-1 bg-white bg-opacity-20">
//         <div
//           className="h-full bg-white transition-all duration-300 ease-in-out"
//           style={{ width: `${((activeIndex + 1) / projects.length) * 100}%` }}
//         ></div>
//       </div>
//     </div>
//   );
// };

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Don't forget to import the CSS files in your main CSS or in this file
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const NextArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 right-4 -mt-4 z-10 cursor-pointer"
    onClick={onClick}
  >
    <ChevronRightIcon className="h-8 w-8 text-white" />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 left-4 -mt-4 z-10 cursor-pointer"
    onClick={onClick}
  >
    <ChevronLeftIcon className="h-8 w-8 text-white" />
  </div>
);

export const ProjectHeroSlider = ({ projects }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  };

  return (
    <div className="h-[55vh] lg:h-[85vh] w-full relative overflow-hidden">
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="h-[55vh] lg:h-[85vh] relative">
            <img
              src={project.banner_image}
              alt={project.project_name}
              className="absolute inset-0 w-full h-full object-cover transform scale-100 transition-transform duration-[10000ms] ease-in-out zoom-effect"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end px-6 lg:px-24 py-8">
              <div className="text-white">
                <span className="text-3xl font-bold mb-2 animate-slide-up">
                  {project.project_name}
                </span>
                <p className="text-xl mb-1 animate-slide-up animation-delay-300">
                  {project.category}
                </p>
                <p className="text-lg animate-slide-up animation-delay-600">
                  by {project.company_name}
                </p>
                <Link to={`/projects/project-details/${project.id}-${project.project_name.replace(/\s+/g, '-')}/`}>
                  <button className="mt-4 px-6 py-2 bg-white text-black font-semibold rounded hover:bg-black hover:text-white transition-all duration-300 animate-slide-up animation-delay-900">
                    View Project
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/* <div className="absolute bottom-0 left-0 w-full h-1 bg-white bg-opacity-20">
        <div
          className="h-full bg-black transition-all duration-300 ease-in-out"
          style={{ width: `${((activeSlide + 1) / projects.length) * 100}%` }}
        ></div>
      </div> */}
    </div>
  );
};
