import React, { useState, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Eye } from "lucide-react";

const ImageGalleryStyleTwo = ({ images = [], imagesPerRow = 3, showOverlay = true }) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]);

  const openCarousel = useCallback((index) => {
    setActiveImageIndex(index);
    setIsCarouselOpen(true);
    document.body.style.overflow = "hidden";
  }, []);

  const closeCarousel = useCallback(() => {
    setIsCarouselOpen(false);
    document.body.style.overflow = "";
  }, []);

  const nextImage = useCallback(() => {
    if (loadedImages.length > 0) {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % loadedImages.length);
    }
  }, [loadedImages]);

  const prevImage = useCallback(() => {
    if (loadedImages.length > 0) {
      setActiveImageIndex(
        (prevIndex) => (prevIndex - 1 + loadedImages.length) % loadedImages.length
      );
    }
  }, [loadedImages]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape" && isCarouselOpen) {
        closeCarousel();
      }
      if (event.key === "ArrowRight" && isCarouselOpen) {
        nextImage();
      }
      if (event.key === "ArrowLeft" && isCarouselOpen) {
        prevImage();
      }
    },
    [isCarouselOpen, closeCarousel, nextImage, prevImage]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (images && images.length > 0) {
      const preloadImages = async () => {
        const loadedImagesArray = await Promise.all(
          images.map((src) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = src;
              img.onload = () => resolve(src);
              img.onerror = () => resolve(null);
            });
          })
        );
        setLoadedImages(loadedImagesArray.filter(Boolean));
      };

      preloadImages();
    }
  }, [images]);

  const getGridColumns = () => {
    const validImagesPerRow = Math.min(Math.max(imagesPerRow, 1), 6);
    return {
      1: "grid-cols-1",
      2: "grid-cols-1 sm:grid-cols-2",
      3: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
      4: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
      5: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
      6: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6",
    }[validImagesPerRow];
  };

  return (
    <div className="container mx-auto">
      <div className={`grid ${getGridColumns()} gap-4`}>
        {loadedImages.length > 0 ? (
          loadedImages.map((image, index) => (
            <div key={index} className="relative group aspect-square overflow-hidden">
              <img
                src={image}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-full rounded object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
              />
              {showOverlay && (
                <div className="absolute inset-0 bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                  <button
                    onClick={() => openCarousel(index)}
                    className="text-white hover:text-gray-200 transition-colors duration-300"
                  >
                    <Eye size={32} />
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No images available.</p>
        )}
      </div>

      <AnimatePresence>
        {isCarouselOpen && loadedImages.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[99999] flex items-center justify-center bg-black bg-opacity-90"
          >
            <button
              onClick={closeCarousel}
              className="absolute top-4 right-4 text-4xl text-white hover:text-gray-300"
            >
              ×
            </button>
            <div className="flex flex-col items-center">
              <img
                src={loadedImages[activeImageIndex]}
                alt={`Carousel image ${activeImageIndex + 1}`}
                className="max-w-[80vw] max-h-[70vh] object-contain"
              />
              <div className="flex justify-center mt-4 space-x-2 overflow-x-auto max-w-full">
                {loadedImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => setActiveImageIndex(index)}
                    className={`w-16 h-16 object-cover cursor-pointer transition-opacity duration-300 ${
                      index === activeImageIndex
                        ? "opacity-100"
                        : "opacity-60 hover:opacity-100"
                    }`}
                  />
                ))}
              </div>
              <button
                onClick={prevImage}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-4xl text-white hover:text-gray-300"
              >
                &#10094;
              </button>
              <button
                onClick={nextImage}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-4xl text-white hover:text-gray-300"
              >
                &#10095;
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ImageGalleryStyleTwo;