import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../../../components/layout/layout";
import { BlogBanner, ArticleSocialShare } from "../../../components/shared";
import BlogFour from "../../../assets/images/Blogs/blogfour.jpg";

const SustainableMaterialsArticle = () => {
  const blogPost = {
    title: "Quality Materials: The Foundation of Sustainable Architecture",
    category: "Sustainability",
    author: "Michael Chen",
    readTime: "12 min read",
    publishDate: "Nov 15, 2024",
    bannerImage: BlogFour,
    breadcrumbs: [
      { label: "Blog", url: "/blog" },
      { label: "Sustainable Building", url: "/blog/sustainability" },
    ],
  };

  return (
    <Layout headerType="2">
      <section className="mt-20">
        <BlogBanner {...blogPost} />
      </section>
      <article className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction */}
        <div className="mb-12">
          <p className="text-lg text-gray-700 leading-relaxed">
            The construction industry stands at a crucial intersection of
            innovation and environmental responsibility. As we face growing
            environmental challenges, the selection of high-quality, sustainable
            building materials has become more critical than ever. This
            comprehensive guide explores how premium materials not only enhance
            building performance but also contribute significantly to
            environmental conservation and long-term sustainability goals.
          </p>
        </div>

        {/* Environmental Impact Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            The Environmental Impact of Quality Materials
          </h2>
          <div className="space-y-6">
            <p className="text-gray-700">
              High-quality materials play a crucial role in reducing
              environmental impact through:
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              <ul className="list-disc list-inside space-y-3 text-gray-700">
                <li>
                  Lower replacement frequency, reducing waste and resource
                  consumption
                </li>
                <li>Enhanced energy efficiency and thermal performance</li>
                <li>
                  Reduced carbon footprint during manufacturing and
                  transportation
                </li>
                <li>Better recyclability and end-of-life management</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Key Materials Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Premium Sustainable Building Materials
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">
                Structural Materials
              </h3>
              <ul className="space-y-3 text-gray-700">
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Mass timber and engineered wood products</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>
                    High-performance concrete with recycled aggregates
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Recycled steel and aluminum</span>
                </li>
              </ul>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">
                Insulation & Finishes
              </h3>
              <ul className="space-y-3 text-gray-700">
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Natural fiber insulation materials</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Low-VOC paints and coatings</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Reclaimed wood and bamboo flooring</span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Performance Benefits Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Performance Benefits of Quality Materials
          </h2>
          <div className="bg-theme-color/5 p-6 rounded-lg">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-semibold text-lg mb-4">
                  Energy Efficiency
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Superior thermal insulation properties</li>
                  <li>Better air tightness and moisture control</li>
                  <li>Reduced heating and cooling costs</li>
                  <li>Enhanced natural lighting utilization</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold text-lg mb-4">Durability</h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Extended building lifespan</li>
                  <li>Lower maintenance requirements</li>
                  <li>Better resistance to weather and wear</li>
                  <li>Improved structural integrity</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Economic Benefits Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Long-term Economic Benefits
          </h2>
          <div className="space-y-6">
            <p className="text-gray-700">
              Investment in quality sustainable materials yields significant
              returns:
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>
                    Reduced maintenance and replacement costs over time
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Lower energy bills through improved efficiency</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>Increased property value and market appeal</span>
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-theme-color font-bold">•</span>
                  <span>
                    Potential tax incentives and certification benefits
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Future Trends Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Emerging Trends in Sustainable Materials
          </h2>
          <div className="bg-theme-color/5 p-6 rounded-lg">
            <ul className="space-y-4 text-gray-700">
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Smart materials with self-healing properties</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Bio-based materials from renewable sources</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Carbon-negative building materials</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Advanced recycling and upcycling technologies</span>
              </li>
            </ul>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Discover Sustainable Materials on GoatArch
          </h2>
          <div className="bg-theme-color/5 p-6 rounded-lg mb-6">
            <p className="text-gray-700 mb-6">
              GoatArch provides architects and builders with comprehensive
              resources for sustainable construction:
            </p>
            <div className="grid md:grid-cols-2 gap-6 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="font-semibold text-lg mb-4">Material Library</h3>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start gap-2">
                    <span className="text-theme-color font-bold">•</span>
                    <span>Extensive database of sustainable materials</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-theme-color font-bold">•</span>
                    <span>
                      Detailed sustainability metrics and certifications
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-theme-color font-bold">•</span>
                    <span>Performance data and case studies</span>
                  </li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="font-semibold text-lg mb-4">
                  Platform Benefits
                </h3>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start gap-2">
                    <span className="text-theme-color font-bold">•</span>
                    <span>Direct connection with sustainable suppliers</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-theme-color font-bold">•</span>
                    <span>Expert community insights and reviews</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-theme-color font-bold">•</span>
                    <span>Latest updates on material innovations</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/products/"
                className="bg-theme-color text-white px-8 py-3 rounded-lg hover:bg-theme-color/90 transition-colors text-center"
              >
                Browse Material Library
              </Link>
              <Link
                to="/register"
                className="border-2 border-theme-color text-theme-color px-8 py-3 rounded-lg hover:bg-theme-color/10 transition-colors text-center"
              >
                Join GoatArch Today
              </Link>
            </div>
          </div>
        </section>

        {/* Conclusion - Updated with GoatArch reference */}
        <section>
          <div className="bg-gray-50 p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">
              Building a Sustainable Future Together
            </h2>
            <p className="text-gray-700 mb-6">
              The choice of quality materials in construction is not just about
              building better structures – it's about creating a sustainable
              future for generations to come. With platforms like GoatArch,
              accessing premium sustainable materials and connecting with
              industry experts has never been easier. Join our community of
              sustainable builders and architects to make a lasting positive
              impact on the construction industry.
            </p>
            {/* <div className="flex flex-wrap gap-4">
              <Link
                to="/register"
                className="bg-theme-color text-white px-6 py-2 rounded-lg hover:bg-theme-color/90 transition-colors"
              >
                Start Your Sustainable Journey
              </Link>
              <Link
                to="/materials-library"
                className="border border-theme-color text-theme-color px-6 py-2 rounded-lg hover:bg-theme-color/10 transition-colors"
              >
                Explore Materials
              </Link>
              <Link
                to="/sustainability-guide"
                className="border border-theme-color text-theme-color px-6 py-2 rounded-lg hover:bg-theme-color/10 transition-colors"
              >
                Download Complete Guide
              </Link>
            </div> */}
          </div>
        </section>
        <ArticleSocialShare
          title={
            "Quality Materials: The Foundation of Sustainable Architecture"
          }
          url={
            "https://goatarch.com/quality-materials-the-foundation-of-sustainable-architecture/"
          }
        />
      </article>
    </Layout>
  );
};

export default SustainableMaterialsArticle;
