import React from "react";
import { Input } from "../../../../../components/shared";
export const CreateProductStepTwo = ({ control, errors }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-6">
        <div className="bg-white lg:p-6 lg:shadow rounded">
          <h3 className="text-xl font-semibold text-gray-900">Project Media</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
            <div>
              <Input
                name="productMainImage"
                control={control}
                type="file"
                label="Upload Product Main Image"
                acceptedFileTypes="images"
                multiple={false}
                maxSizeInMB={2}
                rules={{ required: "Product main image is required" }}
              />
              <p className="text-left text-gray-600 text-sm">
                <strong>Note:</strong> Please upload a high-definition image.
                Landscape orientation is preferred over portrait
              </p>
            </div>
            <div>
              <Input
                name="productSupportingImages"
                control={control}
                type="file"
                label="Upload Product Supporting Images"
                acceptedFileTypes="images"
                multiple={true}
                maxFiles={6}
                maxSizeInMB={2}
                rules={{ required: "Product supporting images is required" }}
              />

              <p className="text-left text-gray-600 text-sm">
                <strong>Note:</strong> Upload high-quality images. Prefer
                landscape orientation for better presentation.
              </p>
            </div>
            <Input
              name="videoEmbedLinks"
              control={control}
              label="Product Information video/ installation video"
              type="text"
              placeholder="Enter video embed link"
            />
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white lg:p-6 lg:shadow rounded">
            <h3 className="text-xl font-semibold text-gray-900">Downloads </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
              <Input
                name="productDataSheet"
                control={control}
                type="file"
                label="Upload Documents"
                acceptedFileTypes="documents"
                multiple={false}
                maxSizeInMB={10}
              />

              <Input
                name="productGreenguardCertifications"
                control={control}
                type="file"
                label="Products GREENGUARD Certification"
                acceptedFileTypes="documents"
                multiple={false}
                maxSizeInMB={10}
              />
            </div>
            <div className="flex items-center">
              <Input
                name="termsAccepted"
                control={control}
                type="checkbox"
                inlineLabel='I accept the <a href="/terms-and-conditions" class="text-theme-color hover:underline">Terms and Conditions</a>'
                rules={{ required: "You must accept the terms and conditions" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
