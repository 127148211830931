// import React, { useState, useEffect } from "react";
// import { X } from "lucide-react";

// export const Toast = ({
//   message,
//   type = "error",
//   duration = 3000,
//   onClose,
// }) => {
//   const [isVisible, setIsVisible] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setIsVisible(false);
//       onClose();
//     }, duration);

//     return () => clearTimeout(timer);
//   }, [duration, onClose]);

//   if (!isVisible) return null;

//   const bgColor = type === "error" ? "bg-red-500" : "bg-green-500";

//   return (
//     <div
//       className={`fixed top-4 right-4 ${bgColor} text-white p-4 rounded-md shadow-lg max-w-sm z-50 flex items-center justify-between`}
//     >
//       <p>{message}</p>
//       <button
//         onClick={() => setIsVisible(false)}
//         className="ml-4 text-white hover:text-gray-200"
//       >
//         <X size={18} />
//       </button>
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import { X } from "lucide-react";

export const Toast = ({
  message,
  type = "error",
  duration,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Only set timeout if duration is provided
    if (duration) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  if (!isVisible) return null;

  const bgColor = type === "error" ? "bg-red-500" : "bg-green-500";

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div
      className={`fixed top-4 right-4 ${bgColor} text-white p-4 rounded-md shadow-lg z-50 flex items-center justify-between`}
    >
      <div className="mr-4">{message}</div>
      <button
        onClick={handleClose}
        className="ml-4 text-white hover:text-gray-200 flex-shrink-0"
      >
        <X size={18} />
      </button>
    </div>
  );
};