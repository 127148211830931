import React from "react";

export const BreadcrumbComponentTwo = ({ title, bgImage }) => {
  return (
    <div className="relative h-[42vh]">
      <div
        className="absolute inset-0 bg-black"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-25"></div>
      <div className="absolute bottom-0 left-0 px-6 lg:px-24">
        <h1 className="text-center lg:text-left text-[52px] lg:text-[72px] font-bold text-white -mb-[20px] lg:-mb-[27px] uppercase">
          {title}
        </h1>
      </div>
    </div>
  );
};
