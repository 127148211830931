import React, { useState } from "react";
import { Calendar, MapPin, Clock, User } from "lucide-react";
import { Layout } from "../../components/layout/layout";
import { BreadcrumbComponentTwo, PhotoDisplay } from "../../components/shared";
import EventBanner from "../../assets/images/Events/eventsbg.jpg";
import EventRegistrationPopup from "./Event Registration";
import { Link, Navigate } from "react-router-dom";

const dummyEvents = [
  {
    id: "1",
    imageUrl: "fx7iQCFpUu0",
    title: "Tech Conference 2023",
    date: "May 15, 2023",
    time: "9:00 AM - 5:00 PM",
    location: "San Francisco Convention Center",
    description:
      "Join us for the annual tech conference showcasing the latest innovations and trends.",
    attendees: "500+ Expected",
    category: "Technology",
    price: "$299",
  },
  {
    id: "2",
    imageUrl: "CWwoCY87p4k",
    title: "Art Exhibit Opening",
    date: "June 1, 2023",
    time: "7:00 PM - 10:00 PM",
    location: "Metropolitan Art Gallery, NYC",
    description:
      "Experience the works of renowned artists at our exclusive art exhibit opening.",
    attendees: "200+ Expected",
    category: "Art & Culture",
    price: "$75",
  },
  {
    id: "3",
    imageUrl: "7LHR3UQwpto",
    title: "Outdoor Music Festival",
    date: "July 20, 2023",
    time: "12:00 PM - 11:00 PM",
    location: "Waterfront Park, Seattle",
    description:
      "Enjoy a day of live music, food, and fun at our outdoor music festival.",
    attendees: "1000+ Expected",
    category: "Music",
    price: "$150",
  },
];

const EventCard = ({ event, onRegisterClick }) => {
  return (
    <div className="group relative bg-theme-light-bg rounded overflow-hidden transform transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl">
      {/* Image Container */}
      <div className="relative h-64 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10" />
        <PhotoDisplay
          photoId={event.imageUrl}
          className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
        />
        {/* Category Badge */}
        <span className="absolute top-4 right-4 z-20 bg-white/95 px-4 py-1 rounded-full text-sm font-medium text-gray-800">
          {event.category}
        </span>
        {/* Price Badge */}
        <span className="absolute top-4 left-4 z-20 bg-theme-color text-white px-4 py-1 rounded-full text-sm font-bold">
          {event.price}
        </span>
      </div>

      {/* Content Container */}
      <div className="p-6 space-y-4">
        {/* Title */}
        <h3 className="text-2xl font-bold text-gray-800 group-hover:text-theme-color transition-colors duration-300">
          {event.title}
        </h3>

        {/* Event Details Grid */}
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center gap-2 text-gray-600">
            <Calendar className="h-4 w-4 text-theme-color" />
            <span className="text-sm">{event.date}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <Clock className="h-4 w-4 text-theme-color" />
            <span className="text-sm">{event.time}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <MapPin className="h-4 w-4 text-theme-color" />
            <span className="text-sm truncate">{event.location}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <User className="h-4 w-4 text-theme-color" />
            <span className="text-sm">{event.attendees}</span>
          </div>
        </div>

        {/* Description */}
        <p className="text-gray-600 text-sm leading-relaxed">
          {event.description}
        </p>

        {/* Action Buttons */}
        <div className="flex gap-3 pt-2">
          <button
            className="flex-1 bg-theme-color text-white py-3 rounded font-semibold transition-all duration-300 hover:bg-black group-hover:shadow-lg"
            onClick={() => onRegisterClick(event.id)}
          >
            Register Now
          </button>
          <Link
            className="px-4 py-3 border-2 border-gray-200 rounded-xl text-gray-600 transition-all duration-300 hover:border-theme-color hover:text-theme-color"
            to={"/events/event-details/"}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 12h14M12 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const EventsPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const handleRegisterClick = (eventId) => {
    setSelectedEventId(eventId);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setSelectedEventId(null);
  };

  const selectedEvent = dummyEvents.find(
    (event) => event.id === selectedEventId
  );

  return (
    <Layout headerType="2">
      <div className="mt-[75px]">
        <BreadcrumbComponentTwo bgImage={EventBanner} title="Discover Events" />
      </div>

      <section className="py-20 px-4 md:px-8 lg:px-16 bg-gray-50">
        {/* Section Header */}
        <div className="max-w-7xl mx-auto text-center mb-16">
          <h4 className="text-theme-color font-bold mb-3">Upcoming Events</h4>
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900">
            Featured Events
          </h2>
          <div className="w-24 h-1 bg-theme-color mx-auto mb-6" />
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Join us for exciting events throughout the year. From tech
            conferences to art exhibitions, there's something for everyone.
          </p>
        </div>

        {/* Events Grid */}
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {dummyEvents.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              onRegisterClick={handleRegisterClick}
            />
          ))}
        </div>
      </section>

      {selectedEvent && (
        <EventRegistrationPopup
          isOpen={isPopupOpen}
          onClose={handlePopupClose}
          event={selectedEvent}
        />
      )}
    </Layout>
  );
};

export default EventsPage;
