import React from "react";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import {
  EyeIcon,
  UserIcon,
  PhoneIcon,
  EnvelopeIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";

const statsData = [
  { label: "Total Products", value: 2, color: "bg-blue-100 text-blue-800" },
  { label: "Inquiries", value: 10, color: "bg-green-100 text-green-800" },
  {
    label: "Project Under Review",
    value: 2,
    color: "bg-yellow-100 text-yellow-800",
  },
  { label: "Profile Visited", value: 25, color: "bg-red-100 text-red-800" },
];

const inquiries = [
  {
    user: "Donald",
    action: "commented on",
    target: "Refund #1234",
    time: "10 Min ago",
  },
  {
    user: "Lucy Peterson",
    action: "made an inquiry about",
    target: "Product X",
    time: "50 Min ago",
  },
  {
    user: "Joseph Rust",
    action: "requested information on",
    target: "Service Y",
    time: "1h ago",
  },
  {
    user: "Emily Johnson",
    action: "asked a question about",
    target: "Order #5678",
    time: "Yesterday",
  },
  {
    user: "Michael Smith",
    action: "submitted feedback for",
    target: "Project Z",
    time: "2 days ago",
  },
];

const projects = [
  {
    id: 1,
    name: "Skyline Plaza",
    email: "john@example.com",
    phone: "123-456-7890",
    projectCategoryName: "Commercial",
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane@example.com",
    phone: "234-567-8901",
    projectCategoryName: "Residential",
  },
  {
    id: 3,
    name: "Bob Johnson",
    email: "bob@example.com",
    phone: "345-678-9012",
    projectCategoryName: "Commercial",
  },
  {
    id: 4,
    name: "Alice Brown",
    email: "alice@example.com",
    phone: "456-789-0123",
    projectCategoryName: "Residential",
  },
];

export const DashboardProfile = () => {
  return (
    <DashboardLayout>
      <div className="min-h-screen p-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800">Dashboard</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {statsData.map((stat, index) => (
            <div
              key={index}
              className={`${stat.color} rounded-lg shadow-md p-6 transition-transform duration-300 hover:scale-105`}
            >
              <h3 className="text-3xl font-bold mb-2">{stat.value}</h3>
              <p className="text-sm font-medium">{stat.label}</p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="bg-theme-color p-4">
                <h2 className="text-xl font-semibold text-white">
                  Recent Projects
                </h2>
              </div>
              <div className="p-6">
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b">
                        <th className="text-left p-2 text-gray-600">
                          Project Name
                        </th>
                        <th className="text-left p-2 text-gray-600">Email</th>
                        <th className="text-left p-2 text-gray-600">Phone</th>
                        <th className="text-left p-2 text-gray-600">
                          Category
                        </th>
                        <th className="text-left p-2 text-gray-600">Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project) => (
                        <tr
                          key={project.id}
                          className="border-b hover:bg-gray-50 text-left"
                        >
                          <td className="p-2 flex items-center">
                            <UserIcon className="h-5 w-5 text-gray-400 mr-2" />
                            {project.name}
                          </td>
                          <td className="p-2">
                            <EnvelopeIcon className="h-5 w-5 text-gray-400 inline mr-2" />
                            {project.email}
                          </td>
                          <td className="p-2">
                            <PhoneIcon className="h-5 w-5 text-gray-400 inline mr-2" />
                            {project.phone}
                          </td>
                          <td className="p-2">
                            <BriefcaseIcon className="h-5 w-5 text-gray-400 inline mr-2" />
                            {project.projectCategoryName}
                          </td>
                          <td className="p-2">
                            <button className="text-black hover:text-theme-color">
                              <EyeIcon className="h-5 w-5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="bg-black p-4">
                <h2 className="text-xl font-semibold text-white">
                  Recent Inquiries
                </h2>
              </div>
              <div className="p-6">
                {inquiries.map((inquiry, index) => (
                  <div
                    key={index}
                    className="mb-4 pb-4 border-b last:border-b-0"
                  >
                    <p className="text-sm text-gray-800">
                      <span className="font-semibold">{inquiry.user}</span>{" "}
                      {inquiry.action}{" "}
                      <span className="font-semibold text-theme-color">
                        {inquiry.target}
                      </span>
                    </p>
                    <small className="text-gray-500">{inquiry.time}</small>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
