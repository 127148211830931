import React from "react";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import BgImage from "../../../assets/images/Projects/healthcare.jpg";
import Logo from "../../../assets/images/Company/companylistingDummylogo.png";

export const DashboardProjects = () => {
  const userInfo = {
    name: "Username",
    title: "Profile Type",
    featuredWorks: [
      {
        title: "Zen Loft Retreat",
        image: `${BgImage}`,
        description: "A minimalist urban sanctuary",
      },
      {
        title: "Coastal Charm Villa",
        image: `${BgImage}`,
        description: "Beachside luxury redefined",
      },
      {
        title: "Eclectic Art Studio",
        image: `${BgImage}`,
        description: "Where creativity meets comfort",
      },
      {
        title: "Eclectic Art Studio",
        image: `${BgImage}`,
        description: "Where creativity meets comfort",
      },
      {
        title: "Eclectic Art Studio",
        image: `${BgImage}`,
        description: "Where creativity meets comfort",
      },
      {
        title: "Eclectic Art Studio",
        image: `${BgImage}`,
        description: "Where creativity meets comfort",
      },
      {
        title: "Eclectic Art Studio",
        image: `${BgImage}`,
        description: "Where creativity meets comfort",
      },
    ],
    skills: [
      "Contemporary",
      "Minimalist",
      "Eco-friendly",
      "Luxury",
      "Commercial",
      "Residential",
    ],
  };

  return (
    <DashboardLayout>
      <div className="overflow-y-auto z-[99] p-10 bg-white shadow-md rounded text-left">
        {/* Header */}
        <header className="relative rounded-xl overflow-hidden shadow-lg">
          <img
            src={BgImage}
            alt="Designer Workspace"
            className="w-full h-[500px] object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
          <div className="absolute bottom-0 left-0 p-6 text-white">
            <h1 className="text-4xl font-bold">{userInfo.name}</h1>
            <p className="text-xl">{userInfo.title}</p>
          </div>
          <img
            src={Logo}
            alt={userInfo.name}
            className="absolute bottom-5 right-10 w-36 h-36 rounded-full border-4 border-white shadow-lg object-cover"
          />
        </header>

        <section>
          <h2 className="text-2xl font-semibold mb-4 mt-10">Featured Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mt-10">
            {userInfo.featuredWorks.map((work, index) => (
              <div
                key={index}
                className="bg-white rounded-xl overflow-hidden shadow-md"
              >
                <img
                  src={work.image}
                  alt={work.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="font-semibold text-lg">{work.title}</h3>
                  <p className="text-sm text-gray-600">{work.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </DashboardLayout>
  );
};
