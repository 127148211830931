import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EyeIcon, ShareIcon } from "lucide-react";
import ProductPopup from "../../Products/Products Detail Popup";
export const SearchResultsProducts = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  if (!products || products.length === 0) {
    return (
      <section className="mb-24">
        <div className="flex flex-col px-4 lg:px-24">
          <h2
            className="font-bold text-theme-color text-left mb-10"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            <span className="text-black">Products </span>
          </h2>
          <p>No products found matching your search criteria.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="mb-24">
      <div className="flex flex-col px-4 lg:px-24">
        <h2
          className="font-bold text-theme-color text-left mb-10"
          style={{ fontFamily: "Lato, sans-serif" }}
        >
          <span className="text-black">Products </span>
        </h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          {products.map((product) => (
            <div className="group relative overflow-hidden rounded shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer">
              <Link to={`/products/products-details/${product.id}/`} rel="nofollow">
                <div className="relative h-80">
                  <img
                    src={product?.product_banner_image}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/30 group-hover:bg-black/40 transition-colors duration-300" />
                </div>
              </Link>

              <Link to="/products/products-details/" rel="nofollow">
                <div className="absolute bottom-4 left-4 right-4">
                  <span className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300">
                    {product?.collection_name}
                  </span>
                  <p className="text-white text-sm">{product.company_name}</p>
                </div>
              </Link>
              <div className="absolute top-4 right-4 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="relative">
                  <button
                    className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProductClick(product);
                    }}
                  >
                    <EyeIcon className="h-5 w-5 text-gray-800" />
                  </button>
                  <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
                    <div className="bg-black/80 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                      Quick View
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <button className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200">
                    <ShareIcon className="h-5 w-5 text-gray-800" />
                  </button>
                  <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
                    <div className="bg-black/80 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                      Share Product
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute top-4 left-4 text-white px-3 py-1 rounded-full text-sm font-medium">
                {product?.category}
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          isOpen={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </section>
  );
};
