export const PolicyPagesFooter = () => {
  return (
    <div className="mt-8 bg-theme-light-bg p-6 rounded-lg">
      <h2 className="text-2xl font-semibold mb-4 text-black">10. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <a href="mailto:info@goatarch.com" className="text-gray-700">Email: info@goatarch.com</a><br />
      <a href="tel:+917400510053" className="text-gray-700">Contact: +91 74005 10053</a>
    </div>
  );
};
