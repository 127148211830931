// import React from "react";
// import { useController } from "react-hook-form";
// import { Input } from "../../../../../components/shared";

// const consultantCategories = [
//   {
//     value: "projectManagementConsultant",
//     label: "Project Management Consultant",
//   },
//   { value: "lighting", label: "Lighting" },
//   { value: "acousticConsultant", label: "Acoustic Consultant" },
//   {
//     value: "structuralEngineeringConsultant",
//     label: "Structural Or Engineering Consultant",
//   },
//   { value: "avIT", label: "AV and IT" },
// ];

// const contractorCategories = [
//   { value: "contractorFitout", label: "Contractor or Fitout contractor" },
//   { value: "mepContractors", label: "MEP Contractors" },
//   { value: "joinery", label: "Joinery" },
//   { value: "foundationAndPiling", label: "Foundation and Piling" },
// ];

// const CategoryCard = ({ title, categories, control }) => {
//   return (
//     <div className="bg-white shadow rounded p-6">
//       <h3 className="text-xl font-semibold mb-4">{title}</h3>
//       {categories.map((category, index) => (
//         <div key={index} className="mb-4">
//           <Input
//             name={category.value}
//             control={control}
//             label={category.label}
//             placeholder={`Enter ${category.label}`}
//           />
//         </div>
//       ))}
//     </div>
//   );
// };

// export const CreateProjectStepThree = ({ control }) => {
//   return (
//     <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//         <CategoryCard
//           title="Consultants"
//           categories={consultantCategories}
//           control={control}
//         />
//         <CategoryCard
//           title="Contractors"
//           categories={contractorCategories}
//           control={control}
//         />
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Input } from "../../../../../components/shared";

const consultantCategories = [
  {
    value: "projectManagementConsultant",
    label: "Project Management Consultant",
  },
  { value: "lighting", label: "Lighting" },
  { value: "acousticConsultant", label: "Acoustic Consultant" },
  {
    value: "structuralEngineeringConsultant",
    label: "Structural Or Engineering Consultant",
  },
  { value: "avIT", label: "AV and IT" },
];

const contractorCategories = [
  {
    value: "contractorFitout",
    label: "Contractor or Fitout contractor",
    required: true,
  },
  { value: "mepContractors", label: "MEP Contractors" },
  { value: "joinery", label: "Joinery" },
  { value: "foundationAndPiling", label: "Foundation and Piling" },
];

const CategoryCard = ({ title, categories, control, errors }) => {
  return (
    <div className="bg-white lg:shadow rounded lg:p-6">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      {categories.map((category, index) => (
        <div key={index} className="mb-4">
          <Input
            name={category.value}
            control={control}
            label={`${category.label}${category.required ? ' *' : ''}`}
            placeholder={`Enter ${category.label}`}
            rules={
              category.required
                ? { 
                    required: `${category.label} is required`,
                    validate: value => 
                      value && value.trim() !== '' || `${category.label} cannot be empty`
                  }
                : undefined
            }
            error={errors[category.value]?.message}
          />
        </div>
      ))}
    </div>
  );
};

export const CreateProjectStepThree = ({ control, errors }) => {
  return (
    <div className="mx-auto lg:px-8 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <CategoryCard
          title="Consultants"
          categories={consultantCategories}
          control={control}
          errors={errors}
        />
        <CategoryCard
          title="Contractors"
          categories={contractorCategories}
          control={control}
          errors={errors}
        />
      </div>
    </div>
  );
};