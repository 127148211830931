import React from "react";
import { Input } from "../../../../../components/shared";
import { useWatch } from "react-hook-form";

export const IndividualProfileUpdateStepThree = ({ control }) => {
  const companyCount = useWatch({
    control,
    name: "companyCount",
    defaultValue: "1",
  });

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-gray-900">Company Details</h3>
      <Input
        name="companyCount"
        control={control}
        label="Companies you work for"
        type="select"
        options={[1, 2, 3, 4, 5].map((num) => ({
          value: num.toString(),
          label: num.toString(),
        }))}
      />
      {[...Array(parseInt(companyCount))].map((_, index) => (
        <Input
          key={index}
          name={`company${index + 1}`}
          control={control}
          label={`Company ${index + 1}`}
          placeholder={`Enter company ${index + 1} name`}
        />
      ))}
    </div>
  );
};