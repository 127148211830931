import React from "react";
import { Link } from "react-router-dom";

export const SearchResultsProjects = ({ projects }) => {
  if (!projects || projects.length === 0) {
    return (
      <section className="px-4 sm:px-6 lg:px-24 mb-14">
        <h2
          className="font-bold text-theme-color text-left mb-10"
          style={{ fontFamily: "Lato, sans-serif" }}
        >
          <span className="text-black">Projects </span>
        </h2>
        <p>No projects found matching your search criteria.</p>
      </section>
    );
  }

  return (
    <section className="px-4 sm:px-6 lg:px-24 mb-14">
      <div className="flex flex-col">
        <h2
          className="font-bold text-theme-color text-left mb-10"
          style={{ fontFamily: "Lato, sans-serif" }}
        >
          <span className="text-black">Projects </span>
        </h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          {projects.map((project) => (
            <div
              key={project.id}
              className="relative overflow-hidden bg-white rounded group"
            >
              <Link
                to={`/projects/project-details/${project.id}/`}
                className="block"
                rel="nofollow"
              >
                <div className="relative">
                  <img
                    src={project?.banner_image}
                    alt="projectimage"
                    className="w-full h-72 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
                  />
                  {/* Category name in top right corner */}
                  <span className="absolute top-2 right-2 text-white text-sm font-semibold z-10">
                    {project.category
                      ? project.category.charAt(0).toUpperCase() +
                        project.category.slice(1)
                      : ""}
                  </span>
                  <div className="absolute top-0 left-0 p-4 bg-gradient-to-b from-transparent to-black/70 w-full h-full flex flex-col justify-end">
                    <span
                      className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300"
                      style={{ fontFamily: "Lato, sans-serif" }}
                    >
                      {project?.project_name}
                    </span>
                    <span className="text-white text-sm">
                      {project?.city_name}, {project?.country_name}
                    </span>
                    <span className="text-white text-sm">
                      By {project?.company_name}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import CommercialImage from "../../../assets/images/Projects/commercials.jpg";
// import HealthcareImage from "../../../assets/images/Projects/healthcare.jpg";
// import RestaurantImage from "../../../assets/images/Projects/restaurant.jpg";
// import HospitalityImage from "../../../assets/images/Projects/hospitality.jpeg";

// const imageMap = {
//   Residential: CommercialImage,
//   Healthcare: HealthcareImage,
//   Restaurant: RestaurantImage,
//   Hospitality: HospitalityImage,
// };

// export const SearchResultsProjects = () => {
//   const [projects, setProjects] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const baseURL = process.env.REACT_APP_BASE_URL;

//   const projectCategory =
//     localStorage.getItem("projectCategory") || "Residential";

//   useEffect(() => {
//     const fetchProjects = async () => {
//       try {
//         const response = await axios.post(
//           `${baseURL}/api/projects/get_projects_by_category/${projectCategory}`,
//           {},
//           { headers: { "Content-Type": "application/json" } }
//         );
//         setProjects(response.data);
//       } catch (err) {
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProjects();
//   }, [projectCategory]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error loading projects: {error.message}</p>;

//   return (
//     <section className="px-4 sm:px-6 lg:px-24 mb-14">
//       <div className="flex flex-col">
//         <div className="flex justify-between items-baseline">
//           <h2
//             className="font-bold text-theme-color text-left mb-10"
//             style={{ fontFamily: "Lato, sans-serif" }}
//           >
//             <span className="text-black">Projects </span>
//           </h2>
//         </div>

//         <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
//           {projects.map((project) => (
//             <div
//               key={project.id}
//               className="relative overflow-hidden bg-white rounded group"
//             >
//               <a
//                 href={`/projects/project-details/${project.id}`}
//                 className="block"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <div className="relative">
//                   <img
//                     src={imageMap[project.category] || CommercialImage} // Fallback image
//                     alt="projectimage"
//                     className="w-full h-72 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
//                   />
//                   {/* Category name in top right corner */}
//                   <span className="absolute top-2 right-2 text-white text-sm font-semibold z-10">
//                     {project.category
//                       ? project.category.charAt(0).toUpperCase() +
//                         project.category.slice(1)
//                       : ""}
//                   </span>
//                   <div className="absolute top-0 left-0 p-4 bg-gradient-to-b from-transparent to-black/70 w-full h-full flex flex-col justify-end">
//                     <span
//                       className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300"
//                       style={{ fontFamily: "Lato, sans-serif" }}
//                     >
//                       {project.project_name || "Project Name"}
//                     </span>
//                     <span className="text-white text-sm">
//                       {project.city_name || "City Name"},{" "}
//                       {project.country_name || "Country Name"}
//                     </span>
//                     <span className="text-white text-sm">
//                       By {project.project_management_consultant || "Unknown"}
//                     </span>
//                   </div>
//                 </div>
//               </a>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };