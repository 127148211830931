import React, { useState } from "react";
import { Send, MessageCircle, PlusCircle } from "lucide-react";
import { Layout } from "../../components/layout/layout";

const INITIAL_QUERIES = [
  {
    id: 1,
    user: {
      name: "Architecture Innovations",
      logo: "/api/placeholder/50/50",
    },
    question:
      "What are the latest sustainable architectural trends for urban development?",
    replies: [
      {
        id: 101,
        user: {
          name: "Professional Architect",
          logo: "/api/placeholder/40/40",
        },
        text: "Green roofs and vertical gardens are becoming increasingly popular in urban settings.",
        timestamp: new Date(),
      },
    ],
  },
];

export const GoatArchForum = () => {
  const [queries, setQueries] = useState(INITIAL_QUERIES);
  const [newQuery, setNewQuery] = useState("");
  const [activeReplies, setActiveReplies] = useState({});
  const [replyTexts, setReplyTexts] = useState({});

  const handleQuerySubmit = (e) => {
    e.preventDefault();

    if (!newQuery.trim()) {
      return;
    }

    const newQueryObj = {
      id: Date.now(),
      user: {
        name: "Anonymous User",
        logo: "/api/placeholder/50/50",
      },
      question: newQuery,
      replies: [],
      timestamp: new Date(),
    };

    setQueries([newQueryObj, ...queries]);
    setNewQuery("");
  };

  const toggleReplies = (queryId) => {
    setActiveReplies((prev) => ({
      ...prev,
      [queryId]: !prev[queryId],
    }));
  };

  const handleReplySubmit = (queryId) => {
    const replyText = replyTexts[queryId];

    console.log("Reply Text:", replyText);
    console.log("Query ID:", queryId);
    console.log("Current Queries:", queries);

    if (!replyText || !replyText.trim()) return;

    const newReply = {
      id: Date.now(),
      user: {
        name: "Community Expert",
        logo: "/api/placeholder/40/40",
      },
      text: replyText,
      timestamp: new Date(),
    };

    const updatedQueries = queries.map((query) => {
      console.log("Processing Query:", query);
      if (query.id === queryId) {
        const updatedQuery = {
          ...query,
          replies: [...query.replies, newReply],
        };
        console.log("Updated Query:", updatedQuery);
        return updatedQuery;
      }
      return query;
    });

    console.log("Updated Queries:", updatedQueries);

    setQueries(updatedQueries);
    setReplyTexts((prev) => ({ ...prev, [queryId]: "" }));
    setActiveReplies((prev) => ({ ...prev, [queryId]: false }));
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Layout headerType="2">
      <div className="min-h-screen bg-theme-light-bg p-6 mt-20">
        <div className="max-w-2xl mx-auto space-y-6">
          <div className="bg-black shadow-lg rounded-2xl p-6 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <PlusCircle className="text-theme-color" size={32} />
              <h1 className="text-3xl font-bold text-white">GoatArch Forum</h1>
            </div>
          </div>

          {/* Query Submission */}
          <form
            onSubmit={handleQuerySubmit}
            className="bg-white rounded-2xl shadow-md overflow-hidden"
          >
            <textarea
              value={newQuery}
              onChange={(e) => setNewQuery(e.target.value)}
              placeholder="What architectural challenge are you facing today?"
              className="w-full p-4 text-gray-700 border-none focus:outline-none resize-none focus:border-b focus:border-b-theme-color  focus:ring-2 focus:ring-theme-color"
              rows="4"
            />
            <div className="bg-gray-50 p-4 flex justify-end">
              <button
                type="submit"
                className="bg-theme-color text-white px-6 py-2 rounded-lg 
              hover:bg-black transition flex items-center space-x-2"
              >
                <Send size={18} />
                <span>Post Query</span>
              </button>
            </div>
          </form>

          {/* Queries List */}
          <div className="space-y-6">
            {queries.map((query) => (
              <div
                key={query.id}
                className="bg-white rounded-2xl shadow-md overflow-hidden"
              >
                {/* Query Header */}
                <div className="bg-black p-4 flex justify-between items-center">
                  <div className="flex items-center space-x-3">
                    <img
                      src={query.user.logo}
                      alt={query.user.name}
                      className="w-10 h-10 rounded-full border-2 border-theme-color"
                    />
                    <div>
                      <span className="font-semibold text-white  block">
                        {query.user.name}
                      </span>
                      <span className="text-xs text-white">
                        {formatTimestamp(query.timestamp)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Query Content */}
                <div className="p-6">
                  <p className="text-black text-lg">{query.question}</p>
                </div>

                {/* Replies Section */}
                <div className="bg-gray-50 p-4">
                  <div
                    onClick={() => toggleReplies(query.id)}
                    className="flex items-center space-x-2 cursor-pointer hover:bg-gray-100 p-2 rounded-lg transition"
                  >
                    <MessageCircle size={20} className="text-theme-color" />
                    <span className="font-semibold text-gray-700">
                      Replies ({query.replies.length})
                    </span>
                  </div>

                  {activeReplies[query.id] && (
                    <div className="mt-4 space-y-4">
                      {/* Existing Replies */}
                      {query.replies.map((reply) => (
                        <div
                          key={reply.id}
                          className="bg-white p-4 rounded-lg shadow-sm"
                        >
                          <div className="flex items-center space-x-3 mb-2">
                            <img
                              src={reply.user.logo}
                              alt={reply.user.name}
                              className="w-8 h-8 rounded-full"
                            />
                            <div>
                              <span className="font-semibold text-gray-800 block">
                                {reply.user.name}
                              </span>
                              <span className="text-xs text-gray-500">
                                {formatTimestamp(reply.timestamp)}
                              </span>
                            </div>
                          </div>
                          <p className="text-gray-700">{reply.text}</p>
                        </div>
                      ))}

                      {/* Reply Input */}
                      <div className="flex space-x-3 items-end">
                        <textarea
                          value={replyTexts[query.id] || ""}
                          onChange={(e) =>
                            setReplyTexts((prev) => ({
                              ...prev,
                              [query.id]: e.target.value,
                            }))
                          }
                          placeholder="Write a reply..."
                          className="w-full p-3 border focus:border-0 rounded-lg focus:outline-none focus:ring-2 focus:ring-theme-color"
                          rows="3"
                        />
                        <button
                          onClick={() => handleReplySubmit(query.id)}
                          className="bg-black text-white p-3 rounded-lg hover:bg-theme-color transition h-fit"
                        >
                          <Send size={20} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GoatArchForum;
