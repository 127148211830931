import React, { useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import EnquiryForm from "../../../../../components/common/Enquiry Form";
import { useLocation } from "react-router-dom";

const ProductDetailsSectionTwo = ({ product }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, [location]);

  const handleDownload = async (fileUrls) => {
    if (Array.isArray(fileUrls) && fileUrls.length > 0) {
      let validFilesFound = false;

      for (const fileUrl of fileUrls) {
        if (typeof fileUrl === "string" && fileUrl) {
          validFilesFound = true;
          try {
            const response = await fetch(fileUrl, {
              mode: "cors", // Ensure CORS is handled
            });

            if (!response.ok) {
              throw new Error(
                `Failed to fetch ${fileUrl}: ${response.statusText}`
              );
            }

            const blob = await response.blob();
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            const fileName = fileUrl.split("/").pop();

            link.href = url;
            link.setAttribute("download", fileName || "download");
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL after download
            URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error downloading the file:", error);
          }
        } else {
          console.error("Invalid file URL:", fileUrl);
        }
      }

      if (!validFilesFound) {
        alert("Nothing to download.");
      }
    } else {
      alert("Nothing to download.");
    }
  };

  return (
    <section className="relative">
      <div className="lg:px-24 px-6 py-12">
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="lg:w-3/4">
            {/* Product Description */}
            <div className="mb-8">
              <h3 className="font-semibold mb-4">Product Description</h3>
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: product?.description,
                }}
              />
              {/* <p className="text-black">
                {product?.description || "No description available"}
              </p> */}
            </div>

            {/* Application or Use */}
            <div className="mb-8">
              <h3 className="font-semibold mb-4">Application or Use</h3>
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: product?.application_of_uses,
                }}
              />
              {/* <p className="text-black">
                {product?.application_of_uses ||
                  "No application or use information available"}
              </p> */}
            </div>

            {/* Installation Video */}
            {product?.product_video_link && (
              <div className="mb-8">
                <h3 className="font-semibold mb-4">Installation Video</h3>
                <div className="relative pt-[56.25%]">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full rounded"
                    src={`https://www.youtube.com/embed/${product.product_video_link}`}
                    title="Installation Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>

          {/* Sidebar */}
          <div className="lg:w-1/4">
            <div className="bg-theme-light-bg p-6 rounded">
              {/* Sizes */}
              <div className="mb-6">
                <p className="text-lg font-semibold mb-4">Sizes</p>
                <ul className="space-y-2">
                  {Array.isArray(product?.sizes) && product.sizes.length > 0 ? (
                    product.sizes
                      .filter((size) => size.value !== "N/A") // Filter out sizes with value 'N/A'
                      .map((size, index) => (
                        <li
                          key={index}
                          className="flex flex-col sm:flex-row text-sm"
                        >
                          {size.value} {/* Display the size value */}
                        </li>
                      ))
                  ) : (
                    <li>No sizes available</li>
                  )}
                </ul>
              </div>

              {/* Certifications */}
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-4">Downloads</h3>
                <div className="flex flex-wrap gap-2">
                  {Array.isArray(product?.product_certifications) &&
                  product.product_certifications.length > 0 ? (
                    product.product_certifications.map((cert, index) => (
                      <button
                        key={index}
                        onClick={() => handleDownload([cert])}
                        className="flex items-center gap-2 bg-theme-color text-black px-4 py-2 rounded hover:bg-black hover:text-theme-color transition"
                      >
                        <FiDownload className="w-4 h-4" />
                        <span className="text-sm">Green Guard Certificate</span>
                      </button>
                    ))
                  ) : (
                    <p>No certifications available</p>
                  )}
                  {Array.isArray(product?.product_documents) &&
                  product.product_documents.length > 0 ? (
                    product.product_documents.map((document, index) => (
                      <button
                        key={index}
                        onClick={() => handleDownload([document])}
                        className="flex items-center gap-2 bg-theme-color text-black px-4 py-2 rounded hover:bg-black hover:text-theme-color transition"
                      >
                        <FiDownload className="w-4 h-4" />
                        <span className="text-sm">Download Data Sheet</span>
                      </button>
                    ))
                  ) : (
                    <p>No downloads available</p>
                  )}
                </div>
              </div>

              {/* Inquiry Form */}
              {/* <div>
                <h3 className="text-lg font-semibold mb-4">Inquiry Form</h3>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <Input
                    name="name"
                    control={control}
                    rules={{ required: "Name is required" }}
                    label="Name"
                    placeholder="Enter your name"
                  />
                  <Input
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    label="Email"
                    type="email"
                    placeholder="Enter your email"
                  />
                  <Input
                    name="message"
                    control={control}
                    rules={{ required: "Message is required" }}
                    label="Message"
                    type="textarea"
                    placeholder="Enter your message"
                    rows={4}
                  />
                  <div className="flex justify-start">
                    <button
                      type="submit"
                      className="bg-theme-color text-black py-2 px-6 rounded hover:bg-black hover:text-theme-color transition"
                    >
                      Send Inquiry
                    </button>
                  </div>
                </form>
                {successMessage && (
                  <div className="mt-4 p-2 bg-green-100 text-green-700 rounded">
                    {successMessage}
                  </div>
                )}
              </div> */}
              <EnquiryForm
                formId={"product-enquiry-form"}
                baseURL={process.env.REACT_APP_BASE_URL}
                recipientEmail={product?.company_email}
                formTitle={"Send Enquiry for this product"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetailsSectionTwo;
