import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { ProjectVideo } from "../Video";
import EnquiryForm from "../../../../components/common/Enquiry Form";
import {
  // ImageGalleryStyleTwo,
  Input,
  UniversalSocialShare,
  ImageDisplay,
} from "../../../../components/shared";

export const ProjectPageDetails = ({ project }) => {
  // console.log("project Page", project);
  // const baseURL = process.env.REACT_APP_BASE_URL;

  // const onSubmit = async (data) => {
  //   console.log(data);
  //   const { name, phone, email } = data;

  //   const requestData = {
  //     name: name,
  //     number: phone,
  //     recipientEmail: project?.company_email,
  //     senderEmail: email,
  //   };

  //   try {
  //     const response = await axios.post(`${baseURL}/api/enquiry`, requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     console.log(response.data);
  //     setSuccessMessage("Your enquiry has been submitted successfully!");
  //     reset();

  //     setTimeout(() => setSuccessMessage(""), 2000);
  //   } catch (error) {
  //     console.error("Error submitting enquiry:", error);
  //     setSuccessMessage("Failed to submit enquiry. Please try again.");
  //     setTimeout(() => setSuccessMessage(""), 2000);
  //   }
  // };

  const projectDetails = [
    { label: "Architect", value: project?.company_name, highlight: true },
    {
      label: "Location",
      value: project?.city_name + ", " + project?.country_name,
    },
    {
      label: "Project Category",
      value: project?.category + ", " + project?.sub_category,
    },
    { label: "Year of Completion", value: project?.year_of_completion },
    {
      label: "Area",
      value: `${Math.floor(project?.total_area)} sq.m`,
      highlight: true,
    },
  ];

  const additionalDetails = [
    {
      label: "Project Management Consultant",
      value: project?.project_management_consultant,
    },
    { label: "Lighting", value: project?.lighting },
    { label: "Acoustic Consultant", value: project?.acoustic_consultant },
    {
      label: "Structural Or Engineering Consultant",
      value: project?.structural_engineering_consultant,
    },
    { label: "AV and IT", value: project?.av_it },
    {
      label: "Contractor or Fitout contractor",
      value: project?.contractor_fitout_contractor,
    },
    { label: "MEP Contractors", value: project?.mep_contractors },
    { label: "Joinery", value: project?.joinery },
    { label: "Foundation and Piling", value: project?.foundation_and_piling },
  ];

  return (
    <div className="flex flex-col lg:flex-row justify-between gap-8">
      <div className="w-full lg:w-3/4">
        <div className="flex justify-between border-b border-gray-lighter pb-6 md:pb-8 2xl:pb-10">
          <div>
            <h1 className="capitalize">
              {project?.project_name}
              {/* — {project?.city_name} */}
              {/* {project?.year_of_completion} */}
            </h1>
            <p className="text-black">
              By
              <Link
                to={`/company-profile/${project?.user_id}-${
                  project?.company_name
                    ? project.company_name.replace(/\s+/g, "-")
                    : ""
                }/`}
                // target="_blank"
                rel="noopener noreferrer"
                className="mb-4"
              >
                {" "}
                {project?.company_name}
              </Link>
              , {project?.city_name}
            </p>
          </div>
          <div className="relative">
            <div className="mt-1 flex items-center gap-3 bg-white md:flex 3xl:gap-6">
              <div className="relative">
                <UniversalSocialShare />
              </div>
              <button
                type="button"
                className="inline-flex font-medium items-center justify-center focus:outline-none transition duration-200 active:scale-90 px-2.5 py-1 text-xs rounded-full bg-transparent border border-gray-300 hover:enabled:border-gray-1000 focus:enabled:border-gray-1000 focus:ring-gray-900/30 !border-none !bg-gray-lightest !p-4 text-gray-dark hover:!bg-gray-dark hover:bg-theme-color"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-auto w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <section className="py-5 lg:py-6 xl:py-7">
          <div dangerouslySetInnerHTML={{ __html: project?.description }} />
        </section>
        {/* for concept */}
        {project?.project_concept && project.project_concept.trim() !== "" && (
          <section className="py-5 lg:py-6 xl:py-7">
            <h2 className="text-2xl font-bold mb-4">Project Concept</h2>
            <div
              dangerouslySetInnerHTML={{ __html: project?.project_concept }}
            />
          </section>
        )}
        {/* for challenges */}
        {project?.project_challenge &&
          project.project_challenge.trim() !== "" && (
            <section className="py-5 lg:py-6 xl:py-7">
              <h2 className="text-2xl font-bold mb-4">Project Challenges</h2>
              <div
                dangerouslySetInnerHTML={{ __html: project?.project_challenge }}
              />
            </section>
          )}
        <section className="py-5 lg:py-6 xl:py-7">
          <h2 className="text-2xl font-bold mb-6">Collaboration Companies</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {additionalDetails
              .filter((detail) => detail.value !== "N/A" && detail.value)
              .map((detail, index) => (
                <div
                  key={index}
                  className="bg-gray-50 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300"
                >
                  <h3 className="text-sm font-semibold text-gray-600 mb-2">
                    {detail.label}
                  </h3>
                  <p className="text-base text-gray-800">{detail.value}</p>
                </div>
              ))}
            {additionalDetails.filter(
              (detail) => detail.value !== "N/A" && detail.value
            ).length === 0 && <p>No valid additional details available</p>}
          </div>
        </section>
        <div className="">
          <ImageDisplay images={project?.project_image} />
        </div>
      </div>
      {/* Project Sidebar */}
      <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
        <div className="rounded-lg shadow-lg bg-white overflow-hidden border border-gray-200">
          {/* Project header */}
          <div className="bg-theme-color p-6 text-white relative overflow-hidden">
            <div className="absolute top-0 right-0 w-24 h-24 bg-white opacity-10 rounded-full transform translate-x-1/3 -translate-y-1/3"></div>
            <div className="absolute bottom-0 left-0 w-16 h-16 bg-white opacity-10 rounded-full transform -translate-x-1/3 translate-y-1/3"></div>
            <div className="flex items-center mb-4 relative z-10">
              <img
                src={project?.company_logo_image}
                alt="Company Logo"
                className="w-20 h-20 object-contain rounded-full bg-white p-1 mr-4 shadow-md"
              />
              <div>
                <h2 className="text-xl md:text-2xl font-bold capitalize">
                  <Link
                    to={`/company-profile/${project?.user_id}-${
                      project?.company_name
                        ? project.company_name.replace(/\s+/g, "-")
                        : ""
                    }/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-4"
                  >
                    {project?.company_name}
                  </Link>
                </h2>
                <p className="text-sm md:text-base opacity-90">
                  {project?.primary_company_type}
                </p>
              </div>
            </div>
          </div>

          {/* Project details */}
          <div className="p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4 pb-2 border-b-2 border-theme-color inline-block">
              Project Details
            </h3>
            <ul className="space-y-3">
              {projectDetails.map((detail, index) => (
                <li
                  key={index}
                  className="flex flex-col py-2 border-b border-gray-200 last:border-b-0 hover:bg-gray-50 transition duration-300"
                >
                  <span className="text-sm text-gray-600 mb-1">
                    {detail.label}
                  </span>
                  <span
                    className={`text-sm font-semibold ${
                      detail.highlight ? "text-theme-color" : "text-black"
                    } break-words`}
                  >
                    {detail.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact form */}
          {/* <div className="p-6 bg-white">
            <h3 className="font-semibold mb-4 text-lg text-gray-800 pb-2 border-b-2 border-theme-color">
              Contact for your next project
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <Input
                  name="name"
                  control={control}
                  rules={{ required: "Name is required" }}
                  label="Name"
                  placeholder="Enter your name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-theme-color focus:border-transparent"
                />
              </div>
              <div className="mb-4">
                <Input
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  label="Email"
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-theme-color focus:border-transparent"
                />
              </div>
              <div className="mb-4">
                <Input
                  name="phone"
                  control={control}
                  rules={{ required: "Phone number is required" }}
                  label="Phone Number"
                  placeholder="Enter your contact number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-theme-color focus:border-transparent"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-theme-color text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300 shadow-md"
              >
                Enquire Now
              </button>
            </form>
            {successMessage && (
              <p className="text-green-600 mt-2 text-center text-sm bg-green-50 p-2 rounded-md">
                {successMessage}
              </p>
            )}
          </div> */}

          <EnquiryForm
            recipientEmail={project?.company_email}
            baseURL={process.env.REACT_APP_BASE_URL}
            formTitle={"Send Enquiry for more projects"}
          />
        </div>
        {project?.video_links === null || project?.video_links === "" ? (
          ""
        ) : (
          <div className="mt-6">
            <h3 className="font-semibold mb-4 text-lg text-gray-800 pb-2 border-b-2 border-theme-color inline-block capitalize">
              Project Video
            </h3>
            <ProjectVideo video={project?.video_links} />
          </div>
        )}
      </div>
      {/* Project sidebar end */}
    </div>
  );
};

// const ImageDisplay = ({ images }) => {
//   return (
//     <div className="mt-6 space-y-6">
//       {images &&
//         images.map((image, index) => (
//           <img
//             key={index}
//             src={image}
//             alt={`Project work ${index + 1}`}
//             className="h-[250px] lg:h-[750px] w-full object-cover"
//           />
//         ))}
//     </div>
//   );
// };

// export default ImageDisplay;
