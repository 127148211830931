import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  CreateProjectStepOne,
  CreateProjectStepThree,
  CreateProjectStepFour,
} from "./Steps";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import { Helmet } from "react-helmet";
import { Loader, Popup, SiteLogo } from "../../../components/shared";

const pageVariants = {
  initial: { opacity: 0, y: 20, scale: 0.95 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { type: "spring", stiffness: 100, damping: 20 },
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.95,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
};

const steps = [
  { title: "Project Details", component: CreateProjectStepOne },
  { title: "Company Involved", component: CreateProjectStepThree },
  { title: "Project Media", component: CreateProjectStepFour },
];

export const CreateProject = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("authToken");

  const [step, setStep] = useState(0);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(data);
    setIsLoading(true);
    setIsPopupOpen(true);

    const formData = new FormData();
    if (data.UploadBannerImages && data.UploadBannerImages) {
      formData.append("banner_image", data.UploadBannerImages);
    }
    if (data.UploadProjectImages && data.UploadProjectImages.length > 0) {
      data.UploadProjectImages.forEach((image) => {
        formData.append("project_image", image);
      });
    }

    const projectData = {
      user_id: user_id,
      email: parsedData?.email,
      company_name: parsedData?.company_name,
      project_name: data.projectName,
      year_of_completion: data.yearOfCompletion,
      total_area: data.totalArea,
      country_name: data.country,
      state_name: data.state,
      city_name: data.city,
      category: data.category,
      sub_category: data.subCategory,
      description: data.description,
      project_management_consultant: data.projectManagementConsultant,
      lighting: data.lighting,
      acoustic_consultant: data.acousticConsultant,
      structural_engineering_consultant: data.structuralEngineeringConsultant,
      av_it: data.avIT,
      contractor_fitout_contractor: data.contractorFitout,
      mep_contractors: data.mepContractors,
      joinery: data.joinery,
      foundation_and_piling: data.foundationAndPiling,
      video_links: data.videoEmbedLinks,
      project_approved: 0,
    };

    formData.append("data", JSON.stringify(projectData));

    try {
      await axios.post(`${baseURL}/api/add_projects`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally, display an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const handleExploreProjects = () => {
    navigate("/projects");
  };

  useEffect(() => {
    let timer;
    if (isPopupOpen && !isLoading) {
      timer = setTimeout(() => {
        setIsPopupOpen(false);
        navigate("/projects/");
      }, 7000);
    }
    return () => clearTimeout(timer);
  }, [isPopupOpen, isLoading, navigate]);

  const validateStep = async (stepNumber) => {
    // console.log(`Validating step ${stepNumber}`);
    switch (stepNumber) {
      case 0:
        return await trigger([
          "projectName",
          "yearOfCompletion",
          "totalArea",
          "country",
          "city",
          "category",
          "subCategory",
          "description",
        ]);
      case 1:
        const isContractorValid = await trigger("contractorFitout");
        if (!isContractorValid) {
          // console.log("Contractor validation failed");
          return false;
        }
        return true;
      case 2:
        return await trigger(["uploadedFiles", "videoEmbedLinks"]);
      default:
        return false;
    }
  };

  const nextStep = async (event) => {
    event.preventDefault();
    const isValid = await validateStep(step);
    if (isValid) {
      if (step < steps.length - 1) {
        setStep(step + 1);
      } else if (step === steps.length - 1) {
        handleSubmit(onSubmit)();
      }
    } else {
      // console.log("Validation failed for step", step);
    }
  };

  const prevStep = () => {
    if (step > 0) setStep(step - 1);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Create New Project | GoatArch Design and Products</title>
      </Helmet>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Create New Project</h1>
        <div className="bg-white rounded lg:shadow-md lg:p-6">
          <div className="mb-6">
            <ul className="flex border-b">
              {steps.map((stepObj, index) => (
                <li key={index} className="-mb-px mr-1">
                  <span
                    className={`inline-block py-2 px-4 text-sm font-semibold ${
                      index === step
                        ? "text-theme-color border-b-2 border-theme-color"
                        : "text-gray-500 hover:text-gray-800 cursor-pointer"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (index < step) setStep(index);
                    }}
                  >
                    {stepObj.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={step}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageVariants}
              >
                {React.createElement(steps[step].component, {
                  control,
                  errors,
                })}
              </motion.div>
            </AnimatePresence>
            <div className="flex gap-2 justify-between mt-6">
              <button
                type="button"
                onClick={prevStep}
                disabled={step === 0}
                className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="w-[200px] text-white bg-black lg:bg-theme-color hover:bg-theme-color lg:hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
              >
                {step === steps.length - 1 ? "Submit" : "Next"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {isPopupOpen && (
        <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
          <div className="flex flex-col items-center justify-center w-auto lg:w-[500px] min-h-[300px] space-y-6">
            <SiteLogo className="w-32 md:w-40 lg:w-48" />
            {isLoading ? (
              <>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center">
                  Submitting...
                </h2>
                <Loader />
              </>
            ) : (
              <>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center">
                  Success!
                </h2>
                <p className="text-gray-600 text-base md:text-lg lg:text-xl text-center">
                  Your project is under review of administration. After
                  approval, you will receive a notification on your registered
                  email ID.
                </p>
                <p className="text-sm md:text-base lg:text-lg text-gray-500 text-center">
                  Until then, explore some other amazing projects in our
                  company.
                </p>
                <button
                  onClick={handleExploreProjects}
                  className="w-[200px] text-white bg-theme-color hover:bg-black transition-opacity duration-200 text-heading-6 font-chivo font-bold shadow-sm py-[13px]"
                >
                  Explore Other Projects
                </button>
              </>
            )}
          </div>
        </Popup>
      )}
    </DashboardLayout>
  );
};
