import React, { useState, useEffect } from "react";
import { Input } from "../../../../components/shared";
import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";

export const CompanyStepThree = ({ control }) => {
  // console.log(control._formValues);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [countryCode, setCountryCode] = useState("");

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    const country = countriesList.find((c) => c.name === countryName);
    setCountryid(country.id);
    setCountryCode(country.iso2);
    setStateid(0); // Reset state and city when country changes
    setCityid(0);
    setStateList([]); // Clear state and city lists
    setCityList([]);

    GetState(country.id).then((result) => {
      setStateList(result);
    });
    // console.log(`Selected Country Name: ${country.name}`);
  };

  const handleStateChange = (e) => {
    const stateName = e.target.value;
    const state = stateList.find((s) => s.name === stateName);
    setStateid(state.id);
    setCityid(0); // Reset city when state changes
    setCityList([]);

    GetCity(countryid, state.id).then((result) => {
      setCityList(result);
    });
    // console.log(`Selected State Name: ${state.name}`);
  };

  const handleCityChange = (e) => {
    const cityName = e.target.value;
    const city = cityList.find((c) => c.name === cityName);
    setCityid(city.id);

    // console.log(`Selected City Name: ${city.name}`);
  };

  return (
    <div className="space-y-6">
      <Input
        name="country"
        control={control}
        label="Country"
        type="select"
        options={countriesList.map((item) => ({
          value: item.name,
          label: item.name,
        }))}
        placeholder="Select a country"
        allowSearch={true}
        onChange={handleCountryChange}
        rules={{ required: "Country is required" }}
      />

      {stateList.length > 0 && ( // Only show the state input if there are states available
        <Input
          name="state"
          control={control}
          label="State"
          type="select"
          options={stateList.map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          placeholder="Select a state"
          allowSearch={true}
          onChange={handleStateChange}
          rules={{ required: "State is required" }}
        />
      )}

      {cityList.length > 0 && ( // Only show the city input if there are cities available
        <Input
          name="city"
          control={control}
          label="City"
          type="select"
          options={cityList.map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          placeholder="Select a city"
          allowSearch={true}
          onChange={handleCityChange}
          rules={{ required: "City is required" }}
        />
      )}

      {/* <Input
        name="zip"
        type="number"
        control={control}
        label="ZIP"
        placeholder="Enter ZIP Code"
      />
      <Input
        name="address"
        control={control}
        label="Address"
        placeholder="Enter Address"
        rules={{ required: "Address is required" }}
      /> */}
      <Input
        name="phone"
        control={control}
        type="tel"
        international
        label="Phone Number"
        placeholder="Enter your phone number"
        rules={{ required: "Phone is required" }}
        defaultCountry={countryCode}
      />
      <Input
        name="companyWebsite"
        control={control}
        label="Company Website (Optional)"
        placeholder="Enter Company Website"
        rules={{
          pattern: {
            value:
              /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{2,}\.[a-z]{2,}(\.[a-z]{2,})?(\/[a-zA-Z0-9#?&%=._-]*)*\/?$/,
            message: "Please enter a valid website URL",
          },
        }}
      />
    </div>
  );
};
