import { useState, useEffect } from "react";
import axios from "axios";
import { Layout } from "../../components/layout/layout";
import { FeatureProjects } from "./Feature/Projects";
import { FeatureProducts } from "./Feature/Products";
import { FeaturedCompanies } from "./Feature/Companies";
import { ProjectHeroSlider } from "./Project Slider";
import HomeAboutSection from "./About/about";
import { Articals } from "./News/news";
import ReactGA from "react-ga4";
import { SEO } from "../../components/shared";
import { GoatArchFeatures } from "./GoatArch Features";
export const Home = () => {
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]);

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    projectFetchData();
    productCompaniesData();
    productFetchData();
  }, []);

  // Projects
  const projectFetchData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseURL}/api/projects`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      const firstFourProjects = response.data.slice(0, 4);
      setProjects(firstFourProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  // Companies
  const productCompaniesData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseURL}/api/companies`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      const firstFourProjects = response.data.slice(0, 8);
      setCompanies(firstFourProjects);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Products
  const productFetchData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseURL}/api/products`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      const firstFourProjects = response.data.slice(0, 4);
      setProducts(firstFourProjects);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <Layout headerType="1">
      {/* SEO Section Start */}
      <SEO
        canonicalUrl="https://goatarch.com/"
        description="GoatArch - A global platform for architects, interior designers, and manufacturers to showcase their work. Discover innovative designs, products, and projects from top professionals."
        keywords={[
          "architecture platform",
          "interior design",
          "manufacturing",
          "design showcase",
          "professional designers",
          "architectural projects",
        ]}
        image="/images/home/home-share.jpg"
        structuredData={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "GoatArch Design and Products",
          url: "https://goatarch.com",
          potentialAction: {
            "@type": "SearchAction",
            target: "https://goatarch.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string",
          },
        }}
      />
      {/* SEO Section End */}
      <ProjectHeroSlider projects={projects} />
      <GoatArchFeatures />
      <HomeAboutSection />
      <FeaturedCompanies companies={companies} />
      <Articals />
      <FeatureProjects projects={projects} />
      <FeatureProducts products={products} />
    </Layout>
  );
};
