import axios from "axios";
import { Layout } from "../../components/layout/layout";
import ProductBannerImage from "../../assets/images/Products/productbannernew.jpg";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback, Fragment } from "react";
import {
  BreadcrumbComponentTwo,
  PhotoDisplay,
  SEO,
} from "../../components/shared";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import ProductPlaceholderImage from "../../assets/images/Placeholders/wowplaceholder.png";
import NODATAFOUND from "../../assets/images/Misc/not-found.gif";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  FaWhatsapp,
  FaTwitter,
  FaFacebook,
  FaEnvelope,
  FaMicrosoft,
} from "react-icons/fa";
import Fuse from "fuse.js";
import ProductPopup from "./Products Detail Popup";
import { EyeIcon, ShareIcon } from "lucide-react";

const categories = [
  {
    name: "All Products",
    subcategories: [],
  },
  {
    name: "Finishes",
    subcategories: [
      "False Ceilings",
      "Walls and Coverings",
      "Doors",
      "Paints & Coatings",
      "Roofing",
      "Windows",
      "Fittings",
      "Surfaces",
      "Blinds Curtains Awnings",
    ],
  },
  {
    name: "Acoustic",
    subcategories: [
      "Wall cladding",
      "Insulation",
      "Ceilings",
      "Flooring",
      "Fabrics",
      "Partitions",
    ],
  },
  {
    name: "Construction Materials",
    subcategories: [
      "Elevators Escalators Lifts",
      "Horizontal Transport",
      "Gates and Fences",
      "Hardware",
      "Materials",
      "Chemicals",
      "Stairs and Rails",
      "Insulation",
      "Roofing",
      "Paving and Decking",
      "Waterproofing",
      "Façade and Glazing",
      "Structural Frames",
      "Prefabricated Structures",
    ],
  },
  {
    name: "Features and Elements",
    subcategories: [
      "Fountains",
      "Fireplace Stoves BBQ",
      "Pods",
      "Sculptures and Objects",
      "Art and Prints",
      "Stabile",
      "Display Elements",
    ],
  },
  {
    name: "Furniture",
    subcategories: [
      "Beds",
      "Fittings",
      "Home Office",
      "Office Furniture",
      "Seating and Chairs",
      "Speciality Furniture",
      "Storage",
      "Tables",
    ],
  },
  {
    name: "Flooring",
    subcategories: [
      "Carpets and Rugs",
      "Concrete Floors",
      "Wooden Flooring",
      "Raised Floor",
      "Accessories and Covers",
      "Glass Flooring",
      "Hardwood Flooring",
      "Porcelain Floor",
      "Mosaic",
      "Luxury Vinyl Tiles",
      "Glass Flooring",
      "Laminate",
      "Rubber Flooring",
    ],
  },
  {
    name: "Bath and Sanitary",
    subcategories: [
      "Bathroom Furniture",
      "Faucets",
      "Shower",
      "Sinks",
      "Spa and Wellness",
      "Toilets Urinal",
      "Plumbing",
      "Bath Kitchens",
      "Bathroom Accessories",
    ],
  },
  {
    name: "Lighting and Fittings",
    subcategories: [
      "Urban Lighting",
      "Light Fittings",
      "Bulbs and Spots",
      "Wall Lights",
      "Floor Lights",
      "Speciality Lights",
      "Track Lighting",
      "Hidden Concealed Lighting",
    ],
  },
  {
    name: "AV and IT",
    subcategories: [
      "Appliances",
      "Multimedia Units",
      "Audio Video Devices",
      "Mobile Devices",
    ],
  },
  {
    name: "Equipment",
    subcategories: [
      "Electrical",
      "Food Service Equipment",
      "Kitchen Equipment",
      "Fitness",
      "Tech Gym",
    ],
  },
  {
    name: "Materials",
    subcategories: [
      "Glass",
      "Ceramics",
      "Concrete",
      "Bricks & Masonry",
      "Metal",
      "Textile",
      "Wood",
      "Stone",
      "Plastic and Fibers",
      "Stucco and Mortars",
    ],
  },
  {
    name: "Signage and Wayfinding",
    subcategories: [
      "Signage System",
      "Pictographs",
      "Plates",
      "Signage Digital Systems",
      "Wayfinding",
    ],
  },
  {
    name: "Landscape and Outdoor",
    subcategories: [
      "Planters",
      "Outdoor Seating",
      "Trash Cans and Bins",
      "Outdoor Furniture",
      "Fences",
      "Paving",
      "Shades",
    ],
  },
];

export const Products = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Products");
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  // console.log("filteredProducts", filteredProducts);

  const [isMobile, setIsMobile] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);
  const [productIdShare, setProductIdShare] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 1024);
  }, []);

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    filterProducts();
  }, [selectedCategory, selectedSubcategories, searchTerm, products]);

  const fetchData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseURL}/api/products`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      setProducts(response.data);
      setFilteredProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategories({});
    if (category === "All Products") {
      setSearchTerm("");
      setMobileFiltersOpen(false);
    } else {
      setOpenCategory(openCategory === category ? null : category);
    }
  };

  const handleSubcategoryToggle = (subcategory) => {
    setSelectedSubcategories((prev) => ({
      ...prev,
      [subcategory]: !prev[subcategory],
    }));
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.trim();
    setSearchTerm(term);
  };

  const filterProducts = () => {
    let filtered = products;

    if (selectedCategory && selectedCategory !== "All Products") {
      filtered = filtered.filter(
        (product) => product.category === selectedCategory
      );
    }

    const activeSubcategories = Object.entries(selectedSubcategories)
      .filter(([, isSelected]) => isSelected)
      .map(([subcategory]) => subcategory);

    if (activeSubcategories.length > 0) {
      filtered = filtered.filter((product) =>
        activeSubcategories.includes(product.subcategory)
      );
    }

    if (searchTerm) {
      const fuse = new Fuse(filtered, {
        keys: ["collection_name", "category", "subcategory", "manufacturer"],
        includeScore: true,
      });
      const results = fuse.search(searchTerm);
      filtered = results.map((result) => result.item);
    }

    setFilteredProducts(filtered);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const MobileFilters = () => (
    <div className="sticky top-0 bg-white z-10 p-4 shadow-md">
      <div className="flex items-center space-x-4">
        <input
          type="search"
          placeholder="Search products..."
          className="flex-grow p-2 border rounded"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button
          type="button"
          className="-m-2 ml-4 p-2 text-black hover:text-gray-500 sm:ml-6 lg:hidden"
          onClick={() => setMobileFiltersOpen(true)}
        >
          <span className="sr-only">Filters</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleShareOptions = () => {
    setIsOpen(!isOpen);
  };

  const shareOnSocialMedia = (platform, productID) => {
    let shareUrl = "";
    const baseUrl = window.location.origin;
    const encodedLink = encodeURIComponent(
      `${baseUrl}/products/products-details/${productID}`
    );

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodedLink}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedLink}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "gmail":
        shareUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=Check%20out%20this%20product&body=I%20found%20this%20product%20interesting:%20${encodedLink}`;
        break;
      case "outlook":
        shareUrl = `https://outlook.live.com/owa/?path=/mail/action/compose&to=&subject=Check%20out%20this%20product&body=I%20found%20this%20product%20interesting:%20${encodedLink}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };

  const productsPageShareURL =
    "https://goatarch.com/static/media/productbannernew.ee85da687cfbeca1c9b0.jpg";
  return (
    <Layout headerType="2">
      {/* SEO Section Start */}
      <SEO
        canonicalUrl="https://goatarch.com/material-library/"
        description="Browse a wide range of innovative products from top manufacturers on GoatArch. Explore detailed product listings and discover cutting-edge designs and solutions."
        title="Products"
        keywords={[
          "architectural products",
          "design products",
          "interior products",
          "manufacturing solutions",
          "building materials",
          "design materials",
        ]}
        image={productsPageShareURL}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: "Design & Architecture Products | GoatArch",
          description:
            "Browse a wide range of innovative products from top manufacturers on GoatArch. Explore detailed product listings and discover cutting-edge designs and solutions.",
          url: "https://goatarch.com/material-library/",
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          },
        }}
      />
      {/* SEO Section End */}
      <div className="mt-[4.6%]">
        <BreadcrumbComponentTwo
          title="Material Library"
          bgImage={ProductBannerImage}
        />
      </div>
      <div className="bg-white">
        <div>
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <span className="text-lg font-medium text-gray-900">
                        Filters
                      </span>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-4 border-t border-gray-200">
                      {categories.map((category) => (
                        <Disclosure
                          as="div"
                          key={category.name}
                          className="border-t border-gray-200 px-4 py-6"
                          defaultOpen={openCategory === category.name}
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button
                                  className={`flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500 ${
                                    selectedCategory === category.name
                                      ? "text-theme-color"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleCategorySelect(category.name)
                                  }
                                >
                                  <span className="font-medium text-gray-900">
                                    {category.name}
                                  </span>
                                  {category.subcategories.length > 0 && (
                                    <span className="ml-6 flex items-center">
                                      {open ? (
                                        <MinusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <PlusIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </span>
                                  )}
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  {category.subcategories.map((subcategory) => (
                                    <div
                                      key={subcategory}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-mobile-${category.name}-${subcategory}`}
                                        name={`${category.name}[]`}
                                        value={subcategory}
                                        type="checkbox"
                                        checked={
                                          selectedSubcategories[subcategory] ||
                                          false
                                        }
                                        onChange={() =>
                                          handleSubcategoryToggle(subcategory)
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${category.name}-${subcategory}`}
                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                      >
                                        {subcategory}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="mx-auto px-4 sm:px-6 lg:px-24">
            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                <div className="hidden lg:block">
                  <h3 className="sr-only">Categories</h3>
                  <div>
                    <div className="relative my-2 ">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded bg-gray-50"
                        placeholder="Search Category, Name..."
                        required=""
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <div className="border border-gray-200 rounded p-4">
                    {categories.map((category) => (
                      <Disclosure key={category.name} as="div" className="mb-2">
                        {({ open }) => (
                          <div>
                            <Disclosure.Button
                              className="flex w-full justify-between rounded bg-[#FBF5EE] px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-theme-color hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                              onClick={() =>
                                handleCategorySelect(category.name)
                              }
                            >
                              <span>{category.name}</span>
                              {category.name !== "All Products" && (
                                <ChevronDownIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-gray-500`}
                                />
                              )}
                            </Disclosure.Button>
                            {category.name !== "All Products" && (
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                {category.subcategories.map((subcategory) => (
                                  <div
                                    key={subcategory}
                                    className="flex items-center mb-2"
                                  >
                                    <input
                                      id={`filter-${category.name}-${subcategory}`}
                                      name={`${category.name}[]`}
                                      value={subcategory}
                                      type="checkbox"
                                      checked={
                                        selectedSubcategories[subcategory] ||
                                        false
                                      }
                                      onChange={() =>
                                        handleSubcategoryToggle(subcategory)
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-${category.name}-${subcategory}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {subcategory}
                                    </label>
                                  </div>
                                ))}
                              </Disclosure.Panel>
                            )}
                          </div>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                  <div className="mt-6 bg-black border border-gray-200 rounded-lg shadow">
                    <Link
                      to="/dashboard/create-products"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PhotoDisplay className="rounded" photoId="jn7uVeCdf6U" />
                    </Link>
                    <div className="p-5">
                      <Link
                        to="/dashboard/create-products"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="text-2xl font-bold tracking-tight text-white">
                          List Your Product
                        </p>
                      </Link>
                      <h6 className="text-sm mt-1 text-white">
                        Reach a global audience of designers
                      </h6>
                      <p className="my-3 font-normal text-white">
                        Join our marketplace of innovative design products.
                        Showcase your creations to architects and designers
                        worldwide!
                      </p>
                      <Link
                        to="/dashboard/create-products"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black hover:text-white bg-theme-color rounded hover:bg-transparent border-theme-color border-2 hover:border-white"
                      >
                        Showcase Your Product
                        <svg
                          className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-3">
                  {isMobile && <MobileFilters />}
                  {filteredProducts.length > 0 ? (
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                      {filteredProducts.map((product) => (
                        <div className="group relative overflow-hidden rounded shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer">
                          <Link
                            to={`/products/products-details/${
                              product.id
                            }-${product.product_name.replace(/\s+/g, "-")}/`}
                            rel="noopener noreferrer"
                          >
                            <div className="relative h-80">
                              <img
                                src={
                                  product.product_banner_image ||
                                  ProductPlaceholderImage
                                }
                                className="w-full h-full object-cover"
                              />
                              <div className="absolute inset-0 bg-black/30 group-hover:bg-black/40 transition-colors duration-300" />
                            </div>
                          </Link>

                          <Link
                            to={`/products/products-details/${product.id}`}
                            rel="noopener noreferrer"
                          >
                            <div className="absolute bottom-4 left-4 right-4">
                              <span className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300">
                                {product.product_name}
                              </span>
                              <p className="text-white text-sm">
                                By {product.company_name}
                              </p>
                              <p className="text-white text-sm">
                                {product.city}, {product.country}
                              </p>
                            </div>
                          </Link>
                          <div className="absolute top-4 right-4 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <div className="relative">
                              <button
                                className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                                onClick={(e) => {
                                  handleProductClick(product);
                                }}
                              >
                                <EyeIcon className="h-5 w-5 text-gray-800" />
                              </button>
                              <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
                                <div className="bg-black/80 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Quick View
                                </div>
                              </div>
                            </div>
                            <div className="relative">
                              <button
                                className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                                onClick={toggleShareOptions}
                              >
                                <ShareIcon className="h-5 w-5 text-gray-800" />
                              </button>
                              {isOpen && (
                                <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 bg-black/80 text-white text-xs rounded py-1 px-2">
                                  <div className="flex space-x-2">
                                    <button
                                      onClick={() =>
                                        shareOnSocialMedia(
                                          "whatsapp",
                                          product.id
                                        )
                                      }
                                    >
                                      <FaWhatsapp className="h-5 w-5" />
                                    </button>
                                    <button
                                      onClick={() =>
                                        shareOnSocialMedia(
                                          "twitter",
                                          product.id
                                        )
                                      }
                                    >
                                      <FaTwitter className="h-5 w-5" />
                                    </button>
                                    <button
                                      onClick={() =>
                                        shareOnSocialMedia(
                                          "facebook",
                                          product.id
                                        )
                                      }
                                    >
                                      <FaFacebook className="h-5 w-5" />
                                    </button>
                                    <button
                                      onClick={() =>
                                        shareOnSocialMedia("gmail", product.id)
                                      }
                                    >
                                      <FaEnvelope className="h-5 w-5" />{" "}
                                      {/* Gmail icon */}
                                    </button>
                                    <button
                                      onClick={() =>
                                        shareOnSocialMedia(
                                          "outlook",
                                          product.id
                                        )
                                      }
                                    >
                                      <FaMicrosoft className="h-5 w-5" />{" "}
                                      {/* Use a suitable icon for Outlook */}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="absolute top-4 left-4 text-white px-3 py-1 rounded-full text-sm font-medium">
                            <b>{product.category}</b>
                            <p className="text-white text-sm">
                              {product.sub_category}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                      <img
                        src={NODATAFOUND}
                        alt="No Data Found"
                        className="max-w-xs w-full mb-4"
                      />
                      <p className="text-xl font-semibold text-black">
                        No projects found for the selected category.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          isOpen={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </Layout>
  );
};
