import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { PhotoDisplay, Input } from "../../../components/shared";
import { EyeIcon, ShareIcon } from "lucide-react";
import ProductPopup from "../../Products/Products Detail Popup";
import { useForm } from "react-hook-form"; // Make sure to install react-hook-form

export const CompanyProducts = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const { control } = useForm();
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleProductClick = (product) => {
    // Navigate to the product detail page
    navigate(`/products/products-details/${product.id}-${product?.product_name.replace(/\s+/g, '-')}/`);
  };

  const handleEyeIconClick = (event, product) => {
    // Prevent click event from propagating to the card
    event.stopPropagation();
    setSelectedProduct(product); // Show the popup
  };

  const categories = [...new Set(products?.map((product) => product.category))];
  const collections = [
    ...new Set(products?.flatMap((product) => product.collection_name)),
  ];

  const categoryOptions = categories.map((category) => ({
    value: category,
    label: category,
  }));
  const collectionOptions = collections.map((collection) => ({
    value: collection,
    label: collection,
  }));

  const handleFilterChange = (filterType, value) => {
    setFilteredProducts(
      products?.filter((product) => {
        if (filterType === "category") {
          return value ? product.category === value : true;
        } else if (filterType === "collection") {
          return value ? product.collection_name === value : true;
        }
        return true;
      })
    );
  };

  return (
    <section className="mt-10">
      <div className="flex flex-col">
        <div className="flex justify-between items-baseline my-10">
          <h3
            className="font-bold text-black text-left"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            Products
          </h3>
          <a href="#tabtop" className="text-theme-color font-bold">
            Go to top
          </a>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Input
            name="categoryFilter"
            control={control}
            type="select"
            label="Filter by Category"
            options={[
              { value: "", label: "All Categories" },
              ...categoryOptions,
            ]}
            onChange={(e) => handleFilterChange("category", e.target.value)}
            className="w-full"
          />
          <Input
            name="collectionFilter"
            control={control}
            type="select"
            label="Filter by Collection"
            options={[
              { value: "", label: "All Collections" },
              ...collectionOptions,
            ]}
            onChange={(e) => handleFilterChange("collection", e.target.value)}
            className="w-full"
          />
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {filteredProducts?.length > 0 ? (
            filteredProducts.map((product) => (
              <div
                key={product.id}
                className="group relative overflow-hidden rounded shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer"
                onClick={() => handleProductClick(product)} // Navigate on card click
              >
                <div className="relative h-80">
                  <img
                    src={product.product_banner_image} // Updated property name
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/30 group-hover:bg-black/40 transition-colors duration-300" />
                </div>

                <div className="absolute bottom-4 left-4 right-4">
                  <span className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300">
                    {product.product_name} {/* Updated property name */}
                  </span>
                  <p className="text-white text-sm">{product.company_name}</p> {/* Updated property name */}
                </div>

                <div className="absolute top-4 right-4 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div className="relative">
                    <button
                      onClick={(e) => handleEyeIconClick(e, product)} // Show popup on eye icon click
                      className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    >
                      <EyeIcon className="h-5 w-5 text-gray-800" />
                    </button>
                    <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
                      <div className="bg-black/80 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                        Quick View
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <button className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200">
                      <ShareIcon className="h-5 w-5 text-gray-800" />
                    </button>
                    <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
                      <div className="bg-black/80 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                        Share Product
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute top-4 left-4 text-white px-3 py-1 rounded-full text-sm font-medium">
                  {product.category}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-lg font-bold text-gray-500">
              No products found
            </div>
          )}
        </div>
      </div>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          isOpen={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </section>
  );
};
