import React from "react";
import { Input } from "../../../../components/shared";

// const businessTypes = ["LLP", "Sole Proprietorship", "Corporation", "Partnership"];
const businessTypes = ["LLP", "Corporation", "Partnership"];

export const CompanyStepSix = ({ control }) => {
  return (
    <div className="space-y-6">
      <Input
        name="businessType"
        control={control}
        type="select"
        label="Business Type"
        options={businessTypes.map(type => ({ value: type, label: type }))}
      />
      <Input
        name="numberOfEmployees"
        control={control}
        type="number"
        label="Number of Employees"
        placeholder="Enter Number of Employees"
      />
      <Input
        type="number"
        name="revenue"
        control={control}
        label="Revenue"
        placeholder="Enter Revenue"
      />
    </div>
  );
};