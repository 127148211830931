import { Layout } from "../../components/layout/layout";
import { AboutSectionOne } from "./Section1/section1";
import { AboutSectionThree } from "./Section3/section3";
import { AboutSectionFour } from "./Section4/section4";
import { SEO } from "../../components/shared";
// import ProcessImage from "./Section 6";
import ModernProcessFlow from "./Section 6";
// import ProcessStaticImage from "../../assets/images/About/WhatsApp Image 2024-11-21 at 7.54.46 PM.jpeg";

export const AboutCompany = () => {
  const shareImageUrl =
    "https://images.unsplash.com/photo-1531702275836-8a2922d78491?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w2NDA3NzN8MHwxfGFsbHx8fHx8fHx8fDE3Mjk3MDA5NzJ8&ixlib=rb-4.0.3&q=80&w=1080";
  return (
    <Layout headerType="2">
      {/* SEO Section */}
      <SEO
        canonicalUrl="https://goatarch.com/about-us/"
        description="GoatArch connects architects, interior designers, and manufacturers with a worldwide audience. Learn about our mission to inspire creativity and innovation in the design industry."
        title="About Us"
        image={shareImageUrl}
        keywords={[
          "about goatarch",
          "architecture",
          "design",
          "interior design",
        ]}
        structuredData={{
          "@context": "https://schema.org",
          "@type": "AboutPage",
          name: "About GoatArch Design and Products",
          description:
            "GoatArch connects architects, interior designers, and manufacturers with a worldwide audience. Learn about our mission to inspire creativity and innovation in the design industry.",
          url: "https://goatarch.com/about-us/",
          image: shareImageUrl,
          publisher: {
            "@type": "Organization",
            name: "GoatArch Design and Products",
            logo: {
              "@type": "ImageObject",
              url: "https://goatarch.com/static/media/GA-Logo-black.4981b095c168dcca95cb.png",
            },
          },
        }}
      />
      {/* SEO Section */}
      <AboutSectionOne />
      <AboutSectionThree />
      {/* <ProcessImage src={ProcessStaticImage} alt={"image"} /> */}
      <ModernProcessFlow />
      <AboutSectionFour />
    </Layout>
  );
};
