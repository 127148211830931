import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../../../components/layout/Dashboard Layout/index";
import { Link } from "react-router-dom";
import { EyeIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import axios from "axios";

export const ProjectsUnderReview = () => {
  const [projects, setProjects] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/under_observation_projects`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setProjects(response.data.projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-container")) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleApprove = async (projectId, userId) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/update_project_status/${projectId}`,
        { status: 1, userId: userId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response.data);
      const updatedProjects = projects.map((project) =>
        project.id === projectId ? { ...project, status: "Approved" } : project
      );
      setProjects(updatedProjects);
      setActiveDropdown(null);
    } catch (error) {
      console.error("Error approving project:", error);
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">
            Projects Under Review
          </h1>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Id
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Project Name
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Total Area
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Completion Year
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Preview
                  </th>
                  <th className="px-4 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-left">
                {projects.map((project) => (
                  <tr
                    key={project.id}
                    className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {project.id}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full object-cover"
                            src={project.banner_image}
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {project.project_name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {project.category}
                    </td>
                    <td className="px-4 py-4 text-sm">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Pending
                      </span>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {project.total_area} sq ft
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {project.city_name}, {project.country_name}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900">
                      {project.year_of_completion}
                    </td>
                    <td className="px-4 py-4 text-sm">
                      <Link
                        to={`/projects/project-details/${project?.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-black hover:text-white bg-theme-color hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color transition-colors gap-2"
                      >
                        <span>View</span>
                        <EyeIcon className="h-4 w-4" />
                      </Link>
                    </td>
                    <td className="px-4 py-4 text-sm">
                      <div className="dropdown-container relative">
                        <button
                          onClick={() => toggleDropdown(project.id)}
                          className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color transition-colors"
                        >
                          Actions
                          <ChevronDownIcon className="ml-2 h-4 w-4" />
                        </button>

                        {activeDropdown === project.id && (
                          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                            <div className="py-1" role="menu">
                              <button
                                onClick={() =>
                                  handleApprove(project.id, project.user_id)
                                }
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                                role="menuitem"
                              >
                                Approve
                              </button>
                              <button
                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                                role="menuitem"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ProjectsUnderReview;
