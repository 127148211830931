import { DefaultHeader, WhiteHeader } from "../common/Header";
import { Footer } from "../common/Footer";
import { useEffect } from "react";

export const Layout = ({ children, Classes, headerType = "1" }) => {
  const renderHeader = () => {
    switch (headerType) {
      case "2":
        return <WhiteHeader />;
      case "1":
      default:
        return <DefaultHeader />;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col text-left">
      {renderHeader()}
      <main className={Classes}>{children}</main>
      <Footer />
    </div>
  );
};
