import React, { useState, useEffect } from "react";
import axios from "axios";
import CommercialImage from "../../../../assets/images/Projects/commercials.jpg";
import HealthcareImage from "../../../../assets/images/Projects/healthcare.jpg";
import RestaurantImage from "../../../../assets/images/Projects/restaurant.jpg";
import HospitalityImage from "../../../../assets/images/Projects/hospitality.jpeg";
import { Link } from "react-router-dom";

const imageMap = {
  Residential: CommercialImage,
  Healthcare: HealthcareImage,
  Restaurant: RestaurantImage,
  Hospitality: HospitalityImage,
};

export const SimiliarProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const projectCategory = localStorage.getItem("projectCategory") || "Residential";

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/api/projects/get_projects_by_category/${projectCategory}`,
          {},
          { headers: { 'Content-Type': 'application/json' } }
        );
        setProjects(response.data.slice(0, 4)); // Limit to 4 projects
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [projectCategory]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading projects: {error.message}</p>;

  return (
    <section className="my-10">
      <div className="flex flex-col">
        <div className="flex justify-between items-baseline">
          <h2
            className="font-bold text-theme-color text-left mb-10"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            <span className="text-black">Similiar </span> Projects
          </h2>
          <a href="/projects" className="text-lg font-bold text-theme-color" target="_blank" rel="noopener noreferrer">
            View All
          </a>
        </div>

        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          {projects?.map((project) => (
            <div
              key={project.id}
              className="relative overflow-hidden bg-white rounded group"
            >
              <Link
                href={`/projects/project-details/${project.id}/`}
                target="_blank"
                className="block"
                rel="noopener noreferrer nofollow"
              >
                <div className="relative">
                  <img
                    src={project?.banner_image || CommercialImage} // Fallback image
                    alt="projectimage"
                    className="w-full h-72 object-cover transition-transform duration-500 ease-in-out group-hover:scale-105"
                  />
                  {/* Category name in top right corner */}
                  <span className="absolute top-2 right-2 text-white text-sm font-semibold z-10">
                    {project.category
                      ? project.category.charAt(0).toUpperCase() +
                        project.category.slice(1)
                      : ""}
                  </span>
                  <div className="absolute top-0 left-0 p-4 bg-gradient-to-b from-transparent to-black/70 w-full h-full flex flex-col justify-end">
                    <span
                      className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300"
                      style={{ fontFamily: "Lato, sans-serif" }}
                    >
                      {project.project_name || 'Project Name'}
                    </span>
                    <span className="text-white text-sm">{project.city_name || 'City Name'}, {project.country_name || 'Country Name'}</span>
                    <span className="text-white text-sm">
                      By {project.company_name || 'Unknown'}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
