import React from "react";
import { BreadcrumbComponentTwo } from "../../../components/shared/index";
import termsOfUseBgImage from "../../../assets/images/Policy/termsandcondition.jpg";
import { Layout } from "../../../components/layout/layout";
import { PolicyPagesFooter } from "../Policy Footer";

export const TermsOfUse = () => {
  const termsOfUseContent = [
    {
      title: "1. Acceptance of Terms",
      content:
        "By accessing or using GoartArch Design & Products's platform, you agree to comply with and be bound by these Terms of Use. If you disagree with any part of these terms, you may not use our services.",
    },
    {
      title: "2. Eligibility",
      content:
        "You must be at least 18 years old and capable of forming a binding contract to use our services. By using GoartArch Design & Products, you represent and warrant that you meet these eligibility requirements.",
    },
    {
      title: "3. User Registration",
      content:
        "To access certain features of GoartArch Design & Products, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
    },
    {
      title: "4. User Responsibilities",
      content:
        "You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password. You must immediately notify GoartArch Design & Products of any unauthorized use of your account.",
    },
    {
      title: "5. Content Submission",
      content:
        "By submitting content to GoartArch Design & Products (including business listings, product information, and project details), you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content.",
    },
    {
      title: "6. Prohibited Conduct",
      content:
        "You agree not to use GoartArch Design & Products for any unlawful purpose or in any way that interrupts, damages, or impairs the site. This includes but is not limited to: posting false or misleading information, infringing on intellectual property rights, or engaging in any fraudulent activity.",
    },
    {
      title: "7. Intellectual Property",
      content:
        "The GoartArch Design & Products platform, including its original content, features, and functionality, is owned by GoartArch Design & Products and protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.",
    },
    {
      title: "8. Payment Terms",
      content:
        "Certain services on GoartArch Design & Products may require payment. You agree to provide accurate and complete payment information. All fees are non-refundable unless otherwise stated at the time of purchase.",
    },
    {
      title: "9. Termination",
      content:
        "We may terminate or suspend your account and access to GoartArch Design & Products immediately, without prior notice or liability, for any reason, including if you breach the Terms of Use.",
    },
    {
      title: "10. Limitation of Liability",
      content:
        "GoartArch Design & Products shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use the service.",
    },
    {
      title: "11. Changes to Terms",
      content:
        "We reserve the right to modify these Terms of Use at any time. We will provide notice of significant changes by posting a prominent announcement on our platform.",
    },
    {
      title: "12. Governing Law",
      content:
        "These Terms of Use shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.",
    },
    {
      title: "13. Dispute Resolution",
      content:
        "Any dispute arising from these Terms of Use will be resolved through binding arbitration, conducted in [Your City/State]. Both parties agree to waive the right to bring a lawsuit in court or have a jury trial.",
    },
    {
      title: "14. Severability",
      content:
        "If any provision of these Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms of Use will otherwise remain in full force and effect and enforceable.",
    },
  ];

  return (
    <Layout>
      <section className="">
        <BreadcrumbComponentTwo
          title="Terms of Use"
          bgImage={termsOfUseBgImage}
        />
        <main className="px-4 py-8 md:px-8 lg:px-24">
          <h1 className="text-3xl font-bold mb-8 text-theme-color">
            Terms of Use
          </h1>
          <p className="text-gray-600 mb-8 font-bold">
            Effective Date: 8-10-24
          </p>

          {termsOfUseContent.map((section, index) => (
            <div
              key={index}
              className="mb-8 pb-8 border-b border-gray-200 last:border-b-0"
            >
              <h2 className="text-2xl font-semibold mb-4 text-theme-color">
                {section.title}
              </h2>
              <p className="text-gray-700 mb-4">{section.content}</p>
            </div>
          ))}

          <PolicyPagesFooter />
        </main>
      </section>
    </Layout>
  );
};
