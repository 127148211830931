import React from "react";
import { ChevronRight } from "lucide-react";
import PlaceholderImage from "../../../assets/images/Placeholders/wowplaceholder.png";

const BlogBanner = ({
  title,
  category,
  bannerImage = PlaceholderImage,
  breadcrumbs = [],
}) => {
  return (
    <div className="relative">
      {/* Banner Image with Overlay */}
      <div className="relative h-[500px]">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundPosition: "center",
          }}
        />
        <div className="absolute inset-0 bg-white/90" />

        {/* Breadcrumbs */}
        {/* <div className="absolute top-8 left-0 w-full">
          <div className="container mx-auto px-6">
            <div className="flex items-center text-sm text-gray-600">
              <a href="/" className="hover:text-theme-color transition-colors">
                Home
              </a>
              {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                  <ChevronRight className="mx-2 h-4 w-4" />
                  <a
                    href={crumb.url}
                    className="hover:text-theme-color transition-colors"
                  >
                    {crumb.label}
                  </a>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div> */}

        {/* Content Container */}
        <div className="absolute inset-0 flex flex-col items-center justify-center px-6">
          {/* Category */}
          <div className="relative mb-8">
            <div className="relative px-8 py-2 bg-theme-color rounded-full border border-theme-color/20 shadow-lg">
              <span className="text-black font-medium tracking-wider text-sm uppercase">
                {category}
              </span>
            </div>
          </div>

          {/* Title */}
          <div className="max-w-4xl text-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-gray-900">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBanner;
