import React, { useState } from "react";
import { FiEye, FiHeart, FiShare2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { UniversalSocialShare } from "../../../../../components/shared";
import { HeartIcon } from "@heroicons/react/24/outline";

const ProductDetailsSectionOne = ({ product }) => {
  // console.log("product :-", product);

  const [selectedImage, setSelectedImage] = useState(0);

  const hasImages =
    product?.product_images && product.product_images.length > 0;

  const combinedImages = product?.product_banner_image
    ? [product.product_banner_image, ...product.product_images]
    : product.product_images;

  const hasCombinedImages = combinedImages?.length > 0;

  return (
    <section className="relative">
      <div className="lg:px-24 px-6 py-12 pt-36">
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="lg:w-2/5">
            <div className="flex flex-col gap-4">
              <div className="relative overflow-hidden rounded bg-gray-100">
                <div className="w-full h-[300px] lg:h-[500px]">
                  {hasCombinedImages ? (
                    <img
                      src={combinedImages[selectedImage]}
                      className="w-full h-full object-cover"
                      alt={`Product Image ${selectedImage + 1}`}
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-200">
                      <p>No Images Available</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex gap-4 overflow-x-auto">
                {hasCombinedImages &&
                  combinedImages.map((image, index) => (
                    <div
                      key={index}
                      className="relative cursor-pointer rounded overflow-hidden flex-shrink-0"
                      onClick={() => setSelectedImage(index)}
                    >
                      <img
                        src={image}
                        className="w-20 h-20 object-cover"
                        alt={`Thumbnail ${index + 1}`}
                      />
                      {selectedImage === index && (
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                          <FiEye className="text-white w-6 h-6" />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="lg:w-3/5">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h1 className="mb-2">
                  {product?.product_name || "No Product Name"}
                </h1>
                <p className="text-gray-600 mb-2">
                  {product?.category || "No Category"},{" "}
                  {product?.sub_category || "No Sub-Category"}
                </p>
                {/* Manufacture Name Logo Section */}
                <div className="flex items-center space-x-3 mt-4 mb-6 bg-gray-50 p-3 rounded-lg shadow-sm">
                  <Link
                    to={`/company-profile/${product?.user_id}-${
                      product?.company_name
                        ? product.company_name.replace(/\s+/g, "-")
                        : ""
                    }/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-4"
                  >
                    <img
                      src={product?.company_logo_image}
                      alt="Manufacturer Logo"
                      className="w-24 h-24 object-contain rounded-full border-[1px] border-theme-color p-1"
                    />
                  </Link>

                  <div>
                    <p className="text-sm font-medium text-gray-700">
                      Manufactured by
                    </p>
                    <p className="text-lg font-semibold text-gray-900">
                      <Link
                        to={`/company-profile/${product?.user_id}-${
                          product?.company_name
                            ? product.company_name.replace(/\s+/g, "-")
                            : ""
                        }/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-4"
                      >
                        {product?.company_name || "Unknown Manufacturer"}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <button className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition">
                  {/* <FiHeart className="w-6 h-6" /> */}
                  <HeartIcon className="h-5 w-5 text-gray-800" />
                </button>
                {/* <button className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition">
                  <FiShare2 className="w-6 h-6" />
                </button> */}
                <UniversalSocialShare />
              </div>
            </div>

            <div className="mb-6">
              <h5 className="font-semibold mb-3">Collection</h5>
              <div className="flex flex-wrap gap-2">
                <span className="bg-black text-theme-color px-3 py-1 rounded-full text-sm">
                  {product?.collection_name || "No Collection Name"}
                </span>
              </div>
            </div>

            <div>
              <h5 className="font-semibold mb-3">Product Characteristics</h5>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {product?.characteristics?.length > 0 ? (
                  product.characteristics.map((char, index) => (
                    <li key={index} className="flex items-start">
                      <span className="w-2 h-2 bg-theme-color rounded-full mr-2 mt-2"></span>
                      <span className="text-black">
                        {char.value || "No Value"}
                      </span>
                    </li>
                  ))
                ) : (
                  <li>No Characteristics Available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetailsSectionOne;
