import React from "react";
import { Input } from "../../../../../components/shared";

export const IndividualProfileUpdateStepOne = ({ control }) => {
  const socialMedias = [
    {
      name: "linkedin",
      label: "LinkedIn",
      placeholder: "LinkedIn Profile URL",
    },
    { name: "twitter", label: "Twitter", placeholder: "Twitter Profile URL" },
    {
      name: "facebook",
      label: "Facebook",
      placeholder: "Facebook Profile URL",
    },
    {
      name: "instagram",
      label: "Instagram",
      placeholder: "Instagram Profile URL",
    },
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white p-6 shadow rounded">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">
          Personal Details
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Input
            name="fullName"
            control={control}
            rules={{ required: "Full Name is required" }}
            label="Full Name"
            placeholder="Enter your full name"
          />
          <Input
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email format",
              },
            }}
            label="Email"
            type="email"
            placeholder="Enter your email"
          />
          <Input
            name="phoneNumber"
            control={control}
            label="Phone Number"
            type="tel"
            placeholder="Enter your phone number"
          />
          <Input
            name="address"
            control={control}
            label="Address"
            placeholder="Enter your address"
          />
          <Input
            name="country"
            control={control}
            label="Country"
            placeholder="Enter your country"
          />
        </div>
        <Input
          name="description"
          control={control}
          label="Description"
          type="textarea"
          placeholder="Enter a brief description"
          rows={4}
        />
      </div>
      <div className="bg-white p-6 shadow rounded">
        <div className="bg-white px-6 shadow rounded">
          <h3 className="text-xl font-semibold text-gray-900 mb-6">
            Social Media Profiles
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {socialMedias.map((social) => (
              <Input
                key={social.name}
                name={social.name}
                control={control}
                label={social.label}
                placeholder={social.placeholder}
                type="url"
              />
            ))}
          </div>
        </div>
        <div className="bg-white p-6 shadow rounded mt-6">
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-900">Media</h3>
            <div className="grid grid-cols-2 gap-6">
              <Input
                name="bannerImage"
                control={control}
                label="Banner Image"
                type="file"
                accept="image/*"
                multiple
              />
              <Input
                name="profileImage"
                control={control}
                label="Profile Image"
                type="file"
                accept="image/*"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
