import React from "react";

export const HashtagsSection = ({ hashtags }) => {
  return (
    <section className="py-12">
      <div className="px-6 lg:px-24">
        <h2 className="text-3xl font-bold text-gray-800 mb-8 font-lato">
          Related Hashtags
        </h2>
        <div className="flex flex-wrap gap-3">
          {hashtags.map((tag, index) => (
            <span
              key={index}
              className="inline-block bg-white text-theme-color px-3 py-1 rounded-full text-sm font-semibold shadow-sm hover:shadow-md transition-all duration-300"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </section>
  );
};
