import React from "react";
import { Menu } from "@headlessui/react";
import UserProfileImage from "../../../assets/images/User Profile/man.jpg";
import {
  Bars3Icon,
  ArrowRightOnRectangleIcon,
  UserIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  NewspaperIcon,
  ArrowsPointingOutIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const DashboardHeader = ({ toggleSidebar, username }) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);
  // console.log("userData", parsedData);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  return (
    <header className="sticky top-0 z-50 flex items-center justify-between h-16 px-5 py-4 text-white bg-black">
      <div className="flex items-center gap-4">
        <button
          onClick={toggleSidebar}
          className="p-2 rounded-full lg:hidden hover:bg-gray-700"
          aria-label="Toggle navigation"
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
        <h1 className="text-lg font-semibold">
          Welcome, {parsedData?.company_name}
        </h1>
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={toggleFullscreen}
          className="hidden p-2 rounded-full md:flex hover:bg-gray-700"
          aria-label="Fullscreen Mode"
        >
          <ArrowsPointingOutIcon className="w-6 h-6" />
        </button>

        <Menu as="div" className="relative">
          <Menu.Button className="flex items-center space-x-2">
            <img
              src={parsedData?.company_logo_image}
              alt="User"
              className="object-contain w-10 h-10 rounded-full bg-white"
            />
            <span className="hidden md:inline">{username}</span>
            <ChevronDownIcon className="w-6 text-white" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 w-48 py-1 mt-2 text-gray-800 bg-white rounded-md shadow-lg">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#profile"
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex items-center px-4 py-2 text-sm`}
                >
                  <UserIcon className="w-5 h-5 mr-2" /> Profile
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/dashboard"
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex items-center px-4 py-2 text-sm`}
                >
                  <ChartBarIcon className="w-5 h-5 mr-2" /> Analytics
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  href="/dashboard/profile-settings"
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex items-center px-4 py-2 text-sm`}
                >
                  <Cog6ToothIcon className="w-5 h-5 mr-2" /> Settings
                </Link>
              )}
            </Menu.Item>
            <hr className="my-1" />
            <Menu.Item>
              {({ active }) => (
                <Link
                  href="#logout"
                  className={`${
                    active ? "bg-gray-100" : ""
                  } flex items-center px-4 py-2 text-sm`}
                >
                  <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2" /> Log Out
                </Link>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </header>
  );
};

export default DashboardHeader;
