import React from "react";
import { Link } from "react-router-dom";
import { BuildingOffice2Icon, MapIcon } from "@heroicons/react/24/outline";

const CompanyCard = ({ company }) => (
  <Link to={`/company-profile/${company.id}-${company.company_name.replace(/\s+/g, '-')}/`} rel="nofollow" className="block">
    <div className="relative w-full h-96 rounded overflow-hidden group">
      <div className="absolute inset-0">
        <img
          src={company.banner_image}
          alt={`${company.company_name} banner`}
          className="w-full h-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70" />

      <div className="absolute inset-0 p-6 flex flex-col justify-between">
        <div className="flex justify-between items-start">
          <div className="bg-white rounded-full shadow-md">
            <img
              src={company.company_logo_image}
              alt={`${company.company_name} logo`}
              className="w-20 h-20 object-contain rounded-full"
            />
          </div>
          <span className="text-white rounded-full text-sm font-bold">
            {company.primary_company_type === "Manufacturer" ||
            company.secondary_company_type === "Manufacturer"
              ? company.product_count || 0
              : company.project_count || 0}
            {company.primary_company_type === "Manufacturer" ||
            company.secondary_company_type === "Manufacturer"
              ? " Product"
              : " Project"}
          </span>
        </div>

        <div className="text-white">
          <span
            className="text-lg font-bold"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            {company.company_name}
          </span>
          <div className="space-y-2 text-sm mt-2">
            <div className="flex items-center">
              <BuildingOffice2Icon className="w-5 h-5 mr-2" />
              <span>{company.primary_company_type || "Not specified"}</span>
            </div>
            <div className="flex items-center">
              <MapIcon className="w-5 h-5 mr-2" />
              <span>
                {company.state && company.country
                  ? `${company.state}, ${company.country}`
                  : company.state
                  ? company.state
                  : company.country
                  ? company.country
                  : "Location not specified"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

export default CompanyCard;
