import { Layout } from "../../components/layout/layout";
import { BreadcrumbComponentTwo } from "../../components/shared";
import BlogBannerImage from "../../assets/images/Blogs/blogbanner.jpg";
import { Link } from "react-router-dom";
import { getSortedNewsItems } from "../../data/articles/blogdata";

export const ArticlePage = () => {
  const sortedNewsItems = getSortedNewsItems();

  return (
    <Layout headerType="2">
      <BreadcrumbComponentTwo
        title="Insights & Inspiration"
        bgImage={BlogBannerImage}
      />
      <section className="px-6 lg:px-24 py-16 bg-theme-light-bg">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-12">
          {sortedNewsItems.map((item) => (
            <div key={item.id} className="flex flex-col">
              <Link to={item.url} className="mb-4 block aspect-w-16 aspect-h-9">
                <img
                  className="w-full aspect-video rounded"
                  src={item.photoId}
                  alt={item.title}
                />
              </Link>
              <h3 className="mb-4">
                <Link to={item.url}>{item.title}</Link>
              </h3>
              <div className="w-full bg-gray-200 h-px mb-4" />
              <div className="flex items-center justify-between mt-auto">
                <div className="flex items-center space-x-4">
                  <Link
                    className="text-sm font-bold text-black hover:text-theme-color"
                    to="#"
                  >
                    {item.category}
                  </Link>
                  <span className="text-black text-sm font-bold">
                    {item.date}
                  </span>
                </div>
                <Link
                  className="text-sm uppercase underline font-bold text-theme-color hover:text-black"
                  to={item.url}
                >
                  READ MORE
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};
