import React from "react";
import { Gallery } from "../../../../components/shared";

export const ProjectImages = ({ project }) => {
  // console.log("Project Images :", project?.banner_image);

  return (
    <div className="relative mt-[100px] mb-10">
      <div className="relative w-full h-[65vh] cursor-pointer overflow-hidden">
        <img
          alt="Project Image"
          src={project?.project_banner_image}
          className="w-full h-full object-cover transform transition-transform duration-300 ease-in-out hover:scale-105 rounded"
        />
      </div>
      <div className="absolute bottom-3 right-3 md:bottom-5 md:right-5 3xl:bottom-8 3xl:right-8">
        <Gallery images={project?.project_image} />
      </div>
    </div>
  );
};

const ImageDisplay = ({ images }) => {
  return (
    <div className="mt-6 space-y-6">
      {images &&
        images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Project work ${index + 1}`}
            className="h-[250px] lg:h-[750px] w-full object-cover"
          />
        ))}
    </div>
  );
};

export default ImageDisplay;
