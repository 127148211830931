import React, { useState, useRef, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Helmet } from "react-helmet";
import { DashboardLayout } from "../../../components/layout/Dashboard Layout";
import { Input, Toast } from "../../../components/shared";
import { PlusCircle, Download } from "lucide-react";

const materialOptions = [
  { value: "wood", label: "Wood" },
  { value: "metal", label: "Metal" },
  { value: "plastic", label: "Plastic" },
  { value: "glass", label: "Glass" },
  { value: "concrete", label: "Concrete" },
];

export const ArchitectureTool = () => {
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  const [toast, setToast] = useState(null);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      thingGroups: [
        {
          thingName: "",
          thingCode: "",
          material: "",
          dimensions: ["", "", ""],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "thingGroups",
  });

  useEffect(() => {
    if (tableData.length > 0 && tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [tableData]);

  const onSubmit = (data) => {
    try {
      setTableData([...tableData, ...data.thingGroups]);
      // Reset form after successful submission
      reset({
        thingGroups: [
          {
            thingName: "",
            thingCode: "",
            material: "",
            dimensions: ["", "", ""],
          },
        ],
      });
      setToast({
        message:
          "Your data table has been created. You can view it above or download as CSV.",
        type: "success",
        duration: 5000,
      });
    } catch (error) {
      setToast({
        message:
          "An error occurred while creating the table. Please try again.",
        type: "error",
        duration: 5000,
      });
    }
  };

  const exportToCSV = () => {
    if (tableData.length === 0) return;

    try {
      const headers = [
        "Thing Name",
        "Thing Code",
        "Material",
        "Dimension 1",
        "Dimension 2",
        "Dimension 3",
      ];
      const csvContent = [
        headers.join(","),
        ...tableData.map((row) =>
          [row.thingName, row.thingCode, row.material, ...row.dimensions].join(
            ","
          )
        ),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "architecture_data.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setToast({
        message: "CSV file has been downloaded successfully!",
        type: "success",
        duration: 3000,
      });
    } catch (error) {
      setToast({
        message: "Failed to download CSV file. Please try again.",
        type: "error",
        duration: 5000,
      });
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Arch Tool | GoatArch Design and Products</title>
      </Helmet>

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={() => setToast(null)}
        />
      )}

      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center p-6 sticky top-0 bg-white z-10">
          <h1 className="text-2xl font-bold">Arch Tool</h1>
        </div>

        {/* Table */}
        {tableData.length > 0 && (
          <div
            ref={tableRef}
            className="mt-8 overflow-x-auto bg-white p-6 rounded-lg shadow-sm"
          >
            <table className="min-w-full bg-white border rounded-lg">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Thing Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Thing Code
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Material
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Dimensions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.thingName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.thingCode}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.material}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.dimensions.join(" × ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Export Button */}
            <div className="flex justify-end mt-4">
              <button
                type="button"
                onClick={exportToCSV}
                className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
              >
                <Download size={20} />
                Export CSV
              </button>
            </div>
          </div>
        )}

        <div className="relative pb-20">
          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="bg-white p-6 rounded-lg shadow-sm border"
              >
                <div className="mb-4">
                  <Input
                    name={`thingGroups.${index}.thingName`}
                    control={control}
                    label="Thing Name"
                    placeholder="Enter thing name"
                    rules={{ required: "Thing name is required" }}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <Input
                    name={`thingGroups.${index}.thingCode`}
                    control={control}
                    label="Thing Code"
                    placeholder="Enter thing code"
                    rules={{ required: "Thing code is required" }}
                  />
                  <Input
                    name={`thingGroups.${index}.material`}
                    control={control}
                    type="select"
                    label="Material"
                    options={materialOptions}
                    placeholder="Select material"
                    rules={{ required: "Material is required" }}
                  />
                  {[0, 1, 2].map((dimIndex) => (
                    <Input
                      key={dimIndex}
                      name={`thingGroups.${index}.dimensions.${dimIndex}`}
                      control={control}
                      type="number"
                      label={`Dimension ${dimIndex + 1}`}
                      placeholder="Enter dimension"
                      rules={{ required: "Dimension is required" }}
                    />
                  ))}
                </div>

                {fields.length > 1 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Remove Group
                  </button>
                )}
              </div>
            ))}

            <div className="flex justify-center">
              <button
                type="button"
                onClick={() =>
                  append({
                    thingName: "",
                    thingCode: "",
                    material: "",
                    dimensions: ["", "", ""],
                  })
                }
                className="flex items-center gap-2 px-4 py-2 text-theme-color hover:text-black"
              >
                <PlusCircle size={20} />
                Add New Group
              </button>
            </div>

            {/* Submit Button - Fixed at bottom */}
            <div className="fixed bottom-0 left-0 right-0 bg-white border-t p-4 z-20">
              <div className="max-w-6xl mx-auto flex justify-end">
                <button
                  type="submit"
                  className="px-6 py-2 bg-theme-color text-white rounded hover:bg-black transition-colors"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ArchitectureTool;
