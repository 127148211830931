import ArticleImageOne from "../../../assets/images/About/instgram-cover-img.png";
import { Layout } from "../../../components/layout/layout";
import { BlogBanner } from "../../../components/shared";

export const ArticleOne = () => {
  const blogPost = {
    title:
      "Why GoatArch Revolutionizing Professional Networking in Architecture & Design ?",
    category: "Platform Guide",
    author: "John Doe",
    readTime: "8 min read",
    publishDate: "Nov 11, 2024",
    bannerImage: ArticleImageOne,
    breadcrumbs: [
      { label: "Blog", url: "/blog" },
      { label: "Platform Guides", url: "/blog/guides" },
    ],
  };
  return (
    <Layout headerType="2">
      <section className="mt-20">
        <BlogBanner {...blogPost} />
      </section>
      <article className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction Section */}
        <div className="mb-12">
          <p className="text-lg text-gray-700 leading-relaxed">
            Creating a professional profile on GoatArch is your first step
            toward expanding your company's digital presence. This guide will
            walk you through the simple registration process and help you
            optimize your company profile for maximum visibility.
          </p>
        </div>

        {/* Registration Process Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">
            Quick Registration Process
          </h2>
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                1
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">
                  Initial Registration
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Visit goatarch.com and click the "Register" button</li>
                  <li>Enter your company name and business email</li>
                  <li>Check your email for the OTP verification code</li>
                  <li>Enter the OTP to verify your email</li>
                </ul>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                2
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">
                  Company Profile Setup
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-700">
                  <li>Fill in essential company details</li>
                  <li>
                    Upload your company logo (recommended size: 400x400px)
                  </li>
                  <li>Add a banner image (recommended size: 1200x400px)</li>
                  <li>Upload portfolio images to your gallery</li>
                  <li>Add your social media links</li>
                </ul>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-theme-color text-white flex items-center justify-center font-semibold">
                3
              </span>
              <div>
                <h3 className="font-semibold text-lg mb-2">
                  Profile Verification
                </h3>
                <p className="text-gray-700">
                  After submitting your profile, our administrators will review
                  your information. Once approved, your profile will be visible
                  to all users, and you'll gain access to additional features.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Dashboard Access Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Accessing Your Dashboard</h2>
          <div className="bg-gray-50 p-6 rounded-lg">
            <ol className="list-decimal list-inside space-y-4 text-gray-700">
              <li>Click on your company logo in the header</li>
              <li>Select "Dashboard" from the dropdown menu</li>
              <li>
                Navigate to "Update Company Details" for profile management
              </li>
            </ol>
          </div>
        </section>

        {/* Profile Enhancement Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Enhancing Your Profile</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">
                Essential Additions
              </h3>
              <ul className="list-disc list-inside space-y-2 text-gray-700">
                <li>Company expertise areas</li>
                <li>Brand collaborations</li>
                <li>Team members</li>
                <li>Company brochure</li>
                <li>Project portfolio</li>
              </ul>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">Available Features</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-700">
                <li>Product showcase</li>
                <li>Project gallery</li>
                <li>Team management</li>
                <li>Media updates</li>
                <li>Profile analytics</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Optimization Tips Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-6">Profile Optimization Tips</h2>
          <div className="bg-theme-color/5 p-6 rounded-lg">
            <ul className="space-y-4 text-gray-700">
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Complete all profile sections for better visibility</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Use high-quality images for logo and banner</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Keep your portfolio and project galleries updated</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="text-theme-color font-bold">•</span>
                <span>Add detailed descriptions for better search results</span>
              </li>
            </ul>
          </div>
        </section>

        {/* Conclusion Section */}
        <section>
          <div className="bg-gray-50 p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">
              Ready to Get Started?
            </h2>
            <p className="text-gray-700 mb-6">
              A complete and well-maintained profile significantly increases
              your visibility on GoatArch. Take advantage of all available
              features to showcase your company's expertise and connect with
              potential clients.
            </p>
            <button className="bg-theme-color text-white px-6 py-2 rounded-lg hover:bg-theme-color/90 transition-colors">
              Register Now
            </button>
          </div>
        </section>
      </article>
    </Layout>
  );
};
