// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
  }
  .loader::before,
  .loader::after {    
    content:"";
    grid-area: 1/1;
    --c:no-repeat radial-gradient(farthest-side,#E6BC8E 92%,#0000);
    background: 
      var(--c) 50%  0, 
      var(--c) 50%  100%, 
      var(--c) 100% 50%, 
      var(--c) 0    50%;
    background-size: 12px 12px;
    animation: l12 1s infinite;
  }
  .loader::before {
    margin: 4px;
    filter: hue-rotate(45deg);
    background-size: 8px 8px;
    animation-timing-function: linear
  }
  
  @keyframes l12 { 
    100%{transform: rotate(.5turn)}
  }`, "",{"version":3,"sources":["webpack://./src/components/shared/Loader/css/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,aAAa;EACf;EACA;;IAEE,UAAU;IACV,cAAc;IACd,8DAA8D;IAC9D;;;;uBAImB;IACnB,0BAA0B;IAC1B,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,yBAAyB;IACzB,wBAAwB;IACxB;EACF;;EAEA;IACE,KAAK,yBAAyB;EAChC","sourcesContent":[".loader {\n    width: 50px;\n    aspect-ratio: 1;\n    display: grid;\n  }\n  .loader::before,\n  .loader::after {    \n    content:\"\";\n    grid-area: 1/1;\n    --c:no-repeat radial-gradient(farthest-side,#E6BC8E 92%,#0000);\n    background: \n      var(--c) 50%  0, \n      var(--c) 50%  100%, \n      var(--c) 100% 50%, \n      var(--c) 0    50%;\n    background-size: 12px 12px;\n    animation: l12 1s infinite;\n  }\n  .loader::before {\n    margin: 4px;\n    filter: hue-rotate(45deg);\n    background-size: 8px 8px;\n    animation-timing-function: linear\n  }\n  \n  @keyframes l12 { \n    100%{transform: rotate(.5turn)}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
