import { Layout } from "../../components/layout/layout";
import { BreadcrumbComponentTwo } from "../../components/shared";
import SearchResultPageBanner from "../../assets/images/Placeholders/wowplaceholder.png";
import { SearchResultCompanies } from "./Companies";
import { SearchResultsProducts } from "./Products";
import { SearchResultsProjects } from "./Projects";
import SearchBar from "./Searchbar";
import React, { useState, useEffect } from "react";
import axios from "axios";

export const GlobalSearchResultPage = () => {
  const [searchResults, setSearchResults] = useState({
    signup: [],
    projects: [],
    products: [],
  });
  const [query, setQuery] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleSearch = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSearchResults({ signup: [], projects: [], products: [] });
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/api/search_all`, {
        searchTerm,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearch(query);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const handleInputChange = (searchTerm) => {
    setQuery(searchTerm);
  };

  return (
    <Layout>
      <section>
        <BreadcrumbComponentTwo
          title="Search Results"
          bgImage={SearchResultPageBanner}
        />
      </section>
      <SearchBar onSearch={handleInputChange} />

      {searchResults.signup.length > 0 && (
        <SearchResultCompanies companies={searchResults.signup} />
      )}

      {searchResults.projects.length > 0 && (
        <SearchResultsProjects projects={searchResults.projects} />
      )}

      {searchResults.products.length > 0 && (
        <SearchResultsProducts products={searchResults.products} />
      )}
    </Layout>
  );
};

// import { Layout } from "../../components/layout/layout";
// import { BreadcrumbComponentTwo } from "../../components/shared";
// import SearchResultPageBanner from "../../assets/images/Placeholders/wowplaceholder.png";
// import { SearchResultCompanies } from "./Companies";
// import { SearchResultsProducts } from "./Products";
// import { SearchResultsProjects } from "./Projects";
// import SearchBar from "./Searchbar";
// import React, { useState, useEffect } from "react";
// import axios from "axios";

// export const GlobalSearchResultPage = () => {
//   const [searchResults, setSearchResults] = useState({
//     signup: [],
//     projects: [],
//     products: [],
//   });
//   const [query, setQuery] = useState("");
//   const baseURL = process.env.REACT_APP_BASE_URL;

//   // console.log("searchResults:", searchResults);

//   const handleSearch = async (searchTerm) => {
//     if (!searchTerm.trim()) {
//       setSearchResults({ signup: [], projects: [], products: [] }); // Clear results if searchTerm is empty
//       return;
//     }

//     try {
//       const response = await axios.post(`${baseURL}/api/search_all`, {
//         searchTerm,
//       });
//       setSearchResults(response.data); // Set search results to state
//     } catch (error) {
//       console.error("Error fetching search results:", error);
//     }
//   };

//   // Effect for debouncing search
//   useEffect(() => {
//     const handler = setTimeout(() => {
//       handleSearch(query);
//     }, 300); // 300ms debounce time

//     return () => {
//       clearTimeout(handler);
//     };
//   }, [query]);

//   const handleInputChange = (searchTerm) => {
//     setQuery(searchTerm);
//   };

//   return (
//     <Layout>
//       <section>
//         <BreadcrumbComponentTwo
//           title="Search Results"
//           bgImage={SearchResultPageBanner}
//         />
//       </section>
//       <SearchBar onSearch={handleInputChange} />
//       <SearchResultCompanies companies={searchResults.signup} />
//       <SearchResultsProjects projects={searchResults.projects} />
//       <SearchResultsProducts products={searchResults.products} />
//     </Layout>
//   );
// };
