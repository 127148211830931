import React from "react";
import { Input } from "../../../../components/shared";

export const CompanyStepTwo = ({ control }) => {
  return (
    <div className="space-y-6">
      <Input
        name="phone"
        control={control}
        type="tel"
        international
        label="Phone Number"
        placeholder="Enter your phone number"
        rules={{ required: "Phone is required" }}
      />
      <Input
        name="companyPhone2"
        control={control}
        placeholder="Number Two ( Optional )"
      />
      <Input
        name="companyEmail"
        control={control}
        label="Another Company Email"
        type="email"
        placeholder="Enter Another Company Email (Optional)"
      />
      <Input
        name="companyWebsite"
        control={control}
        label="Company Website"
        placeholder="Enter Company Website"
        rules={{
          pattern: {
            value:
              /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{2,}\.[a-z]{2,}(\.[a-z]{2,})?(\/[a-zA-Z0-9#?&%=._-]*)*\/?$/,
            message: "Please enter a valid website URL",
          },
        }}
      />
    </div>
  );
};
