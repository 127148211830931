import { PlayIcon } from "@heroicons/react/24/outline";
import VideoBGImage from "../../../../assets/images/Placeholders/wowplaceholder.png";
import { useEffect, useState } from "react";
export const ProjectVideo = ({ video }) => {
  const [videoUrl, setVideoUrl] = useState(video);
  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        // Replace this with your actual API call
        const response = await fetch(
          "https://api.example.com/company-video-url"
        );
        const data = await response.json();
        setVideoUrl(data.videoUrl);
      } catch (error) {
        console.error("Error fetching video URL:", error);
      }
    };

    fetchVideoUrl();
  }, []);
  return (
    <div className="mt-6 relative w-full h-0 pb-[56.25%] overflow-hidden rounded">
      {videoUrl ? (
        <iframe
          src={videoUrl}
          title="Company Video"
          className="absolute top-0 left-0 w-full h-full"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <div
          className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
          style={{
            backgroundImage: `url(${VideoBGImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <PlayIcon className="w-16 md:w-24 text-white z-10" />
          <div className="absolute inset-0 bg-black bg-opacity-30" />
        </div>
      )}
    </div>
  );
};
