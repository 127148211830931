import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProductPlaceholderImage from "../../../../assets/images/Placeholders/wowplaceholder.png";
import { EyeIcon, ShareIcon } from "lucide-react";
import ProductPopup from "../../../Products/Products Detail Popup";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";

export const FeatureProducts = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleShareOptions = () => {
    setIsOpen(!isOpen);
  };

  const shareOnSocialMedia = (platform, productID) => {
    let shareUrl = "";
    const encodedLink = encodeURIComponent(
      `http://localhost:3000/products/products-details/${productID}`
    );

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodedLink}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedLink}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };
  return (
    <section className="pb-16">
      <div className="flex flex-col px-4 lg:px-24">
        <div className="flex justify-between items-baseline">
          <h2
            className="font-bold text-theme-color text-left mb-10"
            style={{ fontFamily: "Lato, sans-serif" }}
          >
            <span className="text-black">GoatArch </span> Products
          </h2>
          <Link to="/products/" className="text-lg font-bold text-theme-color">
            View All
          </Link>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          {products.map((product) => (
            <div className="group relative overflow-hidden rounded shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer">
              <Link
                to={`/products/products-details/${product.id}-${product.product_name.replace(/\s+/g, '-')}/`}
                // target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <div className="relative h-80">
                  <img
                    src={
                      product.product_banner_image || ProductPlaceholderImage
                    }
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/30 group-hover:bg-black/40 transition-colors duration-300" />
                </div>
              </Link>

              <Link
                to={`/products/products-details/${product?.id}-${product?.product_name.replace(/\s+/g, '-')}/`}
                // target="_blank"
                rel="noopener noreferrer"
              >
                <div className="absolute bottom-4 left-4 right-4">
                  <span className="text-lg font-bold text-white group-hover:text-theme-color transition-colors duration-300">
                    {product.product_name}
                  </span>
                  <p className="text-white text-sm">
                    By {product.company_name}
                  </p>
                  <p className="text-white text-sm">
                    {product.city}, {product.country}
                  </p>
                </div>
              </Link>
              <div className="absolute top-4 right-4 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="relative">
                  <button
                    className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    onClick={(e) => {
                      handleProductClick(product);
                    }}
                  >
                    <EyeIcon className="h-5 w-5 text-gray-800" />
                  </button>
                  <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
                    <div className="bg-black/80 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                      Quick View
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <button
                    className="bg-white p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    onClick={toggleShareOptions}
                  >
                    <ShareIcon className="h-5 w-5 text-gray-800" />
                  </button>
                  {isOpen && (
                    <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 bg-black/80 text-white text-xs rounded py-1 px-2">
                      <div className="flex space-x-2">
                        <button
                          onClick={() =>
                            shareOnSocialMedia("whatsapp", product.id)
                          }
                        >
                          <FaWhatsapp className="h-5 w-5" />
                        </button>
                        {/* <button
                                      onClick={() =>
                                        shareOnSocialMedia("instagram", product.id)
                                      }
                                    >
                                      <FaInstagram className="h-5 w-5" />
                                    </button> */}
                        <button
                          onClick={() =>
                            shareOnSocialMedia("twitter", product.id)
                          }
                        >
                          <FaTwitter className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() =>
                            shareOnSocialMedia("facebook", product.id)
                          }
                        >
                          <FaFacebook className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="absolute top-4 left-4 text-white px-3 py-1 rounded-full text-sm font-medium">
                <b>{product.category}</b>
                <p className="text-white text-sm">{product.sub_category}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          isOpen={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </section>
  );
};
