import React, { useState } from "react";
import { Input } from "../../../../components/shared";

export const CompanyStepFive = ({ control }) => {
  const [bannerError, setBannerError] = useState("");
  const [logoError, setLogoError] = useState("");

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <div>
          <Input
            name="bannerImage"
            control={control}
            type="file"
            label="Banner Image"
            acceptedFileTypes="images"
            multiple={false}
            maxSizeInMB={5}
            rules={{
              required: "Please Upload Banner Image for your profile",
            }}
          />

          {bannerError && <p className="text-red-500 text-sm">{bannerError}</p>}
          <p className="mt-2 text-sm">
            <span className="font-bold">Note: </span>Please upload an image with
            dimensions of 1900 x 800 pixels for optimal display on your profile.
            The image size should not exceed 2MB.
          </p>
        </div>

        <div>
          <Input
            name="logo"
            control={control}
            type="file"
            label="Logo"
            acceptedFileTypes="images"
            multiple={false}
            maxSizeInMB={2}
            rules={{
              required: "Please upload a logo, it is required",
            }}
          />
          {logoError && <p className="text-red-500 text-sm">{logoError}</p>}
        </div>
      </div>
    </div>
  );
};
