// test
import cookieImage from "../../../assets/images/Cookie/cookie.png";
import React, { useState, useEffect } from "react";

export const Cookie = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [showManageCookies, setShowManageCookies] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setTimeout(() => {
        setShowConsent(true);
      }, 2000);
    }
  }, []);

  const handleAllowAll = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowConsent(false);
  };

  const handleManageCookies = () => {
    setShowManageCookies(true);
  };

  if (!showConsent && !showManageCookies) return null;

  return (
    <div
      id="dismiss-toast"
      className="hs-removing:translate-x-5 hs-removing:opacity-0 opacity-100 transition-all duration-300 fixed bottom-0 right-0 z-[60] sm:max-w-xl w-full mx-auto p-6"
    >
      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-lg">
        <div className="flex gap-x-5">
          <img
            src={cookieImage}
            alt="cookieimage"
            className="hidden sm:block flex-shrink-0 w-20 h-20"
          />
          <div className="grow">
            {showManageCookies ? (
              <div>
                <h2 className="text-lg font-semibold text-gray-800">
                  Manage your cookie preferences
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  You can choose which cookies to allow. Please select from the
                  options below.
                </p>
                <div className="mt-4">
                  <button
                    onClick={handleAllowAll}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-theme-color text-black hover:text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-all text-sm"
                  >
                    Allow all
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h2 className="text-lg font-semibold text-gray-800">
                  We are using cookies to improve your experience!
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  By clicking "Allow all", you agree to the use of all cookies.
                  Visit our{" "}
                  <a
                    className="inline-flex items-center gap-x-1.5 text-sky-600 decoration-2 hover:underline font-medium"
                    href="/cookie-policy"
                  >
                    Cookies Policy
                  </a>{" "}
                  to learn more.
                </p>
                <div className="mt-5 inline-flex gap-x-2">
                  <button
                    onClick={handleAllowAll}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-theme-color text-black hover:text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-theme-color focus:ring-offset-2 transition-all text-sm"
                  >
                    Allow all
                  </button>
                  <button
                    onClick={handleManageCookies}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-black transition-all text-sm"
                  >
                    Manage cookies
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
